import axios from 'axios';
import authHeader from "./auth-header";
import authParams from "./auth-params";
import { REACT_APP_SERVER_API } from "../../constants";

const request = () => axios.create({
    baseURL: REACT_APP_SERVER_API,
    headers: authHeader(),
    params: authParams(),
    withCredentials: true
})

export default request;
