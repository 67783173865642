import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {deleteAddress, editAddress, getAddresses} from "../../../slices/addresses";

const NewAddress = ({  editPopup, address }) => {
  const dispatch = useDispatch()
  const trans = useSelector(state => state?.fetch?.data?.text);
  const markDefault = async () => {
    dispatch(editAddress({...address, address_id: address.id, is_default: true })).unwrap().then(() => dispatch(getAddresses()))
  }

  const remove = () => {
    if (window.confirm(trans?.['my-profile']?.['my-addresses']?.['sidebar-address']?.confirm ?? "Are you sure to delete address?") === true) { //TODO DE FACUT POPUP NORMAL
      dispatch(deleteAddress({ address_id: address.id })).unwrap().then(() => dispatch(getAddresses()))
    }
  }

  return (
      <div className="profile__new-address">
        <div className="profile__new-address--content">
          <div className="profile__new-top">
            <div className="profile__current-text">
              <div className="profile__new-title">{address?.company_name !== "" ? address?.company_name : `${address?.first_name} ${address?.last_name}` }</div>
              <div className="profile__delivery-data">
                <div className="profile__data-name">{address?.name} {address?.lastName}</div>
                <div className="profile__data-phone">{trans?.page?.contacts?.['title-phone']}: {address?.phone} </div>
                {address?.mail && <div className="profile__data-phone">{trans?.['my-profile']?.['personal-data']?.['input-email']}: {address?.mail}</div>}
                <div className="profile__data-address">{address?.country}, {address?.city}, {address?.state}</div>
              </div>
            </div>
            <div className="profile__new-control">
              {address?.is_default ? <div className="profile__current-default">{trans?.['my-profile']?.['my-addresses']?.['sidebar-address']?.['default-address'] ?? 'Default'}</div>
                  : <div className="profile__new-default" onClick={() => markDefault()}>{trans?.['my-profile']?.['my-addresses']?.['sidebar-address']?.['set-default-address']}</div>}
              <div className="profile__new-change">
                <div className="profile__new-delete" onClick={remove}>{trans?.checkout?.remove ?? 'Remove'}</div>
                <div className="profile__new-edit" onClick={() => editPopup(address)}>{trans?.['my-profile']?.['my-addresses']?.['sidebar-address']?.['btn-edit'] ?? 'Edit'}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default NewAddress