import React, {useRef, useEffect} from 'react'
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux'


const SignUp = ({ registration, setRegisterInput, registerInput, errorSignUp, setErrorSignUp, cart = true, setContact  }) => {
    const {register, handleSubmit, reset, watch, formState: { errors }} = useForm({ mode: 'onChange' });

    const trans = useSelector(state => state?.fetch?.data?.text)
    const password = useRef();
    password.current = watch("password", "");

    const onSubmit = (data) => {
        setRegisterInput(data)
    }

    const onChange = (data) => {
        setErrorSignUp(null);
    }

    useEffect(() => {
        if (registerInput != null) {
            reset()
            registration()
        }
    }, [registerInput])

    return (
        <form className="registration__sing-up" onSubmit={handleSubmit(onSubmit)} onChange={onChange}>
            {cart && <div className="registration__sign-title">{trans?.register?.['signup-text']?.['form-title']}</div>}
            {cart && <div className="registration__sign-text">{trans?.register?.['signup-text']?.['form-subtitle']}</div>}
            <div className="registration__sign-inputs">
                <div className="registration__sign-mail input-title">{trans?.register?.['signup-text']?.['input-name']}</div>
                <input className="registration__name-input" {...register('name', { required: true, pattern: /^[A-Za-z]+$/i })} type="text" placeholder="Name" />
                {errors?.name && <p className='error-mesage'>{trans?.register?.['signup-text']?.['input-name-error']}</p>}
                <div className="registration__sign-pass input-title">{trans?.register?.['signup-text']?.['input-email']}</div>
                <input className="registration__mailinput" {...register('email', { required: true, pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g })} type="text" placeholder="Email" />
                {errors?.email && <p className='error-mesage'>{trans?.register?.['signup-text']?.['input-email-error']}</p>}
                <div className="registration__pass-bottom">
                    <div className="registration__pass">
                        <div className="registration__sign-pass input-title" >{trans?.register?.['signup-text']?.['input-password']}</div>
                        <input
                            name="password"
                            className="registration__pass-input"
                            type="password"
                            placeholder="**********"
                            {...register("password", {
                                    required: {
                                        value: true,
                                        message: trans?.['my-profile']?.['personal-data']?.['input-password-required'] ?? 'Password required'
                                    },
                                    minLength: {
                                        value: 8,
                                        message: trans?.register?.['signup-text']?.['input-password-error-length'] ?? "Password must have at least 8 characters"
                                    },
                                })
                            }
                        />
                        {errors?.password && <p className='error-mesage'>{errors.password.message}</p>}
                    </div>
                    <div className="registration__confirm">
                        <div className="registration__sign-pass input-title" >{trans?.register?.['signup-text']?.['input-confirm-password']}</div>
                        <input
                            className="registration__pass-input"
                            name="confirm_password"
                            type="password"
                            placeholder="**********"
                            {...register("confirm_password",{
                                required: trans?.['my-profile']?.['personal-data']?.['input-passwordconfirm-required'] ?? 'Confirm Password is required.',
                                validate: value => value === password.current || trans?.register?.['signup-text']?.['input-password-error']
                            })}
                        />
                        {errors?.confirm_password && <p className='error-mesage'>{errors.confirm_password.message}</p>}
                    </div>
                </div>
                {errorSignUp !== null && <div className='registration__error' style={{marginBottom: '25px'}}>{ errorSignUp }</div>}
                {cart ?  <button className="registration__register" type="submit">{trans?.register?.['signup-text']?.['btn-register']}</button>
                    :
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <div className="cart__login-back" onClick={() => setContact("registration")}>{trans?.checkout?.['shipping-form']?.['btn-back'] ?? 'Back'}</div>
                        <button className="registration__register" type="submit">{trans?.checkout?.['shipping-form']?.['btn-sign-up']  ?? 'Sign up'}</button>
                    </div>
                }
            </div>
        </form>
    )
}

export default SignUp