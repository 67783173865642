import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './components/Redux/Store';
import './assets/styles/palette.css';
import HttpsRedirect from 'react-https-redirect';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <BrowserRouter >
        <Provider store={store}>
            <HttpsRedirect>
                <App/>
            </HttpsRedirect>
        </Provider>
    </BrowserRouter>
);

