import React from 'react'
import {useDispatch, useSelector} from 'react-redux';
import Modal from "../../UI/Modal/Modal";
import ContactSocial from "../../pages/Cart/ContactSocial";
import {enableScroll} from "../../../helpers/documentElement";
import parse from "html-react-parser"

import {Link, useNavigate} from "react-router-dom";


const PayModal = ({ callback }) => {

    const trans     = useSelector(state => state?.fetch?.data?.text)
    const redirect  = useSelector(state => state?.cart?.payment_redirect);

    const close = () => {
        callback(false)
        enableScroll()
    }

    const myOrders = () => {
        callback(false)
        enableScroll()
    }

    return (
        <div className="cart_modals">
            <Modal status={true} btnClose={false} closeModal={close}>
                <h3>{trans?.checkout?.['order-success']?.payment ?? 'Online payment'}</h3>
                <div className="content">
                    <p>{''}</p>
                </div>
                
                <ContactSocial/>
                <div className="content">
                    <p>{trans?.checkout?.['order-success']?.description}</p>
                </div>
                {parse(redirect ?? '<b>Paynet Error</>')}
            </Modal>
        </div>




                    
    )
}

export default PayModal