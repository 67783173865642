import { createSlice } from "@reduxjs/toolkit";

const orderSlice = createSlice({
    name: 'orders',
    initialState: {
        data: [],
        pagination: undefined,
        loading: false,
    },
    reducers: {
        setOrderInProfile: (state, action) => {
            state.data = action.payload
        },
        setOrderPagination: (state, action) => {
            state.pagination = action.payload
        },
        deleteOrders: (state) => {
            state.data = []
        },
        setOrdersLoading: (state, action) => {
            state.loading = action.payload
        },

    }
})

export const { setOrderInProfile, setOrderPagination, deleteOrders, setOrdersLoading } = orderSlice.actions
export default orderSlice.reducer


