import request from "./utils/request";

const register = (first_name, last_name, email, password, password_confirmation) => {
    return request().post(`/customer/register`, { first_name, last_name, email, password, password_confirmation });
};

const login = (email, password) => {
    const token = true
    return request().post(`/customer/login`, { email, password, token }).then(response => response.data);
};

const logout = () => {
    return request().get(`/customer/logout`);
};

const authService = { register, login, logout };

export default authService;