import React, {useEffect, useState} from "react";
import InputMask from "react-input-mask";
import {useDispatch, useSelector} from "react-redux";
import Select from "react-select";
import {addAddress, editAddress, getAddresses, getRegions} from "../../../slices/addresses"; 
import {enableScroll} from "../../../helpers/documentElement";
import {useForm} from "react-hook-form";
import {useClickOutside} from "../../../hooks/useClickOutside";
import { setLocalAddress } from "../../Redux/AddressReducer"; 
import { cartShipping } from "../../../slices/cart"; 



const AddAddressPopup = ({ close, setAddressPopup, editableAddress, setEditableAddress, setOverlay, overlay, callback, shippingZone, shipping_method }) => {
  // Global State
  const trans     = useSelector(state => state?.fetch?.data?.text);
  const user      = useSelector((user) => user.user.currentUser);
  const addresses = useSelector(state => state.addresses.data)
  const regions   = useSelector(state => state.addresses.regions)
  const language  = useSelector(state => state?.language?.currentLanguage)
  const currency  = useSelector(state => state?.currency?.currentDataCurrency)

  //Local State
  const [error, setError] = useState(false);
  const [selectedCity, setSelectedCity] = useState(editableAddress?.city ? { value: editableAddress?.city, label: editableAddress?.city } : null);

  // Hooks
  const outside = useClickOutside(() => {
    setAddressPopup(false);
    close();
    enableScroll()
  });
  const { watch, register, handleSubmit, formState: {errors}} = useForm({ mode: "onChange" });
  const dispatch  = useDispatch();

  
  // Local consts
  const errorMsgs = {
    ro: {
      required: 'Completează-ți numele adresei',
      minLenght: 'Minim 3 caractere',
      valid: 'Introduceți un nume de adresa valid'
    },
    ru: {
      required: 'Заполните имя адреса',
      minLenght: 'Минимум 3 символа',
      valid: 'Пожалуйста, введите корректное значение'
    },
    en: {
      required: 'Complete your address name',
      minLenght: 'Minimum 3 characters',
      valid: 'Complete with a valid address name'
    }
  }

  const formatChars = {
    '9': '[0-9]',
    '6': '[6-7]',
    '1': '[1-9]',
    'a': '[A-Za-z]',
    '*': '[A-Za-z0-9]'
  } 

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      width: "100%",
      color: "#A9A9A9",
      padding: 0,
      background: "#FFFFFF",
    }),
    indicatorseparator: (provided) => ({
      display:'none',
    }),
  };

  // Utils
  const options = Object.values(regions).map((city) => {
    return {
      value: city,
      label: city,
    };
  });

  const addressData = (data) => {
    return {
      address1: [data?.street],
      city: selectedCity?.value,
      country: currency?.country_name,
      country_name: currency?.country_name,
      phone: data?.phone,
      postcode:  currency?.country_code === 'RO' ? '020122' : 'MD-2075', // change to Ro if ron
      state: data?.state || selectedCity?.value,
      company_name: data?.company_name,
      first_name: data?.first_name,
      last_name: data?.last_name,
      is_default: addresses?.data?.length === 0,
    }
  }


  // Use Effects
  useEffect(() => {
    if(regions === undefined) dispatch(getRegions({code : currency.country_code}))
  }, [])

  useEffect(() => {
    if (editableAddress?.city) {
      let found = options?.find(it => it.value === editableAddress.city)

      if(found) {
        setSelectedCity(found)
      }
    }
  }, [regions])

  // Handlers 
  const createAddress = async (data) => {
    if(user) {
      await dispatch(addAddress(addressData(data))).unwrap().then((address) => {
        if (callback) callback(address.data)

      })

      const shipping_zone = await shippingZone(selectedCity?.value)
      
      if(!shipping_method) {
        await dispatch(cartShipping({shipping_method: "flatrate_flatrate", shipping_zone, currency: currency.code }))
      } else {
        await dispatch(cartShipping({shipping_method, shipping_zone, currency: currency.code }))

      }

    } else {
      const request = {id: "local", ...addressData(data)}
      await dispatch(setLocalAddress(request))
      if (callback) callback(request)
    }
  }; 

  const updateAddress = async (id, data) => {

    if(user) {
      await dispatch(editAddress({address_id: id, ...addressData(data)}))
    } else {
      const request = {id, ...addressData(data)}
      await dispatch(setLocalAddress(request))
      if (callback) callback(request)
    }
  };

  const onSubmit = (data) => {
    if (editableAddress?.id) {
     
      updateAddress(editableAddress?.id, data).then(() => {
        if (user) dispatch(getAddresses())
      })
      setAddressPopup(false);
      setOverlay(!overlay);
      enableScroll()
      setEditableAddress({});
    } else {
      if (!error) {
        setAddressPopup(false);
        setOverlay(!overlay);
        enableScroll()
        createAddress(data).then(() => {
          if (user) dispatch(getAddresses())
        });
      }
      window.scrollTo({
        top: 200,
        left: 0,
        behavior: "smooth",
      });
    }
  };


  const handleCityError = () => {
    if (selectedCity) {
      setError(false);
    } else {
      setError(true);
    }
  };

  return (
    <>
      <form className={`popup__address active-profile`} style={{display: "block"}} onSubmit={handleSubmit(onSubmit)} ref={outside}>
        <div className="popup__address-content">
          <div className="popup__address-wrapper">
            <div className="cart__back">
              <div className="cart__back-link" to="/" onClick={close}>
              {trans?.['checkout']?.['continue-shopping']}
              </div>
            </div>
            <div className="popup__address-newadd">
              <div className="popup__container">
                <div className="popup__newadd-title">{trans?.['my-profile']?.['my-addresses']?.['sidebar-address']?.['title-new-address']}</div>
                <input
                  className="popup__newadd-input"
                  type="text"
                  placeholder={trans?.['my-profile']?.['personal-data']?.['input-address-name']}
                  defaultValue={ editableAddress?.company_name ?? "" }
                  {...register("company_name", {
                    required: {
                      value: true,
                      message: errorMsgs[language].required,
                    },
                    minLength: { value: 3, message: errorMsgs[language].minLenght },
                    pattern: {
                      value: /[a-zA-Z0-9\s]+/i,
                      message: errorMsgs[language].valid
                    },
                  })}
                />
                {/* <input
                  className="popup__newadd-input"
                  type="text"
                  placeholder={trans?.['my-profile']?.['personal-data']?.['input-address-name']}
                  defaultValue={ editableAddress?.company_name ?? "" }
                  {...register("company_name", {
                    required: {
                      value: true,
                      message: trans?.['my-profile']?.['my-addresses']?.['sidebar-address']?.['input-address-required'],
                    },
                    minLength: { value: 3, message: trans?.['my-profile']?.['my-addresses']?.['sidebar-address']?.['input-address-characters'] },
                    pattern: {
                      value: /[a-zA-Z0-9\s]+/i,
                      message: trans?.['my-profile']?.['my-addresses']?.['sidebar-address']?.['input-address-error']
                    },
                  })}
                /> */}
                {errors.company_name && (
                  <p className="error-mesage">{errors.company_name.message}</p>
                )}
                <input
                  className="popup__newadd-input"
                  type="text"
                  placeholder={trans?.['my-profile']?.['personal-data']?.['input-first-name']}
                  defaultValue={ editableAddress?.first_name ?? "" }
                  {...register("first_name", {
                    required: { value: true, message: trans?.['my-profile']?.['my-addresses']?.['sidebar-address']?.['input-new-address-name-error'] },
                    minLength: { value: 2, message: trans?.['my-profile']?.['my-addresses']?.['sidebar-address']?.['input-new-address-name-length'] },
                    pattern: {
                      value: /^[A-Za-z]+$/i,
                      message: trans?.['my-profile']?.['my-addresses']?.['sidebar-address']?.['input-new-address-name-validation'],
                    },
                  })}
                />
                {errors.first_name && (
                  <p className="error-mesage">{errors.first_name.message}</p>
                )}
                <input
                  className="popup__newadd-input"
                  type="text"
                  placeholder={trans?.['my-profile']?.['personal-data']?.['input-last-name']}
                  defaultValue={ editableAddress?.last_name ?? "" }
                  {...register("last_name", {
                    required: {
                      value: true,
                      message: trans?.['my-profile']?.['my-addresses']?.['sidebar-address']?.['input-new-address-surname'],
                    },
                    minLength: { value: 2, message: trans?.['my-profile']?.['my-addresses']?.['sidebar-address']?.['input-new-address-surname-length'] },
                    pattern: {
                      value: /^[A-Za-z]+$/i,
                      message: trans?.['my-profile']?.['my-addresses']?.['sidebar-address']?.['input-new-address-surname-validation'],
                    },
                  })}
                />
                {errors.last_name && (
                  <p className="error-mesage">{errors.last_name.message}</p>
                )}
              </div>
            </div>
            <div className="popup__address-contact">
              <div className="popup__container">
                <div className="popup__contact-title"> {trans?.['my-profile']?.['my-addresses']?.['sidebar-address']?.['title-contacts']}</div>
                <InputMask
                  className="registration__name-input popup__newadd-input"
                  defaultValue={ editableAddress?.phone?.replace( /\s/g, '') ?? "" }
                  placeholder={trans?.page?.contacts?.['title-phone']}
                  mask={currency?.country_code === 'RO' ? '+40 199 999 999' : "+373 69 999 999"}
                  formatChars={formatChars}
                  {...register("phone", {
                    required: true,
                    minLength: 15,
                    alwaysShowMask: true,
                    pattern: currency?.country_code === 'RO' ? /^\+40 [1-9]\d{0,2} \d{3} \d{3}$/ : /^\+373 [1-9]\d{0,1} \d{3} \d{3}$/,
                  })}
                />
                {errors.phone && (
                  <>
                  {
                    currency?.country_code === 'RO' 
                    ?  <p className="error-mesage">{trans?.['my-profile']?.['my-addresses']?.['sidebar-address']?.['input-contacts-error-ro']}</p>
                    :  <p className="error-mesage">{trans?.['my-profile']?.['my-addresses']?.['sidebar-address']?.['input-contacts-error-md']}</p>
                  }
                  </>
                )}
              </div>
            </div>
            <div className="popup__address-default">
              <div className="popup__container">

                <div className="popup__default-title">{trans?.['my-profile']?.['my-addresses']?.['sidebar-address']?.['title-address']}</div>

                <Select
                    className="popup__default-input"
                    defaultValue={{value: currency?.country_name, label: currency?.country_name}}
                    options={[{value: currency?.country_name, label: currency?.country_name}]}
                    isSearchable={false}
                    styles={customStyles}
                    components={{
                      IndicatorContainer:() => null,
                      IndicatorSeparator:() => null,
                      DropdownIndicator:() => null
                    }}
                />

                {errors.country && (
                  <p className="error-mesage">{errors.country.message}</p>
                )}
                {options !== null &&
                    <Select
                        className="popup__default-input"
                        defaultValue={selectedCity}
                        onChange={setSelectedCity}
                        options={options}
                        placeholder={currency.country_code === 'MD' ?  trans?.['my-profile']?.['my-addresses']?.['sidebar-address']?.['input-contacts-city'] : trans?.['my-profile']?.['my-addresses']?.['sidebar-address']?.['input-contacts-city-ro']}
                        isSearchable={true}
                        styles={customStyles}
                        components={{
                          IndicatorContainer:() => null,
                          IndicatorSeparator:() => null,
                          DropdownIndicator:() => null
                        }}
                    />
                }
                {error && <p className="error-mesage">{trans?.['my-profile']?.['my-addresses']?.['sidebar-address']?.['input-contacts-city-error']}</p>}


                { currency.country_code === 'RO' &&
                  <>
                    <input
                      className="popup__default-input"
                      type="text"
                      placeholder={`${trans?.['my-profile']?.['my-addresses']?.['sidebar-address']?.['input-contacts-location-ro']}`}
                      defaultValue={editableAddress?.state ? editableAddress?.state : ""}
                      {...register("state", {
                        required: { value: true, message: `${trans?.['my-profile']?.['my-addresses']?.['sidebar-address']?.['input-contacts-street-error']}` },
                        minLength: { value: 2, message: `${trans?.['my-profile']?.['my-addresses']?.['sidebar-address']?.['input-contacts-street-error']}` },
                        // pattern: {
                        //   value: /^[a-zA-Z0-9\s]*$/,
                        //   message: `${trans?.['my-profile']?.['my-addresses']?.['sidebar-address']?.['input-contacts-street-error']}`
                        // },
                      })}
                    />
                    {errors.street && (
                      <p className="error-mesage">{errors.street.message}</p>
                    )}
                  </>
                }

                <input
                  className="popup__default-input"
                  type="text"
                  placeholder={trans?.['my-profile']?.['my-addresses']?.['sidebar-address']?.['input-contacts-street']}
                  defaultValue={editableAddress?.address1 ? editableAddress?.address1[0] : ""}
                  {...register("street", {
                    required: { value: true, message: `${trans?.['my-profile']?.['my-addresses']?.['sidebar-address']?.['input-contacts-street-error']}` },
                    minLength: { value: 2, message: `${trans?.['my-profile']?.['my-addresses']?.['sidebar-address']?.['input-contacts-street-error']}` },
                    // pattern: {
                    //   value: /^[a-zA-Z0-9\s]*$/,
                    //   message: `${trans?.['my-profile']?.['my-addresses']?.['sidebar-address']?.['input-contacts-street-error']}`
                    // },
                  })}
                />
                {errors.street && (
                  <p className="error-mesage">{errors.street.message}</p>
                )}

                
                
              </div>
            </div>
          </div>
          <div className="popup__create">
            {editableAddress?.id ? <button className="popup__create-btn" type="submit">{trans?.['my-profile']?.['my-addresses']?.['sidebar-address']?.['btn-edit']}</button> :
              <button
                className="popup__create-btn"
                type="submit"
                onClick={handleCityError}>{trans?.['my-profile']?.['my-addresses']?.['sidebar-address']?.['btn-create']}</button>
            }
          </div>
        </div>
      </form>
    </>
  );
};

export default AddAddressPopup;
