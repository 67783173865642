import { configureStore } from "@reduxjs/toolkit";
import AddressReducer from "./AddressReducer";
import CartReducer from "./CartReducer";
import CategoryReducer from "./CategoryReducer";
import FavoriteReducer from "./FavoriteReducer";
import LanguageReducer from "./LanguageReducer";
import OrdersReducer from "./OrdersReducer";
import ProductReducer from "./ProductReducer";
import ProfileReducer from "./ProfileReducer";
import SearchReducer from "./SearchReducer";
import UserCounterReducer from "./UserCounterReducer";
import UserReducer from "./UserReducer";
import InfoPageReducer from "./InfoPageReducer";
import VideoReducer from "./VideoReducer";
import FetchReducer from "./FetchReducer";
import ArticlesReducer from "./ArticlesReducer";
import ContactsReducer from "./ContactsReducer";
import CurrencyReducer from "./CurrencyReducer";

export const store = configureStore({
    
    reducer: {
        cart: CartReducer,
        product: ProductReducer,
        favorite: FavoriteReducer,
        addresses: AddressReducer,
        orders: OrdersReducer,
        profile: ProfileReducer,
        language: LanguageReducer,
        search: SearchReducer,
        category: CategoryReducer,
        user: UserReducer,
        userCounter: UserCounterReducer,
        infoPage: InfoPageReducer,
        videos: VideoReducer,
        fetch: FetchReducer,
        articles: ArticlesReducer,
        contacts: ContactsReducer,
        currency: CurrencyReducer
    },
    middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
    
})