import React from 'react'
import logo from "../../../assets/images/pictures/logo.png"
import sprite from "../../../assets/images/icons/sprite.svg"
import { useSelector} from 'react-redux';

const ReportOverlay = ({ message, outside, close }) => {
    const trans = useSelector(state => state.fetch.data.text);

    return (
        <div className={`overlay ${message ? "active" : ""}`} >
            <div className="message" ref={outside}>
                <div className="product__popup-close" onClick={close}>
                    <svg className="icon close-product">
                        <use href={sprite + '#close-product-popup'}></use>
                    </svg>
                </div>
                <div className="message__bg">
                    <div className="message__brand">
                        <img className='message__logo' src={logo} alt="" />
                    </div>
                </div>
                <div className="message__info">
                    <div className="message__title">{message}</div>
                    <div className="message__btn" onClick={close}>{trans?.page?.contacts?.['btn-continue']}</div>
                </div>
            </div>
        </div>
    )
}

export default ReportOverlay