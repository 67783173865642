import {createAsyncThunk} from "@reduxjs/toolkit";
import getErrorMessage from "../services/utils/error-message";
import ArticlesService from "../services/articles.service";
import {setArticles, setArticle } from "../components/Redux/ArticlesReducer";

export const getArticles = createAsyncThunk(
    "articles/getAll",
    async ({ locale, page }, thunk) => {
        try {
            const response = await ArticlesService.getAll({ locale, page });
            thunk.dispatch(setArticles(response));
            return response;
        } catch (error) {
            return thunk.rejectWithValue(getErrorMessage(error));
        }
    }
);

export const getArticle = createAsyncThunk(
    "articles/get",
    async (params, thunk) => {
        try {
            const response = await ArticlesService.get(params);
            thunk.dispatch(setArticle(response));
            return response;
        } catch (error) {
            return thunk.rejectWithValue(getErrorMessage(error));
        }
    }
);
