import { createSlice } from "@reduxjs/toolkit";

const userCounterSlice = createSlice({
    name: 'userCounter',
    initialState: {
        userCounter: localStorage.getItem('userCounter') ? JSON.parse(localStorage.getItem('userCounter')) : {favorite: null, cart: null}
    },

    reducers: {
        setCurrentFavCounter: (state, action) => {
            state.userCounter.favorite = action.payload > 0 ? action.payload : null
            localStorage.setItem('userCounter', JSON.stringify(state.userCounter))
        },
        setCurrentCartCounter: (state, action) => {
            state.userCounter.cart = action.payload > 0 ? action.payload : null
            localStorage.setItem('userCounter', JSON.stringify(state.userCounter))
        },
        deleteCurrentUserCounter: (state, action) => {
            state.userCounter = null
            localStorage.setItem('userCounter', null)
        },
    }
})



export const { setCurrentFavCounter,deleteCurrentUserCounter, setCurrentCartCounter} = userCounterSlice.actions
export default userCounterSlice.reducer