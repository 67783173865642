import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CartCounter from '../../Counter/CartCounter';
import { cartShipping, removeCartProduct } from "../../../slices/cart";


const Product = ({ product, shipping_method, shippingZone }) => {
    const trans = useSelector(state => state?.fetch?.data?.text)
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const handleDeleteClick = async () => {

        const shipping = await dispatch(removeCartProduct({ product_id: product?.id }))

        const shipping_zone = await shippingZone()

        if( shipping.payload.data) {
            dispatch(cartShipping({shipping_method, shipping_zone}))
        }

    };

    const aboutClick = (product) => {
        navigate(`/product/${product?.product?.url_key}`)
    }

    return (
        <div className="cart__product">
            <div className="cart__image-link" onClick={() => aboutClick(product)}>
                <img className="cart__product-image" src={product?.product?.base_image?.product_cart_image_url ?? product?.product?.base_image?.product_image_url} alt={product?.name} />

                {/* Discout badge */}
                {/* <div className="gallery__images-top" style={{top: 0}}>
                    { product?.product?.is_sales
                        ? <div className="gallery__discount">-{Math.round(100 - product?.product?.special_price / (product?.product?.price / 100))}%</div> 
                        : ''
                    }
                </div> */}
                {/* Discout badge */}

                </div>
            <div className="cart__product-about">
                <div className="cart__about-title">{product?.name}</div>
                <div className="cart__about-description">{product?.additional?.savedOption?.savedColor ? `${product?.additional?.savedOption?.savedColor} |` : ''} {product?.sku}</div>
                <div className="bag-popup__details">
                    <div className="cart__about-size">{trans?.products?.size}:<span>{product?.additional?.savedOption?.savedSize}</span></div>
                    <div className="bag-popup__color" style={{ background: product?.savedColor }}/>
                </div>
                <div className="cart__buy product__buy">
                    <div className="cart__buy-content product__buy-content">
                        <CartCounter product={product} />
                    </div>
                </div>
            </div>
            <div className="cart__product-price">
                  {/* Discount price */}
                {
                    product?.product?.is_sales 
                    ? <div className={`cart__price-sum sale`}>
                        {product?.product?.formated_special}
                        <div className={`gallery__image-price sale`}>{product?.product?.formated_price}</div>
                    </div>

                : 
                <div className={`cart__price-sum`}>
                    {product?.formated_price}
                </div>

                }
               
             
                <div className="cart__remove" onClick={handleDeleteClick}>
                    <div className="cart__remove-text">{trans?.checkout?.remove}</div>
                </div>
            </div>
        </div>
    )
}

export default Product