import React from 'react'
import { useSelector } from 'react-redux'
const BlogRecent = () => {
  const trans     = useSelector(state => state?.fetch?.data?.text)
  const articles  = useSelector(state => state?.articles?.data?.recent)

  return (
    <>
    {articles && <div className="blog__recent">
      <h3 className="blog__info-title">{trans?.['blog']?.['recent-posts'] ?? 'Recent posts'}</h3>
      {
      articles && articles.map( item => 
          <ul className="blog__info-items">
              <li className="blog__info-post">{item?.name}</li>
              <li className="blog__info-date">{item?.date}</li>
          </ul>
        )
      }
    </div>}
    </>
  )
}

export default BlogRecent