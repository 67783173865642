import {createAsyncThunk} from "@reduxjs/toolkit";
import AuthService from "../services/auth.service";
import {deleteCurrentUser, setCurrentUser} from "../components/Redux/UserReducer";
import getErrorMessage from "../services/utils/error-message";
import {getCart} from "./cart";
import {getWishlist} from "./wishlist";
import {setAddresses} from "../components/Redux/AddressReducer";
import {deleteOrders} from "../components/Redux/OrdersReducer";
import {setProfileSection} from "../components/Redux/ProfileReducer";

export const registerUser = createAsyncThunk(
    "auth/register",
    async ({ first_name, last_name, email, password, password_confirmation }, thunk) => {
        try {
            return await AuthService.register(first_name, last_name, email, password, password_confirmation);
        } catch (error) {
            return thunk.rejectWithValue(getErrorMessage(error));
        }
    }
);

export const loginUser = createAsyncThunk(
    "auth/login",
    async ({ email, password, locale }, thunk) => {
        try {
            await thunk.dispatch(setAddresses([]))
            const user = await AuthService.login(email, password);
            await localStorage.clear();
            await thunk.dispatch(setProfileSection("data"))
            await thunk.dispatch(setCurrentUser(user));
            await thunk.dispatch(getCart())
            await thunk.dispatch(getWishlist({locale}))
            return user
        } catch (error) {
            return thunk.rejectWithValue(getErrorMessage(error));
        }
    }
);

export const logout = createAsyncThunk("auth/logout", async ({ locale }, thunk) => {
    await AuthService.logout();
    await thunk.dispatch(deleteCurrentUser())
    await thunk.dispatch(getCart())
    await thunk.dispatch(getWishlist({locale}))
    await thunk.dispatch(setAddresses([]))
    await thunk.dispatch(deleteOrders())
    await thunk.dispatch(deleteCurrentUser())
    localStorage.clear()
    localStorage.setItem("profile", "data")
});