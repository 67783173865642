import { createSlice } from "@reduxjs/toolkit";

const favoriteSlice = createSlice({
    name: 'favorite',
    initialState: {
        favoriteInCart: [],
        loading: true
    },
    reducers: {
        setFavoriteInCart: (state, action) => {
            state.favoriteInCart = action.payload
        },
        setFavoriteLoading: (state, action) => {
            state.loading = action.payload
        },
    }
})

export const { setFavoriteInCart, setFavoriteLoading } = favoriteSlice.actions
export default favoriteSlice.reducer