import {createAsyncThunk} from "@reduxjs/toolkit";
import getErrorMessage from "../services/utils/error-message";
import AddressesService from "../services/addresses.service";
import {setAddresses, setAddressesLoading, setRegions} from "../components/Redux/AddressReducer";

export const getAddress = createAsyncThunk(
    "addresses/get_one",
    async ({ address_id }, thunk) => {
        try {
            return await AddressesService.get(address_id);
        } catch (error) {
            return thunk.rejectWithValue(getErrorMessage(error));
        }
    }
);

export const getAddresses = createAsyncThunk(
    "addresses/get",
    async (_, thunk) => { 
        try {
            thunk.dispatch(setAddressesLoading(true))
            const data = await AddressesService.get();
            await thunk.dispatch(setAddresses(data))
            thunk.dispatch(setAddressesLoading(false))
            return data
        } catch (error) {
            thunk.dispatch(setAddressesLoading(false))
            return thunk.rejectWithValue(getErrorMessage(error));
        }
    }
);

export const addAddress = createAsyncThunk(
    "addresses/add",
    async ({ address1, city, country, country_name, phone, postcode, state, company_name, first_name, last_name, is_default }, thunk) => {
        try {
            return await AddressesService.add(address1, city, country, country_name, phone, postcode, state, company_name, first_name, last_name, is_default);
        } catch (error) {
            return thunk.rejectWithValue(getErrorMessage(error));
        }
    }
);

export const editAddress = createAsyncThunk(
    "addresses/edit",
    async ({ address_id, address1, city, country, country_name, phone, postcode, state, company_name, first_name, last_name, is_default }, thunk) => {
        try {
            return await AddressesService.update(address_id, address1, city, country, country_name, phone, postcode, state, company_name, first_name, last_name, is_default);
        } catch (error) {
            return thunk.rejectWithValue(getErrorMessage(error));
        }
    }
);

export const deleteAddress = createAsyncThunk(
    "addresses/delete",
    async ({ address_id }, thunk) => {
        try {
            return await AddressesService.remove(address_id);
        } catch (error) {
            return thunk.rejectWithValue(getErrorMessage(error));
        }
    }
);


export const getRegions = createAsyncThunk(
    "addresses/get_regions",
    async ( {code}, thunk) => {
        try {
            const data = await AddressesService.regions(code);
            thunk.dispatch(setRegions(data.regions))
            return data
        } catch (error) {
            return thunk.rejectWithValue(getErrorMessage(error));
        }
    }
);