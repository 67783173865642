import React, { useState, useEffect } from 'react'
import Map from '../../Map/Map'
import Meta from '../../../meta/Meta';
import ContactMail from './ContactMail';
import ContactForm from './ContactForm';
import ContactHours from './ContactHours';
import { useForm } from 'react-hook-form';
import ContactNumber from './ContactNumber';
import ContactOverlay from './ContactOverlay';
import ContactLocation from './ContactLocation';
import BreadCrumbs from '../../BreadCrumbs/BreadCrumbs';
import { useClickOutside } from '../../../hooks/useClickOutside';
import sprite from "../../../assets/images/icons/sprite.svg"
import {disableScroll, enableScroll} from "../../../helpers/documentElement";
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from "react-router-dom";
import parse from "html-react-parser";

import {getContacts, sendContact} from "../../../slices/contacts";
const Contact = () => {

  const contacts  = useSelector(state => state?.contacts?.data)
  const language  = useSelector(state => state?.language?.currentLanguage);
  const [message, setMessage] = useState(false);
  const dispatch  = useDispatch();
  const location = useLocation();
  const slug = location?.pathname ?? '/page/contact-us';
  useEffect(() => {
   
    dispatch(getContacts(
      {
        locale: language,
        slug: slug
      }))
  }, [language,slug,dispatch])

  const close = () => {
    setMessage(false)
    enableScroll()
  }
  const {
    register,
    handleSubmit, reset,
    formState: { errors },
  } = useForm({
    mode: 'onChange'
  });


  const onSubmit = (params) => { 
    params.slug = slug;   
    dispatch(sendContact(params))
    setMessage(!message)
    disableScroll()
    reset()
  }
  document.onkeyup = function (evt) {
    evt = evt || window.event;
    if (evt.keyCode === 27 && message) {
      setMessage(false)
      enableScroll()
    }
  };
  const outside = useClickOutside(() => {
    setMessage(false)
    enableScroll()
  })

  return (
    <>
      <Meta 
        title={contacts?.data?.['meta_title'] ?? contacts?.data?.name} 
        description={contacts?.data?.['meta_description']} 
        keywords={contacts?.data?.['meta_keywords']} 
      />
      <ContactOverlay message={message} outside={outside} close={close} />
      <section className="contact">
        {contacts?.data?.additional?.latitude && contacts?.data?.additional?.longitude && <Map lat={parseFloat(contacts?.data?.additional?.latitude)} long={ parseFloat(contacts?.data?.additional?.longitude) }/>}
        <div className="container">
          <BreadCrumbs title={contacts?.data?.name} />
          <div className="contact__content">
            <div className="contact__info">
              <div className="contact__info-text">{ parse(contacts?.data?.content ?? '')}</div>
              <div className="contact__info-block">
                <ContactNumber sprite={sprite} />
                <ContactMail sprite={sprite} />
                <ContactLocation sprite={sprite} />
                <ContactHours sprite={sprite} />
              </div>
            </div>
            <ContactForm handleSubmit={handleSubmit} errors={errors} onSubmit={onSubmit} register={register}/>
          </div>
        </div>
      </section>
    </>
  )
}

export default Contact