import React from 'react';
import parse from 'html-react-parser';

const ProductInfo = ({ delivery, setDelivery, activePopup, product, trans }) => {

    return (
        <div className="product__botoom-info">
            <div className="product__bottom-content">
                <div className="product__bottom-item--tiles">
                    <div className={`product__bottom-item ${delivery === "delivery" && "active__product-title"}`} onClick={() => setDelivery("delivery")}>{trans?.products?.delivery}</div>
                    <div className={`product__bottom-item ${delivery === "payment" && "active__product-title"}`} onClick={() => setDelivery("payment")}>{trans?.products?.payment}</div>
                    <div className={`product__bottom-item ${delivery === "policy" && "active__product-title"}`} onClick={() => setDelivery("policy")}>{trans?.products?.['return-policy']}</div>
                </div>
                <div className={`product__bottom-container ${delivery === "delivery" && "active-delivery"}`}>
                    <div className="product__bottom-text ">{parse(String(product?.delivery ?? ''))}</div>
                    <div className="product__bottom-desc active-delivery">{trans?.products?.['more-info']}
                        <span className="product__info-delivery" onClick={() => activePopup("delivery")}>{trans?.products?.delivery}</span>
                    </div>
                </div>
                <div className={`product__bottom-container ${delivery === "payment" && "active-delivery"}`}>
                    <div className="product__bottom-text ">{parse(String(product?.payment ?? ''))}</div>
                    <div className="product__bottom-desc">{trans?.products?.['more-info']}<span
                        className="product__info-delivery" onClick={() => activePopup("payment")}>{trans?.products?.payment}</span></div>
                </div>
                <div className={`product__bottom-container ${delivery === "policy" && "active-delivery"}`}>
                    <div className="product__bottom-text ">{parse(String(product?.return  ?? ''))}</div>
                    <div className="product__bottom-desc">{trans?.products?.['more-info']}<span
                        className="product__info-delivery" onClick={() => activePopup("return")} >{trans?.products?.['return-policy']}</span></div>
                </div>
            </div>
        </div>
    )
}

export default ProductInfo