import {createAsyncThunk} from "@reduxjs/toolkit";
import getErrorMessage from "../services/utils/error-message";
import WishlistService from "../services/wishlist.service";
import { setFavoriteInCart, setFavoriteLoading } from "../components/Redux/FavoriteReducer";
import {setCurrentFavCounter} from "../components/Redux/UserCounterReducer";

export const getWishlist = createAsyncThunk(
    "wishlist/get",
    async ({ locale, page }, thunk) => {
        try {
            // thunk.dispatch(setFavoriteLoading(true))
            const response = await WishlistService.get(locale,page);
            thunk.dispatch(setFavoriteInCart(response));
            thunk.dispatch(setCurrentFavCounter(response?.meta?.total))
            thunk.dispatch(setFavoriteLoading(false))
            return response;
        } catch (error) {
            setTimeout(() => {
                thunk.dispatch(getWishlist({ locale, page }))
            }, 5000)
            return thunk.rejectWithValue(getErrorMessage(error));
        }
    }
);

export const addToWishlist = createAsyncThunk(
    "wishlist/add",
    async (product_id , thunk) => {
        try {
            const response = await WishlistService.add(product_id);
            thunk.dispatch(setCurrentFavCounter(response?.count))
            return response
        } catch (error) {
            return thunk.rejectWithValue(getErrorMessage(error));
        }
    }
);

export const updateWishlist = createAsyncThunk(
    "wishlist/update",
    async (list, thunk) => {
        try {
            thunk.dispatch(setFavoriteInCart(list));
            return list
        } catch (error) {
            return thunk.rejectWithValue(getErrorMessage(error));
        }
    }
);
