import React from 'react'
import {Link} from 'react-router-dom'
import SocialShare from '../../SocialShare/SocialShare'
import parse from "html-react-parser"


const BlogInfo = ({trans, post}) => {
    const containerStyle = {
        backgroundImage:
            `url('${post?.image}')`,
    };

    return (
        <div className="blog__posts-content">
            <div className="blog__description-container">
                <img className='blog__post-image' src={post?.image} alt={post?.i_title}/>
                <div className="blog__top-description">
                    <div className="blog__posts-pretitle">{post?.additional}</div>
                    <h1 className="blog__posts-title">{post?.i_title}</h1>
                    <div className="blog__posts-date">{post?.i_caption}</div>
                </div>
            </div>
            <div className="blog__description-content">
                <div className="blog__posts-description">
                    <div className="blog__description-top">
                        <h2 className="blog__description-title">{post?.name}</h2>
                        <div className="product__social blog__social">
                            <SocialShare trans={trans}/>
                        </div>
                    </div>
                    <div className="blog__description-subtitle">
                        <div className="blog__description-by">{trans?.author} <span
                            className="blog__description-author">{post?.author}</span></div>
                        <div className="blog__description-month">{post?.created_at}</div>
                    </div>
                    <div className="blog__description-text">{parse(String(post?.excerpt ?? ''))}</div>
                    <Link className="blog__description-more" to={`${post?.slug}`}>{trans?.['btn-read-more']}</Link>
                </div>
            </div>
        </div>
    )
}

export default BlogInfo