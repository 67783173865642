import React from 'react'

const SizeProduct = ({ savedOption, setSavedOption, sizeItem, active, sizeActive, setSizeActive, index, item, setSelectedProductSize }) => {
    
    const sizeHandler = () => {

        if (sizeActive !== index) {
            setSizeActive(index)
            setSelectedProductSize(true)
        }
        setSavedOption({ ...savedOption, savedSize: item })

    }

    return (
        <>
            <li className={`category__size-product  ${sizeActive === index ? "active-category" : ""} `} onClick={sizeHandler}>
                <input className="gallery__size-link" type="radio" />
                {item}
            </li>
        </>
    )
}

export default SizeProduct