import { createSlice } from "@reduxjs/toolkit";

const addressSlice = createSlice({
    name: 'addresses',
    initialState: {
        local: localStorage.getItem('address') ? JSON.parse(localStorage.getItem('address')) : null,
        data: [],
        regions: [],
        loading: false
    },

    reducers: {
        setRegions: (state, action) => {
            state.regions = action.payload
        },
        setAddresses: (state, action) => {
          state.data = action.payload
          state.loading = false
        },
        setAddressesLoading: (state, action) => {
            state.loading = action.payload
        },
        setLocalAddress: (state, action) => {
            state.local = action.payload
            localStorage.setItem('address', JSON.stringify(action.payload))
        }
    }
})

export const { setRegions, setAddresses, setAddressesLoading, setLocalAddress } = addressSlice.actions
export default addressSlice.reducer