import {createAsyncThunk} from "@reduxjs/toolkit";
import getErrorMessage from "../services/utils/error-message";
import currencyService from "../services/currency.service";
import { setCurrentDataCurrency, setCurrentValueCurrency, setCurrencyLoading } from "../components/Redux/CurrencyReducer";


export const changeCurrency = createAsyncThunk(
    "currency/post",
    async ({ currency }, thunk) => {
        try {
            thunk.dispatch(setCurrencyLoading(true))
            const response = await currencyService.post(currency);
            thunk.dispatch(setCurrentDataCurrency(response.data))
            thunk.dispatch(setCurrentValueCurrency(response.data.code))
            
            return response;
        } catch (error) {
            thunk.dispatch(setCurrencyLoading(false))
            return thunk.rejectWithValue(getErrorMessage(error));
        }
    }
);
