import {createAsyncThunk} from "@reduxjs/toolkit";
import getErrorMessage from "../services/utils/error-message";
import InfoPageService from "../services/infopage.service";
import { setInfoPage, setLoading } from "../components/Redux/InfoPageReducer";

export const getInfoPage = createAsyncThunk(
    "infoPage/get",
    async ({url, locale}, thunk) => {
        try {
            thunk.dispatch(setLoading(true));
            const infoData = await InfoPageService.get(url,locale);
            thunk.dispatch(setInfoPage(infoData));
            thunk.dispatch(setLoading(false));
            return infoData;
        } catch (error) {
            thunk.dispatch(setLoading(false));
            return thunk.rejectWithValue(getErrorMessage(error));
        }
    }
);
