import {createAsyncThunk} from "@reduxjs/toolkit";
import getErrorMessage from "../services/utils/error-message";
import OrdersService from "../services/orders.service";
import {setOrderInProfile, setOrderPagination, setOrdersLoading} from "../components/Redux/OrdersReducer";

export const getOrder = createAsyncThunk(
    "orders/get_one",
    async ({ order_id }, thunk) => {
        try {
            return await OrdersService.get(order_id);
        } catch (error) {
            return thunk.rejectWithValue(getErrorMessage(error));
        }
    }
);

export const getOrders = createAsyncThunk(
    "orders/get",
    async ({ page, locale }, thunk) => {
        try {
            thunk.dispatch(setOrdersLoading(true))
            const response = await OrdersService.get(page, locale);
            await thunk.dispatch(setOrderInProfile(response?.data))
            await thunk.dispatch(setOrderPagination(response?.meta))
            thunk.dispatch(setOrdersLoading(false))
            return response
        } catch (error) {
            await thunk.dispatch(setOrderInProfile([]))
            await thunk.dispatch(setOrderPagination(undefined))
            return thunk.rejectWithValue(getErrorMessage(error));
        }
    }
);

export const buyNowOrder = createAsyncThunk(
    "orders/buy_now",
    async ({product_id, email, first_name, last_name, phone, size, savedOption, locale, quantity}, thunk) => {
        try {
            thunk.dispatch(setOrdersLoading(true))
            const response = await OrdersService.buyNow(product_id, email, first_name, last_name, phone, size, savedOption, locale, quantity);
            thunk.dispatch(setOrdersLoading(false))
            return response;
        } catch (error) {
            return thunk.rejectWithValue(getErrorMessage(error));
        }
    }
);

export const cancelOrder = createAsyncThunk(
    "orders/cancel",
    async ({ order_id }, thunk) => {
        try {
            return await OrdersService.cancel(order_id);
        } catch (error) {
            return thunk.rejectWithValue(getErrorMessage(error));
        }
    }
);

export const report = createAsyncThunk(
    "orders/report",
    async ({ locale, name, email, message, order }, thunk) => {
        try {
            return await OrdersService.report(locale, name, email, message, order);
        } catch (error) {
            return thunk.rejectWithValue(getErrorMessage(error));
        }
    }
);
