import request from "./utils/request";

const getByCategory = (slug, params) => {
    return request().get(`/categories/category/${slug}`, { params }).then(response => response.data);
};

const getProduct = (slug, locale, currency) => {
    return request().get(`/products/product/${slug}`, { params: { locale, currency } }).then(response => response.data);
};

const searchProducts = (query, locale, page) => {
    return request().get(`/products`, { params: { search: query, locale, page, limit: 12 } }).then(response => response.data);
};

const buyNow = (locale, product_id, quantity, size, name, email, phone ) => {
    return request().post(`/checkout/buynow`, { locale, product_id, quantity, size, name, email, phone }).then(response => response.data);
};

const productsService = { getByCategory, getProduct, searchProducts, buyNow };

export default productsService;