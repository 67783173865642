import React from 'react'

const FooterSocial = ({ sprite, data }) => {
  return (
    <div className="footer__social">
      {data?.links?.facebook && <a className="footer__social-link" target="_blank" href={data?.links?.facebook?.url} title={data?.links?.facebook?.title}>
        <svg className="icon facebook-wh">
          <use href={sprite + '#facebook-wh'}></use>
        </svg>
      </a>}
      {data?.links?.twitter && <a className="footer__social-link" target="_blank" href={data?.links?.twitter?.url} title={data?.links?.twitter?.title}> 
        <svg className="icon twitter-wh">
            <use href={sprite + '#twitter-wh'}></use>
        </svg>
      </a>}
     { data?.links?.instagram && <a className="footer__social-link" target="_blank" href={data?.links?.instagram?.url} title={data?.links?.instagram?.title}>
        <svg className="icon instagram-wh">
          <use href={sprite + '#instagram-wh'}></use>
        </svg>
      </a>
      }
      { data?.links?.telegram && <a className="footer__social-link" target="_blank" href={data?.links?.telegram?.url} title={data?.links?.telegram?.title}>
        <svg className="icon instagram-wh">
          <use href={sprite + '#telegram'}></use>
        </svg>
      </a>
      }
    </div>
  )
}

export default FooterSocial