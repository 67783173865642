import request from "./utils/request";

const get = (page = undefined , locale = 'en') => {
    return request().get(`/orders`, {params: { page, locale }}).then(response => response.data);
};

const buyNow = (product_id, email, first_name, last_name, phone, size, savedOption, locale, quantity) => {
    return request().post(`/checkout/cart/add/${product_id}`, { is_buy_now: true, email, first_name, last_name, phone, size, savedOption, quantity }, { params: { locale } }).then(response => response.data)
};

const cancel = (order_id) => {
    return request().delete(`/orders/${order_id}/cancel`).then(response => response?.data);
};

const report = (locale, name, email, message, order = undefined) => {
    return request().post(`reports/save-report`, {locale, name, email, message, order}).then(response => response?.data);
};

const addressesService = { get, buyNow, cancel, report };

export default addressesService;