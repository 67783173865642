import { createSlice } from "@reduxjs/toolkit";

const articlesSlice = createSlice({
    name: 'articles',
    initialState: {
        data: [],
        article: [],
        loading: true
    },

    reducers: {
        setArticles: (state, action) => {
            state.data = action.payload
            state.loading = false
        },
        setArticle: (state, action) => {
            state.article = action.payload
        },
    }
})

export const { setArticles, setArticle } = articlesSlice.actions
export default articlesSlice.reducer