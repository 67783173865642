import request from "./utils/request";

const get = (params) => {
    return request().get(params.slug, { params: params} ).then(response => response.data)
};

const send = (name, email, message, slug) => {
    return request().post( slug, {name, email, message} ).then(response => response.data)
};


const contactsService = { get, send };

export default contactsService;