import React, { useEffect, useRef } from 'react'
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import sprite from "../../../../assets/images/icons/sprite.svg"
import logo from "../../../../assets/images/pictures/logo.png"
import { useClickOutside } from '../../../../hooks/useClickOutside';
import InputMask from "react-input-mask";
import eye from "../../../../assets/images/icons/eye.svg"
import {deleteCurrentUser, setCurrentUser} from '../../../Redux/UserReducer';
import { Link } from 'react-router-dom';
import userRegister from "../../../../assets/images/pictures/user.png"
import {REACT_APP_SERVER_API} from "../../../../constants";
import styled from './PersonalData.module.css';
import { logout as logoutSlice } from '../../../../slices/auth'
import {disableScroll, enableScroll} from "../../../../helpers/documentElement";

const PersonalData = ({ data, trans }) => {

    const [logout, setLogout] = useState(false)
    const [popup, setPopup] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const profile = useSelector(state => state?.profile?.profile)
    const locale = useSelector(state => state?.language?.currentLanguage);
    const dispatch = useDispatch()
    const user = useSelector(user => user?.user?.currentUser)
    const currency = useSelector(state => state?.currency?.currentDataCurrency)


    const ref = useRef(null)
    const logOut = () => {
        reset()
        dispatch(logoutSlice({locale}))
        setLogout(false)
        setPopup(false)
        enableScroll()
        reset()
        dispatch(deleteCurrentUser());
        window.scrollTo({
            top: 200,
            behavior: "smooth"
        });
    }

    useEffect(() => { if (user === null) { reset() } }, [user])

    const close = () => {
        setLogout(false)
        setPopup(false)
        enableScroll()
    }
    document.onkeyup = function (evt) {
        evt = evt || window.event;
        if (evt.keyCode === 27 && logout) {
            setLogout(!logout)
            enableScroll()
        }
    };
    const handleLogout = () => {
        setPopup('logout')
        setLogout(!logout)
        disableScroll()
    }
    const outside = useClickOutside(() => {
        setLogout(false)
        setPopup(false)
        enableScroll()
    })
    const {
        register,
        handleSubmit, reset, watch, setValue, resetField,
        formState: { errors },
        
    } = useForm({ mode: 'all' });
       
    const onSubmit = async (data) => {
        const result = await fetch(`${REACT_APP_SERVER_API}/customer/profile`, {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer" + user?.token
            },
            body: JSON.stringify({
                token: user?.token,
                first_name: data?.name,
                last_name: data?.lastName,
                name: `${data?.name} ${data?.lastName}`,
                email: data?.email,
                phone: data?.phone,
                "reward_card": data?.reward_card,
            })
        })
        const resultInJson = await result.json()
        dispatch(setCurrentUser({
                ...resultInJson,
                token: user?.token
            }));
        setPopup('update')
        setLogout(!logout)
        disableScroll()
        reset()
        ref.current.scrollIntoView({ behavior: 'smooth' })
    };

    const newPassword = useRef()
    newPassword.current = watch("newPassword", "");
    const handlePassword = () => { setShowPassword(!showPassword) }
        
    const formatChars = {
        '9': '[0-9]',
        '6': '[6-7]',
        '1': '[1-9]',
        'a': '[A-Za-z]',
        '*': '[A-Za-z0-9]'
    } 
    

    return (
        <>
            <div className={`overlay ${logout ? "active" : ""}`}>
                {popup === 'logout' &&
                    <div className="problem__popup" ref={outside}>
                        <div className="product__popup-close" onClick={close}><svg className="icon close-product">
                            <use href={sprite + '#close-product-popup'}></use>
                        </svg></div>
                        <div className="problem__popup-title logout"> {trans?.register?.logout?.title}</div>
                        <div className="logout__btns">
                            <div className="logout__btn-cancel" onClick={close}>{trans?.register?.logout?.['btn-cancel']}</div>
                            <div className="logout__btn-logout" onClick={logOut}>{trans?.register?.logout?.['btn-logout']}</div>
                        </div>
                    </div>
                }
                {popup === 'update' &&
                    <div className="message" ref={outside}>
                        <div className="product__popup-close" onClick={close}>
                            <svg className="icon close-product">
                                <use href={sprite + '#close-product-popup'}></use>
                            </svg>
                        </div>
                        <div className="message__bg">
                            <div className="message__brand">
                                <img className='message__logo' src={logo} alt="" />
                            </div>
                        </div>
                        <div className="message__info">
                            <div className="message__title">{user?.message ?? "Data updated"}</div>
                            <div className="message__btn" onClick={close}>{trans?.page?.contacts?.['btn-continue'] ?? 'Continue'}</div>
                        </div>
                    </div>
                }
            </div>
            <div className={`profile__data ${data === "data" && "active-profile"}`}>
                {user ?
                    <>
                        <h3 className="profile__data-title" ref={ref}>{trans?.['contacts']?.['btn-personal-data']}</h3>
                        <div className="profile__mailadd-title">{trans?.['register']?.['sign-in-text']?.['input-email']}</div>
                        <form className="profile__inputs-content" onSubmit={handleSubmit(onSubmit)}>
                            <div className="profile__input-mail">
                                <div className="profile__input-newmail">
                                    <div className="profile__newmail-title">{trans?.['register']?.['sign-in-text']?.['input-email']}:</div>
                                    <input className="registration__mailinput" disabled  {...register('email', {
                                        value: user ? user?.data?.email : '',
                                        required: { value: true, message: trans?.register?.['reset-password']?.['input-email-required'] },
                                        pattern: { value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g, message: trans?.register?.['signup-text']?.['input-email-error'] ?? 'Enter a valid email' }
                                    })} type="text" placeholder="Email" defaultValue={user ? user?.data?.email : ''}/>
                                    {errors.email && <p className='error-mesage'>{errors.email.message}</p>}
                                </div>
                                <div className="profile__input-confmail">
                                </div>
                            </div>
                            <h3 className="profile__userdata-title">{trans?.['my-profile']?.['personal-data']?.['title-userdata']}</h3>
                            <div className="profile__input-userdata">
                                <div className="profile__input-username">
                                    <div className="profile__username-title">{trans?.['my-profile']?.['personal-data']?.['input-first-name']}</div>
                                    <input className="registration__name-input" {...register('name', {
                                        required: { value: true, message: trans?.['my-profile']?.['personal-data']?.['input-first-name-error'] ?? 'Name is required.' },
                                        pattern: { value: /^[A-Za-z]+$/i, message: trans?.['my-profile']?.['personal-data']?.['input-first-name-error'] }
                                    })} type="text" placeholder="Name" defaultValue={user ? user?.data?.first_name : ''} />
                                    {errors.name && <p className='error-mesage'>{errors.name.message}</p>}
                                </div>
                                <div className="profile__input-lastname">
                                    <h3 className="profile__lastname-title">{trans?.['my-profile']?.['personal-data']?.['input-last-name']}</h3>
                                    <input className="registration__name-input" {...register('lastName', {
                                        required: { value: true, message:  trans?.['my-profile']?.['personal-data']?.['input-last-name-error'] ?? 'Last name is required.' },
                                        pattern: { value: /^[A-Za-z]+$/i, message: trans?.['my-profile']?.['personal-data']?.['input-last-name-error'] ?? 'Enter a valid lastname' }
                                    })} type="text" placeholder="Last name" defaultValue={user ? user?.data?.last_name : ''} />
                                    {errors.lastName && <p className='error-mesage'>{errors.lastName.message}</p>}
                                </div>
                            </div>
                            <h3 className="profile__password-title">{trans?.['my-profile']?.['personal-data']?.['title-password']}</h3>
                            {profile?.newPassword || profile?.Password ? <div className="profile__input-confmail profile__password">
                                <div className="profile__password-conf">{trans?.['my-profile']?.['personal-data']?.['title-password']}</div>
                                <input className='profile__confirm-input-pass' readOnly type={showPassword ? "text" : 'password'}  {...register('Password', { required: true, minLength: 5, pattern: { value: /^[0-9-A-Za-z]+$/i, message: 'Wrong password' } })} placeholder="**********" defaultValue={profile?.newPassword} autoComplete="off" />
                                <div className={`profile__show-password ${showPassword ? 'active' : ''}`}>
                                    <img className="profile__password-eye" src={eye} alt="" onClick={handlePassword} />
                                    {errors.Password && <p className='error-mesage'>{trans?.['my-profile']?.['personal-data']?.['input-password-error']}</p>}
                                </div>
                            </div> : null}

                            <div className="profile__newpass-inputs">
                                <div className="profile__newpass-new">
                                    <div className="profile__newpass-title">{trans?.['my-profile']?.['personal-data']?.['input-password']}</div>
                                    {profile ?
                                        <input type="password" name='newPassword' {...register('newPassword', {
                                            required: false,
                                            pattern: { value: /^[0-9-A-Za-z]+$/i, message: 'New password is wrong' },
                                            // validate: { value: (value) => value != confPasswordCheck && "The passwords do not match" },
                                            minLength: { value: 5, message: trans?.['my-profile']?.['personal-data']?.['input-new-password-error'] },
                                        })} placeholder="**********" autoComplete="off" />
                                        :
                                        <input type="password"  name='newPassword' {...register('newPassword',
                                            {
                                                required: { value: false, message: trans?.['my-profile']?.['personal-data']?.['input-password-required'] },
                                                // validate: { value: (value) => value != confPasswordCheck && "The passwords do not match" },
                                                minLength: { value: 5, message: trans?.['my-profile']?.['personal-data']?.['input-new-password-error'] },
                                                pattern: { value: /^[0-9-A-Za-z]+$/i, message: 'New password is wrong' },
                                            })} placeholder="**********" autoComplete="off" />
                                    }
                                    {errors.newPassword && <p className='error-mesage'>{errors.newPassword.message}</p>}
                                </div>

                                <div className="profile__confpass-new">
                                    <div className="profile__newpass-title">{trans?.['my-profile']?.['personal-data']?.['input-confirm-password']}:</div>
                                    {profile ?
                                        <input type="password" name='confPassword' {...register('confPassword', {
                                            required: false,
                                            validate: value => value.length === 0 || value === newPassword.current || trans?.['my-profile']?.['personal-data']?.['input-confirm-password-error']
                                        })} placeholder="**********" autoComplete="off" />
                                        :
                                        <input type="password" name='confPassword' {...register('confPassword', {
                                            required: false,
                                            validate: value => value === newPassword.current || trans?.['my-profile']?.['personal-data']?.['input-confirm-password-error']
                                        })} placeholder="**********" autoComplete="off" />

                                    }
                                    {errors.confPassword && <p className='error-mesage'>{errors.confPassword.message}</p>}
                                </div>
                            </div>

                            <h3 className="profile__contact-title">{trans?.['my-profile']?.['personal-data']?.['title-contact']}</h3>
                            <div className="profile__contact-subtitle">{trans?.['my-profile']?.['personal-data']?.['subtitle-password']}:
                            </div>
                            <div className="profile__mobile-title">{trans?.['my-profile']?.['personal-data']?.['input-contact']}</div>
                            <div className="profile__input-mail profile-phone">
                                <div className="profile__input-newmail">
                                    <InputMask
                                        className="profile__lastname-input"
                                        placeholder={trans?.products?.['popup-buy-now']?.['input-phone']}
                                        mask={currency?.country_code === 'RO' ? '+40 199 999 999' : "+373 69 999 999"}
                                        formatChars={formatChars}
                                        defaultValue={user?.data?.phone}
                                        {...register("phone", 
                                            {
                                                required: false,
                                                minLength: 15,
                                                pattern: currency?.country_code === 'RO' ? /^\+40 [1-9]\d{0,2} \d{3} \d{3}$/ : /^\+373 [1-9]\d{0,1} \d{3} \d{3}$/,
                                            })}
                                        
                                    />
                                    
                                    {errors.phone && <p className='error-mesage'>{trans?.['my-profile']?.['personal-data']?.['input-contact-error']}</p>}
                                </div>
                                <div className="profile__input-confmail">
                                </div>
                            </div>
                           { false && <>
                                <h3 className="profile__card-title">{trans?.['my-profile']?.['personal-data']?.['title-reward-card']}</h3>
                                <div className="profile__card-subtitle">{trans?.['my-profile']?.['personal-data']?.['subtitle-reward-card']}</div>
                                </>
                            }
                            {/* <div className="profile__mobile-title">{trans?.['my-profile']?.['personal-data']?.['input-card']}:</div>
                            <div className="profile__input-mail profile-card">
                                <div className="profile__input-newmail">
                                 
                                    <input className="profile__lastname-input card-input" {...register('reward_card', {
                                        required: { value: false, message: trans?.['my-profile']?.['personal-data']?.['input-card-error'] },
                                    })} type="text" placeholder={trans?.['my-profile']?.['personal-data']?.['input-card-error']} defaultValue={user?.data?.reward_card} />
                                    {errors?.card && <p className='error-mesage'>{errors?.card}</p>}

                                </div>
                                <div className="profile__input-confmail">
                                </div>
                            </div> */}
                            {/* <div className="profile__card-example">{trans?.['my-profile']?.['personal-data']?.['description-reward-card']}</div> */}
                            <div className="profile__bottom-btns">
                                <button className="profile__update-text" type="submit">{trans?.['my-profile']?.['personal-data']?.['btn-update-profile']}</button>
                                <div className="profile__logout" onClick={handleLogout}>{trans?.['my-profile']?.['personal-data']?.['btn-log-out']}</div>
                            </div>
                        </form>
                    </> : <div className='empty__cart'>
                        <img className="empty__cart-img" src={userRegister} />
                        <div className="empty__cart-title">{trans?.['my-profile']?.['personal-data']?.['need-auth-text']}</div>
                        <div className={`empty__cart-text ${styled.links}`}>
                            {trans?.['my-profile']?.['personal-data']?.['need-auth-description']}
                            <Link to="/registration"> {trans?.['my-profile']?.['personal-data']?.['need-auth-button']} </Link>
                        </div>
                        <Link className="empty__cart-btn" to="/registration">
                            <div className="category__clear-text" >{trans?.['my-profile']?.['personal-data']?.auth}</div>
                        </Link>
                    </div>}
            </div >

        </>
    )
}

export default PersonalData