import { createSlice } from "@reduxjs/toolkit";

const categorySlice = createSlice({
    name: 'category',
    initialState: {
        currentCategory: undefined,
        loading: false
    },

    reducers: {
        setCurrentCategory: (state, action) => {
            state.currentCategory = action.payload
        },
        setCategoryLoading: (state, action) => {
            state.loading = action.payload
        }
    }
})

export const { setCurrentCategory, setCategoryLoading } = categorySlice.actions
export default categorySlice.reducer