import React from 'react'

const FooterPay = ({ sprite }) => {
    return (
        <div className="footer__pay">
            <div className="footer__pay-link">
                <svg className="icon visa">
                    <use href={sprite + '#visa'}></use>
                </svg>
            </div>
            <div className="footer__pay-link">
                <svg className="icon master">
                    <use href={sprite + '#master'}></use>
                </svg>
            </div>
            {/* <div className="footer__pay-link"><svg id="paypal" className="icon paypal">
                <use id="paypal" href={sprite + '#paypal'}></use>
            </svg></div> */}
            <div className="footer__pay-link">
                <svg className="icon master">
                    <use href={sprite + '#paynetbox'}></use>
                </svg>
            </div>
        </div>
    )
}

export default FooterPay