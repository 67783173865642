import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import Modal from "../../UI/Modal/Modal";
import ContactSocial from "./ContactSocial";
import {enableScroll} from "../../../helpers/documentElement";
import {setCart} from "../../Redux/CartReducer";
import {setCurrentCartCounter} from "../../Redux/UserCounterReducer";
import {Link, useNavigate} from "react-router-dom";
import {setProfileSection} from "../../Redux/ProfileReducer";
import {verify_status} from "../../../slices/cart";


const CartSuccessModal = ({ callback }) => {

    const trans                         = useSelector(state => state?.fetch?.data?.text)
    const user                          = useSelector(user => user?.user?.currentUser)
    const [delay, setDelay]             = useState(15000);
    const status                        = useSelector(state => state?.cart?.order_status);
    const lastOrderId                   = useSelector(state => state?.cart?.last_order_id);
    const [checkCount, setCheckCount]   = useState(0);
    const [verifiable, setVerify]       = useState(true);
    
    const checkStatus = () => {
        dispatch(verify_status(lastOrderId));
    }
 
    useEffect(() => {
      if (lastOrderId) {
    
        if (verifiable) {
            setTimeout(() => {
                checkStatus();
                setCheckCount(checkCount + 1);
            }, delay)
        }                
      }
    }, [checkCount]);


    useEffect(() => {
        // Stop Auto Checking
        if(['cancel','refund','invalid']?.includes(status?.status)) {
            setVerify(false);
        }
        // Redirect to payment page
        if(['pending_payment']?.includes(status?.status) && status?.token) {
            close();
            navigate(`/payment/${status?.token}`)
        } 
        // Redirect to success page
        if(['approved','processing','completed']?.includes(status?.status)) {
            close();
            navigate(`/success/${lastOrderId}`);
        }
    }, [status]);

    const dispatch  = useDispatch()
    const navigate  = useNavigate()

    const close = () => {
        callback(false)
        enableScroll()
        dispatch(setCart(null))
        dispatch(setCurrentCartCounter(null))
        navigate('/')
      
    }

    const myOrders = () => {
        callback(false)
        enableScroll()
        dispatch(setCart(null))
        dispatch(setCurrentCartCounter(null))
        dispatch(setProfileSection("order"))
    }

    return (
        <div className="cart_modals">
            <Modal status={true} btnClose={false} closeModal={close}>
                <h3>{trans?.checkout?.['pending-order']?.title}</h3>
                <div className="content">
                    <p>{trans?.checkout?.['pending-order']?.subtitle}</p>
                    <p>{trans?.checkout?.['pending-order']?.description}</p>
                </div>
                <div className="content">
                    {status?.status === 'pending' && <p>{trans?.checkout?.['pending-order']?.pending ?? 'Your order is still pending'}</p>}
                    {status?.status === 'cancel' && <p>{trans?.checkout?.['pending-order']?.cancel ?? 'Your order was canceled'}</p>}
                    {status?.status === 'refund' && <p>{trans?.checkout?.['pending-order']?.refund ?? 'Your order was refunded'}</p>}
                </div>
                
                <ContactSocial/>
                {user ?
                    <Link to="/profile" onClick={myOrders} className="order__home">{trans?.['my-profile']?.['btn-my-orders']}</Link>
                    : ''
                    // <Link className="order__home" to="/" onClick={close}>{trans?.checkout?.['complete-order']?.['button-go-to-home-page']}</Link>
                }
                <button onClick={checkStatus} className="order__home full-width">{trans?.checkout?.['pending-order']?.check_status ?? 'Check order status'}</button>
            </Modal>
        </div>
    )
}

export default CartSuccessModal