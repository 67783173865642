import React, {useEffect, useState} from "react";
import Reset from "./Reset/Reset";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import Meta from "../../../meta/Meta";
import RegistrationBanner from "./RegistrationBanner";
import BreadCrumbs from "../../BreadCrumbs/BreadCrumbs";
import Loader from "../../Loader/Loader";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginUser, registerUser } from "../../../slices/auth";
import {disableScroll} from "../../../helpers/documentElement";

const Registration = () => {
  const user      = useSelector(user => user?.user?.currentUser)
  const trans     = useSelector(state => state.fetch.data.text);
  const language  = useSelector(state => state.language.currentLanguage);
  const [forgot, setForgot] = useState({
    status: false,
    email: "",
  });
  const [loading, setLoading] = useState(false);
  const [logInInput, setLoginInput] = useState(null);
  const [registerInput, setRegisterInput] = useState(null);
  const [errorSignIn, setErrorSignIn] = useState({
    status: false,
    message: "",
  });
  const [errorSignUp, setErrorSignUp] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (user) {
      navigate('/profile');
    }
  });
  const handleForgot = () => {
    setForgot({
      ...forgot,
      status: !forgot.status,
    });
    disableScroll()
  };
  const logIn = async () => {
    const loginData = {
      email: logInInput?.email,
      password: logInInput?.password,
      locale: language
    };
    dispatch(loginUser(loginData)).unwrap().catch((error) => {
          setErrorSignIn({
            status: true,
            message: error,
          });
        });
  };

  const registration = async () => {
    const registerData = {
      first_name: registerInput?.name,
      last_name: registerInput?.name,
      email: registerInput?.email,
      password: registerInput?.password,
      password_confirmation: registerInput?.confirm_password,
      locale: language
    };
    await dispatch(registerUser(registerData)).unwrap()
        .then((user) => {
          dispatch(loginUser(registerData)).unwrap()
        }).catch((error) => {
          setErrorSignUp(error);
        });
  };

  return (
      <>
        <Meta
            title={"Authentication"}
            description={"Lea Lea Brand"}
            keywords={"Lea Lea Brand"}
        />
        {loading ? (
            <div className="loader__container">
              <Loader />
            </div>
        ) : (
            <section className="registration">
              <RegistrationBanner trans={trans} />
              <BreadCrumbs title={trans?.['register']?.['signup-text']?.['title']} />
              <div className="container">
                <div className="registration__content">
                  <div className="registration__content-sign">
                    <SignIn
                        errorSignIn={errorSignIn}
                        logInInput={logInInput}
                        setLoginInput={setLoginInput}
                        logIn={logIn}
                        setLoading={setLoading}
                        handleForgot={handleForgot}
                        trans={trans}
                    />
                    
                  </div>
                  <Reset forgot={forgot} setForgot={setForgot} trans={trans} />
                  <div className="registration__line"></div>
                  <SignUp
                      errorSignUp={errorSignUp}
                      setErrorSignUp={setErrorSignUp}
                      registerInput={registerInput}
                      registration={registration}
                      setRegisterInput={setRegisterInput}
                      trans={trans}
                  />
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Registration;
