import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import Orders from './Orders';
import Addresses from './Addresses';
import Meta from '../../../meta/Meta';
import OrderEmpty from './OrderEmpty';
import ProfileButtons from './ProfileButtons';
import PersonalData from './PersonalData/PersonalData';
import ProfileBanner from './ProfileBanner';
import ModalOverlay from './ModalOverlay';
import BreadCrumbs from '../../BreadCrumbs/BreadCrumbs';
import {enableScroll} from "../../../helpers/documentElement";
import {getOrders} from "../../../slices/orders";

const Profile = ({setAddress, address}) => {
    const trans = useSelector(state => state?.fetch?.data?.text)
    const user = useSelector(user => user?.user?.currentUser)
    const [overlay, setOverlay] = useState(false)
    const [addressPopup, setAddressPopup] = useState(false)
    const [editableAddress, setEditableAddress] = useState({})
    const items = useSelector(state => state?.orders?.data);
    const section = useSelector(state => state?.profile?.section)
    const locale = useSelector(state => state?.language?.currentLanguage);
    const dispatch = useDispatch();
    
    useEffect(() => {
        if(user) dispatch(getOrders({locale}));
    }, [user])



    document.onkeyup = function (evt) {
        evt = evt || window.event;
        if (evt.keyCode === 27 && addressPopup) {
            setAddressPopup(false)
            setOverlay(false)
            enableScroll()
        }
    };

    const close = () => {
        setAddressPopup(false)
        setOverlay(false)
        enableScroll()
        setEditableAddress({})
    }
  
    return (
        <div className='profile'>
            <Meta 
                title={trans?.['my-profile']?.['btn-personal-data']} 
                // description={"Lea Lea Brand"} 
                // keywords={"Lea Lea Brand"}
            />
            <ProfileBanner/>
            <BreadCrumbs title={trans?.['my-profile']?.['btn-personal-data']}/>
            <section className="profile">
                <ModalOverlay overlay={overlay} close={close}/>
                <div className="container">
                    <div className="profile__content">
                        {user && <ProfileButtons section={section} user={user} trans={trans}/>}
                        {section && <PersonalData data={section} trans={trans}/>}
                        {user && <Addresses adress={address} setAdress={setAddress} editableAddress={editableAddress}
                                   setEditableAddress={setEditableAddress} data={section} close={close} overlay={overlay}
                                   setOverlay={setOverlay} setAddressPopup={setAddressPopup}
                                   addressPopup={addressPopup} trans={trans}/>}
                        {items.length > 0 ? <Orders items={items} data={section} counter={items.length} trans={trans}/>
                            : <OrderEmpty data={section} trans={trans}/>}
                    </div>
                </div>
            </section>
        </div>

    )
}

export default Profile