import { createSlice } from "@reduxjs/toolkit";

const fetchSlice = createSlice({
    name: 'fetch',
    initialState: {
        data: [],
        loading: true
    },

    reducers: {
        setFetch: (state, action) => {
            state.data = action.payload
            state.loading = false
        },
        setFetchLoading: (state, action) => {
            state.loading = action.payload
        }
    }
})

export const { setFetch, setFetchLoading } = fetchSlice.actions
export default fetchSlice.reducer