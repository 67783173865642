import React from 'react'

const WishBanner = ({ favorites, trans }) => {
    return (
        <div className="baner__container">
            <div className="category-baner">
                <div className="container">
                    <div className="category-baner__title">{trans?.wishlist?.title}</div>
                </div>
            </div>
        </div>
    )
}

export default WishBanner