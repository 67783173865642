import React from 'react'

const PlacingBanner = ({title = '', subtitle = ''}) => {
    return (
        <div>
            <div className="baner__container">
                <div className="baner__container">
                    <div className="category-baner">
                        <div className="container">
                            <div className="category-baner__title">{title}</div>
                           {subtitle && <div className="category-baner__subtitle">{subtitle}</div>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PlacingBanner