import React from 'react'
import { Link } from 'react-router-dom'
import empty from "../../../assets/images/pictures/empty-cart.png"
const SearchEmpty = ({trans}) => {
    return (
        <div className='empty__cart'>
            <img className="empty__cart-img" src={empty} alt="" />
            <div className="empty__cart-title">{trans?.search?.['empty-page-title']}</div>
            <div className="empty__cart-text">{trans?.search?.['empty-page-subtitle']}</div>
            <Link to="/" className="empty__cart-btn">{trans?.search?.['btn-search']}</Link>
        </div>
    )
}

export default SearchEmpty