import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import Pagination from '../../Pagination/Pagination'
import OrderCard from './OrderCard'
import {getOrders} from "../../../slices/orders";

const Orders = ({ data, trans }) => {
    const [currentPage, setCurrentPage] = useState(1)
    const orders = useSelector(state => state.orders.data);
    const pagination = useSelector(state => state.orders.pagination);
    const locale = useSelector(state => state.language.currentLanguage);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getOrders({ page: currentPage, locale }))
    }, [currentPage])

    const onPageChange = pageNumber => {
        setCurrentPage(pageNumber)
        window.scrollTo({
            top: 500,
            left: 0,
            behavior: "smooth"
        });
    }

    return (
        <div className={`profile__data ${data === "order" && "active-profile"}`}>
            <div className="profile__myorder">
                <h3 className="profile__myorder-title">{trans?.['my-profile']?.['my-orders']?.['title']}</h3>
                <div className="profile__completed-text">{trans?.['my-profile']?.['my-orders']?.['subtitle']}</div>
                {orders?.map(order => <OrderCard order={order} key={order?.id} />)}
            </div>

            {pagination !== undefined && <Pagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalCount={pagination?.total}
                pageSize={pagination?.per_page}
                lastPage={pagination?.last_page}
                onPageChange={onPageChange}
            />}

        </div>
    )
}

export default Orders