import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
    name: 'cart',
    initialState: {
        data: null,
        in_progress: false,
        loading: false,
        payment: null,
        paymentLoading: false,
        payment_redirect: null,
        success_order: null,
        last_order_id: null,
        order_status: null,
        verifying: false,
        coupon_total: localStorage.getItem('coupon_total') ? localStorage.getItem('coupon_total') : null,
        discount_total: localStorage.getItem('discount_total') ? localStorage.getItem('discount_total') : null
    },
    reducers: {
        setCart: (state, action) => {
            state.data = action.payload
            state.loading = false
        },
        setCartProgress: (state, action) => {
            state.in_progress = action.payload
        },
        setCartLoading: (state, action) => {
            state.loading = action.payload
        },
        setCouponTotal: (state, action) => {
            state.coupon_total = action.payload
            localStorage.setItem('coupon_total', action.payload)
        },
        setDiscountTotal: (state, action) => {
            state.discount_total = action.payload
            localStorage.setItem('discount_total', action.payload)
        },
        setPayment: (state, action) => {
            state.payment = action.payload
            state.paymentLoading = false
        },
        setPaymentLoading: (state, action) => {
            state.paymentLoading = action.payload
        },
        setPaymentRedirect: (state, action) => {
            state.payment_redirect = action.payload
            state.paymentLoading = false
        },
        setSuccess: (state, action) => {
            state.success_order = action.payload
        },
        setVerifyProgress: (state, action) => {
            state.verifying = action.payload
        },
        setOrderStatus: (state, action) => {
            state.order_status = action.payload
        },
        setLastOrder: (state, action) => {
            state.last_order_id = action.payload
        },
    }
})


export const { setCart, setCartProgress, setCartLoading, setCouponTotal, setDiscountTotal, setPayment, setPaymentLoading, setPaymentRedirect, setSuccess, setVerifyProgress, setOrderStatus, setLastOrder } = cartSlice.actions
export default cartSlice.reducer