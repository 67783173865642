import React from 'react'
import useWindowDimensions from '../../../hooks/useWindowDimensions';

const PrivacyBanner = ({title = '', subtitle = '', image = null, image_mobile = null}) => {

    const windowDimensions  = useWindowDimensions();
    const background_url    = windowDimensions?.width >= 1024 ? image : image_mobile;
    const styles = { 
      backgroundImage: `url("${background_url}")`,
    };

    return (
        <div className="baner__container" >
            <div className="baner__container">
                <div className="category-baner" style={styles}>
                    <div className="container">
                        <div className="category-baner__title">{title}</div>
                       {subtitle && <div className="category-baner__subtitle">{subtitle}</div>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PrivacyBanner