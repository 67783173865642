import React, {useState} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import sprite from "../../../assets/images/icons/sprite.svg"
import {addToWishlist} from "../../../slices/wishlist";

const AddToWish = ({ product }) => {
    const dispatch = useDispatch()
    const [userFavorite, setUserFavorite] = useState(product?.is_wishlisted)
    const trans = useSelector(state => state?.fetch?.data?.text)

    const favoriteClick = () => {
        dispatch(addToWishlist(product?.id)).unwrap().then(response => {
            setUserFavorite(!userFavorite)
        }).catch();
    };

    return (
        <div className="product__favorite" onClick={favoriteClick}>
            {
                <div className={`product__favorite-content ${ userFavorite ? 'active' : ''}`}>
                    <svg className="favorite-icon">
                        <use href={sprite + '#favorite'}></use>
                    </svg>
                    <div className="product__favorite-text">{trans?.products?.['add-to-wishlist']}</div>
                </div>
            }
        </div>

    )
}

export default AddToWish