import React, {useMemo, useState} from 'react'
import Meta from '../../../meta/Meta';
import SearchEmpty from './SearchEmpty';
import {useDispatch, useSelector} from 'react-redux';
import SearchBanner from './SearchBanner';
import BreadCrumbs from '../../BreadCrumbs/BreadCrumbs';
import {useParams} from "react-router-dom";
import {searchProducts} from "../../../slices/products";
import {disableScroll, enableScroll} from "../../../helpers/documentElement";
import Pagination from "../../Pagination/Pagination";
import Goods from "../../Goods/Goods";
import Loader from "../../Loader/Loader";


const Search = () => {
    const params        = useParams()
    const language      = useSelector(state => state?.language?.currentLanguage);
    const trans         = useSelector(state => state.fetch.data.text)
    const searchItems   = useSelector(state => state.search.currentSearch)
    const loading       = useSelector(state => state.search.loading)
    const dispatch      = useDispatch()
    const [pagination, setPagination] = useState(undefined);
    const [currentPage, setCurrentPage] = useState(1);

    const search = () => {
        disableScroll()
        dispatch(searchProducts({ query: params.query, locale: language, page: currentPage })).unwrap()
            .then(response => {
                enableScroll()
                setPagination(response?.meta)
            })
            .catch(e => {
                enableScroll()
                setPagination(undefined)
            })
    }

    useMemo(() => {
        search()
    }, [language, currentPage])

    useMemo(() => {
        if(searchItems !== undefined) currentPage !== 1 ? setCurrentPage(1) : search()
    }, [params.query])

    const onPageChange = (pageNumber) => {
        setTimeout(() => {
            window.scrollTo({
                top: 200,
                behavior: "smooth",
            });
        }, 400);
        setCurrentPage(pageNumber);
    };

    return (
        <>
            {loading ?
                <div className="loader__container">
                    <Loader/>
                </div>
                :
                <div className='search-page'>
                    <Meta 
                        title={trans?.search?.['page-title']} 
                        // description={"Lea Lea Brand"}
                        // keywords={"Lea Lea Brand"}
                    />
                    <SearchBanner items={pagination?.total}/>
                    <section className="category wish">
                        <BreadCrumbs title={trans?.search?.['page-title']}/>
                        <div className="container">
                            {searchItems?.length > 0 ?
                                <>
                                    <div className="category__content wish-content">
                                        <div className="goods">
                                            <div className="gallery__slider">
                                                {searchItems?.length > 0 && searchItems?.map((product) =>
                                                    <Goods product={product} key={product?.id}/>
                                                )}
                                            </div>
                                            {pagination !== undefined && <Pagination
                                                currentPage={currentPage}
                                                setCurrentPage={setCurrentPage}
                                                totalCount={pagination?.total}
                                                pageSize={pagination?.per_page}
                                                lastPage={pagination?.last_page}
                                                onPageChange={onPageChange}
                                            />}
                                        </div>
                                    </div>
                                </>
                                :
                                <SearchEmpty trans={trans}/>}
                        </div>
                    </section>
                </div>
        }
        </>
    )
}

export default Search