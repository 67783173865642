import React, {useEffect, useState} from 'react'
import InputMask from "react-input-mask";
import {useForm} from "react-hook-form";
import {useSelector} from "react-redux";

const CartAddressInputs = ({ name, email, phone, setName, setPhone, setEmail, addressError, saveAddress, register, setValue, errors }) => {

    const user      = useSelector(state => state.user.currentUser);
    const trans     = useSelector(state => state?.fetch?.data?.text)
    const userCart  = useSelector(state => state.cart.data);
    const currency = useSelector(state => state?.currency?.currentDataCurrency)

    const [init, setInit] = useState(false);

    const formatChars = {
        '9': '[0-9]',
        '6': '[6-7]',
        '1': '[1-9]',
        'a': '[A-Za-z]',
        '*': '[A-Za-z0-9]'
    } 

    useEffect(() => {
        if(userCart?.shipping_address) {
            if(userCart?.shipping_address?.first_name) {
                setName(userCart?.shipping_address?.first_name)
                setValue("name", userCart?.shipping_address?.first_name ?? "")
            }
            if(userCart?.shipping_address?.phone) {
                setPhone(userCart?.shipping_address?.phone)
                setValue("number", userCart?.shipping_address?.phone ?? "")
            }
            if(userCart?.shipping_address?.email) {
                setEmail(userCart?.shipping_address?.email)
                setValue("mail", userCart?.shipping_address?.email ?? "")
            }
        } else {
            if(name === null) {
                setName(userCart?.customer_first_name)
                setValue("name", userCart?.customer_first_name ?? "")
            }
            if(phone === null) {
                setPhone(user?.data?.phone)
                setValue("number", user?.data?.phone ?? "")
            }
            if(email === null) {
                setEmail(userCart?.customer_email)
                setValue("mail", userCart?.customer_email ?? "")
            }
        }
        setInit(true)
    }, [userCart])

    const save = () => {
        if(!errors.name && !errors.number && !errors.mail) {
            saveAddress()
        }
    }

    return (
        <>
            {init &&
                <div className="cart__contact-inputs cart-container ">
                    <input className="registration__name-input"
                           value={name}
                           {...register('name',
                               {
                                   onChange: (e) => setName(e.target.value),
                                   onBlur: save,
                                   required: true,
                                   pattern: /^[A-Za-z, ""]+$/i
                               })
                           }
                           type="text"
                           placeholder="Name"/>
                            {errors.name && <p className='error-mesage'>{ trans?. register?.['signup-text']?.['input-name-error'] ?? 'Enter a valid name'}</p>}

                    <InputMask className="registration__name-input"
                               id='registration__phone-input'
                               value={phone}
                               placeholder='Phone' 
                               mask={currency?.country_code === 'RO' ? '+40 199 999 999' : "+373 69 999 999"}
                               formatChars={formatChars}
                               {...register("number",
                                   {
                                       onChange: (e) => setPhone(e.target.value),
                                       onBlur: save,
                                       alwaysShowMask: true,
                                       required: true,
                                       minLength: 15,
                                       pattern: currency?.country_code === 'RO' ? /^\+40 [1-9]\d{0,2} \d{3} \d{3}$/ : /^\+373 [1-9]\d{0,1} \d{3} \d{3}$/,
 
                                   })
                               } />
                               {errors.number && (
                                <>
                                    {
                                        currency?.country_code === 'RO' 
                                        ?  <p className="error-mesage">{trans?.['my-profile']?.['my-addresses']?.['sidebar-address']?.['input-contacts-error-ro']}</p>
                                        :  <p className="error-mesage">{trans?.['my-profile']?.['my-addresses']?.['sidebar-address']?.['input-contacts-error-md']}</p>
                                    }
                                    </>
                                )}

                    <input className="registration__mailinput"
                           value={email}
                           {...register('mail',
                               {
                                   onChange: (e) => setEmail(e.target.value),
                                   onBlur: save,
                                   required: true,
                                   pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g
                               })
                           }
                           type="text"
                           placeholder="Email"/>
                    {errors.mail && <p className='error-mesage'>{trans?.register?.['signup-text']?.['input-email-error']}</p>}

                    {addressError && (errors.name || errors.number || errors.mail) &&
                        <p className='product__error'>{trans?.checkout?.['shipping-form']?.['input-data-error']}</p>
                    }

                </div>
            }
        </>
    )
}

export default CartAddressInputs