import React from 'react'
import { useSelector } from 'react-redux'
import parse from "html-react-parser"

const BlogRecent = () => {
  const trans     = useSelector(state => state?.fetch?.data?.text)
  const content   = useSelector(state => state?.articles?.data?.data?.content)

  return (
    <>
    {content && <div className="blog__recent">
      {/* <h3 className="blog__info-title">{trans?.['blog']?.['about'] ?? 'About'}</h3> */}
      <div className="blog__info-description">{parse(String(content ?? ''))}</div>
    </div>}
    </>
  )
}

export default BlogRecent