import request from "./utils/request";

const add = (product_id) => {
    return request().get(`/wishlist/add/${product_id}`).then(response => response.data);
};

const get = (locale, page) => {
    return request().get(`/wishlist`, { params: { locale, page } }).then(response => response.data);
};

const wishlistService = { add, get };

export default wishlistService;