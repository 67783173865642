// React
import Select from 'react-select';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from "react-router-dom";
// Components
import HeaderMobile from './HeaderMobile'
//Redux
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentLanguage } from '../Redux/LanguageReducer';
//Icons 
import sprite from '../../assets/images/icons/sprite.svg'
import { changeCurrency } from '../../slices/currency';
import { getRegions } from '../../slices/addresses';

const options = [
    { value: 'ro', label: 'Română', name: 'Română' },
    { value: 'ru', label: 'Русский', name: 'Русский' },
    { value: 'en', label: 'English', name: 'English' },
];


const Header = ({ fetch }) => {
    // Global State
    const language = useSelector(state => state?.language?.currentLanguage)
    const currency = useSelector(state => state?.currency?.currentValueCurrency)
    const counter = useSelector(state => state?.userCounter?.userCounter)

    // Hooks
    const dispatch = useDispatch()
    const navigate = useNavigate()

    //Local State
    const [search, setSearch] = useState(false)
    const [selectedOption, setSelectedOption] = useState(null)
    const [selectedCurrency, setSelectedCurrency] = useState(null)
    const [query, setQuery] = useState('')
    const [popup, setPopup] = useState(false)
    const [currency_options, setCurrencyOption] = useState(null)

    useEffect(() => {
        const fetchCurrency = async () => {
            let currencies = []
            fetch?.header?.currencies?.forEach(el => {
                currencies.push({
                    value: el.code,
                    label: el.code,
                    name: el.code
                })
            })
            
            if(!selectedCurrency) {
                
                let found = currencies.find(el => {
                    return el.value === currency
                })
                setSelectedCurrency(found.value)
            } 

            const res = await dispatch(changeCurrency({ currency: currency })).unwrap()
            
            if(res) {
                await dispatch(getRegions({code: res.data.country_code}))
            }

            setCurrencyOption(currencies) 
        }
        fetchCurrency()
    }, [])

    document.onkeyup = async (e) => {
        e = e || window.event;
        if (query.length > 1 && e.keyCode === 13) {
            handleSearch()
        } else if (e.keyCode === 27 && search) {
            setSearch(!search)
            setQuery('')
        }
    }

    const handleSearch = async () => {
        navigate(`/search/${query}`)
        setQuery('')
        setSearch(false)
        setPopup(false)
    }

    // Handle Change language
    useEffect(() => {
        if (selectedOption?.value !== undefined) {
            dispatch(setCurrentLanguage(selectedOption?.value));
        }
    }, [selectedOption])

    useEffect(() => {
        options?.map(({value, name}) => {
            language === value ? setSelectedOption(name) : <></>
        })
    }, [language])

    // Handle change currency
    useEffect(() => {
        currency_options?.map(({value, name}) => {
            currency === value ? setSelectedCurrency(name) : <></>
        })
    }, [currency])


    
    const handleChangeCurency = async (data) => {
        const resCurrency = await dispatch(changeCurrency({ currency: data.value })).unwrap()
       
        if(resCurrency) {
            await dispatch(getRegions({code: resCurrency.data.country_code}))
        }
        
        setSelectedCurrency(data.value) 
    }



    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            border: 'none',
        }),
        menu: (provided, state) => ({
            ...provided,
            width: '90px',
            color: '#A9A9A9',
            padding: 0,
            background: '#FFFFFF',
            left: '-56px',
            textAlign: 'right',
           
        }),
        indicatorContainer: (provided, state) => ({
            ...provided,
            display: 'none'
        }),

        menuList: (provided, state) => ({
            padding: 0
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            padding: 0,
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            const color = 'red';
            return {
              ...styles,
              backgroundColor: isDisabled
                ? undefined
                : isSelected
                ? '#d5ba6c !important'
                : isFocused
                ? undefined
                : undefined,
              color: isDisabled
              ? undefined
              : isSelected
              ? 'white !important'
              : isFocused
              ? undefined
              : undefined,
              cursor: isDisabled ? 'not-allowed' : 'default',
        
              ':active': {
                ...styles[':active'],
                backgroundColor: !isDisabled
                  ? isSelected
                    ? 'white'
                    : 'white'
                  : undefined,
              },
            };
          },

    }

    const statusProfile = () => {
        const currentProfileTag = localStorage.getItem('profile');
        if(currentProfileTag !== 'data') {
            localStorage.setItem('profile', "data")
        }
    }

    

    return (
        <>
            <header className="header">
                <div className="container">
                    <div className="header__content">

                        {/* Left side content / social icons */}
                        {fetch?.header?.links &&
                            <div className="header__leftside">
                                <ul className="header__leftside-items">
                                    
                                    
                                    {fetch?.header?.links?.facebook?.url &&
                                        <li className="header__leftside-item">
                                            <a className="header__leftside-link" rel="noreferrer"
                                               href={fetch?.header?.links?.facebook?.url} target="_blank">
                                                <svg className="icon facebook">
                                                    <use href={sprite + '#facebook-bl'}></use>
                                                </svg>
                                            </a>
                                        </li>
                                    }
                                    {fetch?.header?.links?.twitter?.url &&
                                        <li className="header__leftside-item">
                                            <a className="header__leftside-link" rel="noreferrer"
                                               href={fetch?.header?.links?.twitter?.url} target="_blank">
                                                <svg className="icon twitter">
                                                    <use href={sprite + '#twitter-bl'}></use>
                                                </svg>
                                            </a>
                                        </li>
                                    }
                                    {fetch?.header?.links?.instagram?.url &&
                                        <li className="header__leftside-item">
                                            <a className="header__leftside-link" rel="noreferrer"
                                               href={fetch?.header?.links?.instagram?.url} target="_blank">
                                                <svg className="icon instagram">
                                                    <use href={sprite + '#instagram-bl'}></use>
                                                </svg>
                                            </a>
                                        </li>
                                    }
                                    {fetch?.header?.links?.telegram?.url &&
                                        <li className="header__leftside-item">
                                            <a className="header__leftside-link" rel="noreferrer"
                                               href={fetch?.header?.links?.telegram?.url} target="_blank">
                                                <svg className="icon instagram">
                                                    <use href={sprite + '#telegram'}></use>
                                                </svg>
                                            </a>
                                        </li>
                                    }
                                    {fetch?.header?.links?.tiktok?.url &&
                                        <li className="header__leftside-item">
                                            <a className="header__leftside-link" rel="noreferrer"
                                               href={fetch?.header?.links?.tiktok?.url} target="_blank">
                                                <svg className="icon instagram">
                                                    <use href={sprite + '#tiktok'}></use>
                                                </svg>
                                            </a>
                                        </li>
                                    }
                                </ul>
                            </div>
                        }

                        {/* Right side content */}
                        <div className="header__rightside">
                            <div className='header-links'>
                                <Link to='/page/contact-us'>Contacte</Link>
                            </div>
                            <div className='header-links'>
                                <Link to='/page/conditions-shipping'>Livrare</Link>
                            </div>
                            
                            <div className="header__language-items">
                                <Select
                                    defaultValue={selectedOption}
                                    onChange={setSelectedOption}
                                    options={options}
                                    placeholder={selectedOption}
                                    isSearchable={false}
                                    // menuIsOpen={true}
                                    styles={customStyles}
                                    id={0}
                                    components={{
                                        IndicatorSeparator: () => null,
                                        DropdownIndicator: () => null
                                    }}
                                />
                            </div>
                            <div className="header__currency-items">
                                <Select
                                    defaultValue={selectedCurrency}
                                    onChange={(value) => handleChangeCurency(value)}
                                    options={currency_options}
                                    placeholder={selectedCurrency}
                                    isSearchable={false}
                                    // menuIsOpen={true}
                                    styles={customStyles}
                                    id={0}
                                    components={{
                                        IndicatorSeparator: () => null,
                                        DropdownIndicator: () => null
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <HeaderMobile 
                statusProfile={statusProfile}
                fetch={fetch} 
                setQuery={setQuery} 
                query={query} 
                setPopup={setPopup} 
                popup={popup}
                handleSearch={handleSearch}
                search={search}
                setSearch={setSearch}
                currency_options={currency_options}
                handleChangeCurency={handleChangeCurency}
            />
                
        </>
    )
}

export default Header