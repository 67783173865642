import React, { useEffect } from 'react'
import sprite from "../../assets/images/icons/sprite.svg"
import {DOTS, usePagination} from "../../helpers/pagination";

const Pagination = ({ onPageChange, totalCount, siblingCount = 1, currentPage, pageSize }) => {

    const paginationRange = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize
    });

    if (currentPage === 0 || paginationRange?.length < 2) {
        return null;
    }

    const onNext = () => {
        onPageChange(currentPage + 1);
    };

    const onPrevious = () => {
        onPageChange(currentPage - 1);
    };

    let lastPage = paginationRange?.[paginationRange?.length - 1];

    return (
        <>
            <nav className="pagination">
                <ul className="pagination__items">
                    {currentPage !== 1 &&
                        <li className={`pagination__link pagination__arrow__button pagination__prev ${currentPage === 1 ? "disable" : ''}`}
                            onClick={onPrevious}>
                            <svg className="pagination-arrow" style={{transform: 'rotate(180deg)'}}>
                                <use href={sprite + '#pagination-arrow'}></use>
                            </svg>
                        </li>
                    }

                    {paginationRange?.map(pageNumber => {
                        if (pageNumber === DOTS) {
                            return <li key={pageNumber.toString()} className={`pagination__link pagination__numbers pagination__dots disable`}>...</li>
                        }

                        return (
                            <li
                                key={pageNumber.toString()}
                                className={`pagination__link pagination__numbers ${currentPage === pageNumber ? "active-page" : ""}`}
                                onClick={() => onPageChange(pageNumber)}>
                                {pageNumber}
                            </li>
                        );
                    })}

                    {currentPage !== lastPage && currentPage > 1 &&
                        <li className={`pagination__link pagination__arrow__button pagination__next ${currentPage === lastPage ? "disable" : ''}`}
                            onClick={onNext}>
                            <svg className="pagination-arrow">
                                <use href={sprite + '#pagination-arrow'}></use>
                            </svg>
                        </li>
                    }
                </ul>
            </nav >
        </>
    )
}

export default Pagination