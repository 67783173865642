import React, {useEffect, useState} from 'react'
import Meta from '../../../meta/Meta'
import PlacingEmpty from './PlacingEmpty'
import PlacingBanner from './PlacingBanner'
import OrderItems from './OrderItems'
// import PayWindow from './PayWindow'
import PayModal from './PayModal'
import Loader from "../../../components/Loader/Loader";
import { useSelector, useDispatch } from 'react-redux'
import { invoice, onlinePay } from "../../../slices/cart";
import {useParams} from "react-router-dom";
import {disableScroll, enableScroll} from "../../../helpers/documentElement";

const Placing = () => {

    const trans         = useSelector(state => state?.fetch?.data?.text);
    const order         = useSelector(state => state?.cart?.payment?.order);
    const loading       = useSelector(state => state?.cart?.paymentLoading);
    const params        = useParams();
    const dispatch      = useDispatch();

    enableScroll();
    useEffect(() => {
        dispatch(invoice(params?.id))
    }, [params?.id])

    const [openRedirect, setRedirect] = useState(false);
    const pay = () => {
        dispatch(onlinePay(order?.id))
        setRedirect(true);
    }

    return (
        loading ? 
            <div className="loader__container">
                <Loader/>
            </div>
        :
        <div className='placing'>
            <Meta 
                title={`${trans?.checkout?.invoice?.title ?? "Order Payment"} #${params?.id}` }
            />
            <PlacingBanner 
                title={`${trans?.checkout?.invoice?.title ?? "Order Payment"} #${params?.id}` }
                subtitle={trans?.checkout?.invoice?.subtitle ?? "Pay your order online"}
            />

            {openRedirect && <PayModal callback={() => {setRedirect(false)}}/>}
            <div className="placing__container">
                {order ?
                    <div className="placing__content">
                        <div className="placing__title">{trans?.checkout?.invoice?.personal_info ?? 'Personal data'}</div>
                        <div className="placing__info">
                            <div className="placing__info-left">
                                {(order?.customer_first_name || order?.customer_last_name) && <div className="placing__info-name">
                                    <div className="placing__info-name--label">{trans?.checkout?.invoice?.fullname ?? 'Firstname/Lastname'}</div>
                                    <div className="placing__info-name--text">{order?.customer_first_name} {order?.customer_last_name}</div>
                                </div>}
                                {(order?.customer_phone || order?.shipping_address?.phone ) && <div className="placing__info-number">
                                    <div className="placing__info-number--label">{trans?.page?.contacts?.['title-phone'] ?? 'Phone number' }</div>
                                    <div className="placing__info-number--text">{order?.customer?.phone ?? order?.shipping_address?.phone}</div>
                                </div>}
                                {order?.customer_email && <div className="placing__info-mail">
                                    <div className="placing__info-mail--label">{trans?.page?.contacts?.['title-email'] ?? 'Email' }</div>
                                    <div className="placing__info-mail--text">{order?.customer_email}</div>
                                </div>}
                            </div>
                            <div className="placing__info-right">
                                { (order?.shipping_address?.address1 && order?.shipping_address?.country ) && <div className="placing__info-address">
                                    <div className="placing__info-address--label">{trans?.checkout?.invoice?.delivery_address ?? 'Delivery address' }</div>
                                    <div className="placing__info-address--text">{order?.shipping_address?.country}, {order?.shipping_address?.city}, {order?.shipping_address?.address1}, {order?.shipping_address?.postcode}</div>
                                </div>}
                                {order?.comment && <div className="placing__info-detail">
                                    <div className="placing__info-detail--label">{trans?.checkout?.invoice?.order_details ?? 'Order Details' }</div>
                                    <div className="placing__info-detail--text">{order?.admin_comment}</div>
                                </div>}
                            </div>
                        </div>
                        <div className="placing__title">{trans?.checkout?.invoice?.your_order ?? 'Your order' } {order?.increment_id}</div>
                        <div className="placing__order">
                            <OrderItems items={order?.items} />
                        </div>
                        <div className="placing__total">
                            <div className="placing__total-sub">
                                <div className="placing__total-sub--text">{trans?.checkout?.['complete-order']?.subtotal}</div>
                                <div className="placing__total-sub--price">{order?.formated_sub_total}</div>
                            </div>
                            <div className="placing__total-delivery">
                                <div className="placing__total-delivery--text">{trans?.checkout?.delivery}</div>
                                <div className="placing__total-delivery--price">{order?.formated_shipping_amount}</div>
                            </div>
                            {order?.formated_discount_amount && <div className="placing__total-discount">
                                <div className="placing__total-discount--text">{trans?.checkout?.['complete-order']?.discount}</div>
                                <div className="placing__total-discount--price">{order?.formated_discount_amount}</div>
                            </div>}
                            <div className="placing__total-total">
                                <div className="placing__total-total--text">{trans?.checkout?.['complete-order']?.total}</div>
                                <div className="placing__total-total--price">{order?.formated_grand_total}</div>
                            </div>
                        </div>
                        <div className="placing__btn" onClick={pay}>{trans?.checkout?.invoice?.pay ?? 'Pay' }</div>
                      
                    </div>
                    : <PlacingEmpty />}
            </div>

        </div>
    )
}

export default Placing