import React from "react";
import {useSelector} from "react-redux";

const CartBanner = () => {
    const counter   = useSelector(state => state?.userCounter?.userCounter)
    const trans     = useSelector(state => state?.fetch?.data?.text)
    return (
        <div className="baner__container">
            <div className="category-baner">
                <div className="container">
                    <div className="category-baner__title">{trans?.checkout?.title ?? "Cart"}</div>
                   
                        {counter?.cart !== null &&
                         <div className="category-baner__subtitle">
                            <span className="items__number">
                                {counter.cart} {trans?.checkout?.items ?? 'item(s) in cart'}
                            </span>
                        </div>
                        }
              
                </div>
            </div>
        </div>
    );
};

export default CartBanner;
