import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import Sort from "./Sort";
import Size from "./Size";
import Color from "./Color";
import Material from "./Material";
import SliderPrice from "./Slider";
import Goods from "../../Goods/Goods";
import Meta from "../../../meta/Meta";
import Loader from "../../Loader/Loader";
import {useDispatch, useSelector} from "react-redux";
import CategoryBanner from "./CategoryBanner";
import CategoryEmpty from "./CategoryEmpty";
import CategoryOverlay from "./CategoryOverlay";
import Pagination from "../../Pagination/Pagination";
import BreadCrumbs from "../../BreadCrumbs/BreadCrumbs";
import HeaderFilterMobile from "../../Header/HeaderFilterMobile";
import empty from "../../../assets/images/pictures/empty-cart.png";
import {disableScroll, enableScroll} from "../../../helpers/documentElement";
import {getProductsByCategory} from "../../../slices/products";
import {useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";
import Error from "../404/404";
import {setCurrentCategory} from "../../Redux/CategoryReducer";
import {useWindowScroll} from "../../../hooks/useWindowScroll";
 
const Category = () => {
    const currency = useSelector(state => state?.currency?.currentValueCurrency)
    const [searchParams, setSearchParams] = useSearchParams()
    //const updatedSearchParams = new URLSearchParams(searchParams.toString())
    const updatedSearchParams = useMemo(() => new URLSearchParams(searchParams.toString()), [searchParams]);
    const [_, scrollTo] = useWindowScroll();
    const trans = useSelector(state => state.fetch.data.text)
    const ref = useRef(null);
    const dispatch = useDispatch();
    const params = useParams()
    const [sort, setSort] = useState('created_at');
    const [order, setOrder] = useState('order');
    const [filter, setFilter] = useState(false);
    const [maxPrice, setMaxPrice] = useState(null);
    const [filtration, setFiltration] = useState([]);
    const [price, setPrice] = useState([0, ""]);
    const [currentPage, setCurrentPage] = useState( Number(searchParams.get('page')) || 1 )
    const [selectedSize, setSelectedSize] = useState([]);
    const [productArray, setProductArray] = useState(null);
    const [selectedColor, setSelectedColor] = useState([]);
    const [selectedMaterial, setSelectedMaterial] = useState([]);
    const category = useSelector(state => state.category.currentCategory);
    const language = useSelector(state => state.language.currentLanguage)
    const loading = useSelector(state => state.category.loading)
    const [categoryText, setCategoryText] = useState(null);
    const [sorting, setSorting] = useState([]);
    const [priceFilter, setPriceFilter] = useState(undefined)
    const [colorFilter, setColorFilter] = useState(undefined)
    const [sizeFilter, setSizeFilter] = useState(undefined)
    const [materialFilter, setMaterialFilter] = useState(undefined)
    const [pagination, setPagination] = useState(undefined);
    const [notFound, setNotFound] = useState(false)
    const [initPrice, setInitPrice] = useState(false)
    const localhost = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
       
        if( updatedSearchParams.get('page')) {
            setCurrentPage(Number(updatedSearchParams.get('page')))
        } else {
            setCurrentPage(1)
        }
        
    }, [setSearchParams, updatedSearchParams]);


    const fetchProducts = async (changed_category = false, page = currentPage) => {
        const data = {
            slug: params?.slug,
            params: {
                locale: language,
                currency: currency,
                color: changed_category ? undefined : (selectedColor.length ? selectedColor.join() : undefined),
                size: changed_category ? undefined : (selectedSize.length ? selectedSize.join() : undefined),
                material: changed_category ? undefined : (selectedMaterial.length ? selectedMaterial.join() : undefined),
                sort: changed_category ? 'created_at' : sort,
                order: changed_category ? 'desc' : order,
                price: changed_category ? undefined : (typeof price[1] === "number" ? `${price[0]},${price[1] ? price[1] : ""}` : undefined),
                page: page,
                
            }
        }
        await dispatch(getProductsByCategory(data)).unwrap().then(response => {
            setNotFound(false)
           
            
            if (response !== undefined) {
                if (changed_category) setPriceFilter(undefined)
                setFiltration(response?.options);
                setProductArray(response?.products?.data);
                setPagination(response?.products?.meta)
                setCategoryText(response?.text)

            } else {
                setNotFound(true)
            }
            scrollTo({y: 0})
        }).catch(e => {
            setNotFound(true)
        }).finally(() => {
            
        })
        enableScroll()
    };

    useEffect(() => {
        if (priceFilter?.max) {
            setPrice([0, priceFilter?.max * 1]);
            setMaxPrice(priceFilter?.max * 1);
        }
    }, [priceFilter?.max === undefined]);

    useEffect(() => {
        setPriceFilter(filtration?.find(it => it?.code === "price"))
        setColorFilter(filtration?.find(it => it?.code === "color"))
        setSizeFilter(filtration?.find(it => it?.code === "size"))
        setMaterialFilter(filtration?.find(it => it?.code === "material"))
    }, [filtration])

    useEffect(() => {
        if (category !== undefined) {
            fetchProducts(true, currentPage);
        }
    }, [currentPage]);

    useEffect(() => {
        if (category !== undefined) {
            fetchProducts(true, currentPage);
        }
    }, [currency, language]);

    const isFirstRender = useRef(true);

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
        setCurrentPage(1)
    }, [localhost.pathname])

    useEffect(() => {
        dispatch(setCurrentCategory(undefined))
        setInitPrice(false)
        setSelectedMaterial([]);
        setSelectedSize([]);
        setSelectedColor([]);
        setPrice([0, ""])
        setMaxPrice(null)
        setSort('desc')
        setOrder('created_at')

        if( updatedSearchParams.get('page')) {
            setCurrentPage(Number(updatedSearchParams.get('page')))
            fetchProducts(true);
        } else {
            setCurrentPage(1)
            fetchProducts(true, 1);
        }

    }, [params.slug])

    const onPageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const changePrice = (value) => {
        setPrice(value);
        setInitPrice(true);
    };

    const resetAll = () => {
        setSelectedMaterial([]);
        setSelectedSize([]);
        setSelectedColor([]);
        setSort('desc')
        setOrder('created_at')
        setPrice([0, maxPrice]);
        setInitPrice(true)
    };

    const setPriceSlider = () => {
        setPrice([0, maxPrice]);
        setInitPrice(true)
    };

    const close = () => {
        setFilter(!filter);
        enableScroll()
    };

    document.onkeydown = function (evt) {
        evt = evt || window.event;
        if (evt.keyCode === 27 && filter) {
            setFilter(!filter);
            enableScroll()
        }
    };
    useEffect(() => {// TODO: fix render story by to page
        if (categoryText !== null) {
            setSorting([
                {
                    id: 1,
                    title: categoryText?.['cheap'],
                    sort: "price",
                    order: "asc"
                },
                {
                    id: 2,
                    title: categoryText?.['new'],
                    sort: "created_at",
                    order: "desc"
                },
                {
                    id: 3,
                    title: categoryText?.['old'],
                    sort: "created_at",
                    order: "asc"
                },
                {
                    id: 4,
                    title: categoryText?.['expensive'],
                    sort: "price",
                    order: "desc"
                },
            ])
        }

    }, [categoryText])


    return (
        <>
            {notFound === true ?
                <Error/>
                :
                <>
                    {loading && category === undefined ?
                        <div className="loader__container">
                            <Loader/>
                        </div>
                        :
                        <div className="category">
                            <Meta
                                title={category?.name ?? category?.meta_title}
                                description={category?.meta_description}
                                keywords={category?.meta_keywords}
                                image={category?.image_url}
                            />
                            <CategoryOverlay filter={filter} close={close}/>
                            <CategoryBanner category={category}/>
                            <BreadCrumbs title={category?.name}/>
                            {/* <FilterHandler activeFilter={activeFilter} text_sort_by={trans?.category?.['sort-by']}/> */}
                            <section className="category">
                                <div className="container">
                                    <div className="category__content">
                                        {false && <div className={`category__filters ${filter ? "active" : ""}`}>
                                            <HeaderFilterMobile close={close}/>
                                            {sorting && (
                                                <div className="category__cheboxes">
                                                    <div className="category__cheboxes-top">
                                                        <div
                                                            className="category__checkbox-title">{categoryText?.['sort-by']}</div>
                                                    </div>
                                                    <div className="category__filtration-element">
                                                        {sorting?.map((check, index) => (
                                                            <Sort
                                                                key={index}
                                                                index={index}
                                                                sort={sort}
                                                                setSort={setSort}
                                                                order={order}
                                                                setOrder={setOrder}
                                                                value={check}
                                                            />
                                                        ))}
                                                    </div>
                                                </div>
                                            )}
                                            {sizeFilter?.options && (
                                                <div className="category__size">
                                                    <div className="category__size-top">
                                                        <div
                                                            className="category__size-title">{trans?.products?.size}</div>
                                                        <div className="category__reset"
                                                             onClick={() => setSelectedSize([])}>
                                                            {categoryText?.['btn-reset']}
                                                        </div>
                                                    </div>
                                                    <div className="category__size-boxes">
                                                        <ul className="category__size-items">
                                                            {sizeFilter?.options?.map((item, index) => (
                                                                <Size
                                                                    selectedSize={selectedSize}
                                                                    size={item?.name}
                                                                    id={item.id}
                                                                    key={index}
                                                                    setSelectedSize={setSelectedSize}
                                                                />
                                                            ))}
                                                        </ul>
                                                    </div>
                                                </div>
                                            )}
                                            <div className="category__price">
                                                <div className="category__price-top">
                                                    <div
                                                        className="category__price-title">{trans?.products?.price}</div>
                                                    <div className="category__reset" onClick={setPriceSlider}>
                                                        {categoryText?.['btn-reset']}
                                                    </div>
                                                </div>
                                                <SliderPrice
                                                    maxPrice={maxPrice}
                                                    changePrice={changePrice}
                                                    price={price}
                                                    text={{
                                                        textPriceMin: categoryText?.['price-min'],
                                                        textPriceMax: categoryText?.['price-max']
                                                    }}
                                                />
                                            </div>
                                            {colorFilter?.options && (
                                                <div className="category__color">
                                                    <div className="category__color-top">
                                                        <div
                                                            className="category__color-title">{trans?.products?.colors}</div>
                                                        <div className="category__reset"
                                                             onClick={() => setSelectedColor([])}>
                                                            {categoryText?.['btn-reset']}
                                                        </div>
                                                    </div>

                                                    <ul className="category__color-items">
                                                        {colorFilter.options?.map(color => (
                                                            <Color
                                                                setSelectedColor={setSelectedColor}
                                                                selectedColor={selectedColor}
                                                                col={color.swatch_value}
                                                                key={color?.id}
                                                                id={color.id}
                                                            />
                                                        ))}
                                                    </ul>
                                                </div>
                                            )}
                                            {materialFilter?.options && (
                                                <div className="category__cheboxes">
                                                    <div className="category__cheboxes-top">
                                                        <div
                                                            className="category__checkbox-title">{trans?.products?.material}</div>
                                                        <div className="category__reset"
                                                             onClick={() => setSelectedMaterial([])}>
                                                            {categoryText?.['btn-reset']}
                                                        </div>
                                                    </div>
                                                    <div className="category__filtration-element">
                                                        {materialFilter?.options?.map((item, index) => (
                                                            <Material
                                                                key={item?.id}
                                                                setSelectedMaterial={setSelectedMaterial}
                                                                selectedMaterial={selectedMaterial}
                                                                index={index}
                                                                title={item.name}
                                                                id={item.id}
                                                            />
                                                        ))}
                                                    </div>
                                                </div>
                                            )}
                                            <div className="category__clear" onClick={resetAll}>
                                                <div
                                                    className="category__clear-text">{categoryText?.['btn-clear']}</div>
                                            </div>
                                        </div>}
                                        <div className="goods" ref={ref}>

                                            {productArray?.length && pagination !== undefined ?
                                                <>
                                                    <div className="gallery__slider">
                                                        {productArray?.map((product, index) => (
                                                            <Goods
                                                                product={product}
                                                                key={index}
                                                            />
                                                        ))}
                                                    </div>
                                                    {  <Pagination
                                                        currentPage={currentPage}
                                                        setCurrentPage={setCurrentPage}
                                                        totalCount={pagination?.total}
                                                        pageSize={pagination?.per_page}
                                                        lastPage={pagination?.last_page}
                                                        onPageChange={(e) => {
                                                            onPageChange(e)
                                                            navigate(`${localhost.pathname}?page=${e}`)
                                                        }}
                                                    />}
                                                </>
                                                :
                                                <CategoryEmpty
                                                    empty={empty}
                                                    resetAll={resetAll}
                                                    text={{
                                                        emptyTitle: categoryText?.['empty-page-title'],
                                                        emptyDescription: categoryText?.['empty-page-subtitle'],
                                                        emptyBtn: categoryText?.['btn-see-all'],
                                                    }}
                                                />
                                            }

                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    }
                </>
            }
        </>
    );
};

export default Category;
