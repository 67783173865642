import React from 'react'
import parse from 'html-react-parser'
import useWindowDimensions from '../../../hooks/useWindowDimensions';

const CategoryBanner = ({category}) => {

  const windowDimensions  = useWindowDimensions();
  const background_url    = windowDimensions?.width >= 1024 ? category?.image_url : category?.image_mobile;
  const styles = { backgroundImage: `url("${background_url}")`};
 
  return (
    
    <div className="baner__container">
      { category?.image_url &&
      <div className="category-baner" style={styles}>
        <div className="container">
          <div className="category-baner__title">{category?.name}</div>
          {category?.description?.lenght && <div className="category-baner__subtitle">{ category?.description ? parse(category?.description ?? '') : null }</div>}
        </div>
      </div>
      }
    </div>

  )
}

export default CategoryBanner