import React from 'react'
import { useSelector } from 'react-redux'
const ProfileBanner = () => {

    const trans = useSelector(state => state?.fetch?.data?.text)
    const orderPagination = useSelector(state => state.orders.pagination)

    return (
        <div className="baner__container">
            <div className="baner__container">
                <div className="category-baner">
                    <div className="container">
                        <div className="category-baner__title">{trans?.['my-profile']?.['personal-data']?.['page-title']}</div>
                        {orderPagination?.total > 0 ?
                            <> {orderPagination.total > 1 ? <div className="category-baner__subtitle">{orderPagination.total} {trans?.['my-profile']?.['my-orders']?.['subtitle']}</div>
                                : <div className="category-baner__subtitle">{orderPagination.total} {trans?.['my-profile']?.['my-orders']?.['subtitle']}</div>}</>
                            : ''}

                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProfileBanner