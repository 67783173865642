import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {CASH, PAYNET} from "./Cart/Cart";
import {cartComment, cartPayment} from "../../../slices/cart";
import { Link } from 'react-router-dom'
import { useForm } from "react-hook-form";

const CartPayment = ({ payment, setPayment, paymentError, setTermsError, termsError}) => {
    const trans     = useSelector(state => state?.fetch?.data?.text)
    const header    = useSelector(state => state?.fetch?.data?.header)
    const userCart  = useSelector(state => state?.cart?.data);
    const currency = useSelector(state => state?.currency?.currentDataCurrency)
    const [comment, setComment] = useState("");
    const [agree, setAgree]     = useState(false);
    const dispatch = useDispatch()
    const { register, handleSubmit, reset, formState: { errors } } = useForm({ mode: "all" });

    useEffect(() => {
        if(payment && payment !== userCart?.payment?.method) {
            dispatch(cartPayment({method: payment}))
        }
    }, [payment])

 
    useEffect(() => {
        if(userCart?.customer_comment && userCart?.customer_comment !== comment) {
            setComment(userCart?.customer_comment)
        }
    }, [userCart?.customer_comment])

    const add_comment = () => {
        dispatch(cartComment({comment}))
    }

    useEffect(() => {
        setTermsError(!agree);
    }, [agree])

    const termAgree = () => {
        setAgree(!agree);
       
    }

    return (
        <div className="cart__payment">
            <div className="cart__payment-title cart-container">{trans?.checkout?.['shipping-form']?.['title-payment']}</div>
            <div className="cart__payment-choose cart-container">
                { currency?.country_code === 'MD' &&
                    <div className={`cart__payment-cash payment ${payment === PAYNET ? "active" : ""}`} onClick={() => setPayment(PAYNET)}>
                        <input type='radio' className="cart__online-text" />{trans?.checkout?.['shipping-form']?.['online-payment']}
                    </div>
                }
                
                <div className={`cart__payment-cash ${payment === CASH ? "active" : ""}`} onClick={() => setPayment(CASH)}>
                    <input type='radio' className="cart__cash-text" />{trans?.checkout?.['shipping-form']?.['cash-on-delivery']}
                </div>
                {paymentError ? <>{!payment ? <p className='product__error'>{trans?.checkout?.['shipping-form']?.['error-payment']}</p> : null}</> : ''}
                <div className="cart__payment-comment--input">
                    <input className="cart__payment-comment" value={comment} onChange={e => setComment(e.target.value)} onBlur={add_comment} type="text" placeholder={trans?.products.comments} />
                </div>
                
                <div className="category__filter terms_agree">
                    <input 
                        className="hidden_checkbox" 
                        value={true} 
                        type="checkbox" 
                        id="terms_agree" 
                                             
                        {...register('terms_agree',
                            {
                                onChange: (e) => termAgree(),
                                required: true
                            })
                        } 
                    />
                    <label htmlFor="terms_agree" className={`cart__payment-cash ${agree ? 'active' : ''}`}>{header?.['term-text'] ?? 'Am luat la cunoştinţă şi sunt de acord cu '}
                        <span className='terms_agree_link'>
                            <Link to={`/page/${header?.['link_to_terms']}`}> { header?.['link_text'] ?? 'Condiţiile comerciale'} </Link>
                        </span>
                    </label>
                    { termsError &&
                        <p className="product__error">
                            {trans?.checkout?.['shipping-form']?.['terms-required'] ?? 'Trebuie să fiți de acord cu condițiile'}
                        </p>
                    }
                </div>

            

            </div>
        
        </div>
        
    )
}

export default CartPayment