import React from "react";
import {useSelector} from "react-redux";
import {COURIER, TAKE} from "./Cart/Cart";

const CartTotal = () => {

    const userCart          = useSelector(state => state.cart.data);
    const coupon_total      = useSelector(state => state.cart.coupon_total);
    const discount_total    = useSelector(state => state.cart.discount_total);
    const trans             = useSelector(state => state?.fetch?.data?.text);
    

    return (
        <div className="cart__total">
            {(userCart?.selected_shipping_rate?.method === COURIER || userCart?.discount_code || userCart?.coupon_code) &&
                <ul className="cart__total-list">
                    <li className="cart__total-info">{trans?.checkout?.['complete-order']?.['subtotal']}</li>
                    <li className="cart__total-price">
                        {userCart?.formated_sub_total}
                    </li>
                </ul>
            }
            {userCart?.selected_shipping_rate?.method === COURIER &&
                <ul className="cart__total-list">
                    <li className="cart__total-info">{trans?.checkout?.delivery}:</li>
                    <li className="cart__total-price">{userCart?.selected_shipping_rate?.formated_price}</li>
                </ul>
            }
            {false && userCart?.discount_code !== null &&
                <ul className="cart__total-list">
                    <li className="cart__total-info">{trans?.checkout?.['complete-order']?.discount}</li>
                    <li className="cart__total-price">
                        {userCart?.coupon_code === null ?
                            userCart?.formated_discount
                            :
                            discount_total
                        }
                    </li>
                </ul>
            }
            {false && userCart?.coupon_code !== null &&
                <ul className="cart__total-list">
                    <li className="cart__total-info">{trans?.checkout?.coupon_code}</li>
                    <li className="cart__total-price">
                        {userCart?.discount_code === null ?
                            userCart?.formated_discount
                            :
                            coupon_total
                        }
                    </li>
                </ul>
            }

            <ul className="cart__total-list all-price">
                <li className="cart__total-info">Total</li>
                <li className="cart__total-price">
                    {userCart?.formated_grand_total}
                </li>
            </ul>
        </div>
    );
};

export default CartTotal;
