import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
// Redux
import { useDispatch, useSelector } from 'react-redux';
import { useClickOutside } from '../../hooks/useClickOutside';
import { setCurrentLanguage } from '../Redux/LanguageReducer';
import {logout as logoutSlice} from "../../slices/auth";
import {disableScroll, enableScroll} from "../../helpers/documentElement";
//Icons
import sprite from '../../assets/images/icons/sprite.svg'
import { ReactComponent as FbIcon } from '../../assets/images/icons/icon-facebook-gold.svg';
import { ReactComponent as InstagramIcon } from '../../assets/images/icons/icon-instagram-gold.svg';
import { ReactComponent as TeleframIcon } from '../../assets/images/icons/icon-telegram-gold.svg';
import { ReactComponent as CloseMenuIcon } from '../../assets/images/icons/close-menu.svg';
import { ReactComponent as MobileMenuArrow } from '../../assets/images/icons/mobile-menu-arrow.svg';

// Components
import HeaderNavigation from './HeaderNavigation';
import HeaderSearch from './HeaderSearch';
import Select from 'react-select';
import HeaderSearchDesktop from './HeaderSearchDesktop';

const options = [
    { value: 'ro', label: 'Ro', name: 'Română' },
    { value: 'ru', label: 'Ру', name: 'Русский' },
    { value: 'en', label: 'Eng', name: 'English' },
];

const HeaderMobile = ({ fetch, setQuery, query, handleSearch, setPopup, popup, setSearch, statusProfile, search, handleChangeCurency, currency_options }) => {
    // Redux state
    const favorites = useSelector(state => state?.favorite?.favoriteInCart)
    const user = useSelector(user => user?.user?.currentUser)
    const counter = useSelector(state => state?.userCounter?.userCounter)
    const locale = useSelector(state => state?.language?.currentLanguage);
    const trans = useSelector(state => state?.contacts?.data?.text);
    const currency = useSelector(state => state?.currency?.currentValueCurrency)

    // React State
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState('')
    const [isChildrenShow, setIsChildrenShow] =  useState(false)
    const [ radioValue, setRadioValue ] = useState(currency)
    
    const dispatch = useDispatch()

    // Effects
    useEffect(() => {
        if (popup) {
            disableScroll()
            document.body.style.overflow = "hidden"
        }
        else { enableScroll() }
    }, [popup])

    useEffect(() => {
        if (fetch?.header?.language !== undefined) {
            setSelectedOption(fetch?.header?.language)
        }
    }, [fetch])

    useEffect(() => {
        if (selectedOption?.value != undefined) {
            dispatch(setCurrentLanguage(selectedOption?.value));
        }
    }, [selectedOption])

    useEffect(() => {
        setRadioValue(currency)
    }, [currency])

    // Functions
    const logOut = () => {
        dispatch(logoutSlice({ locale }))
        setPopup(false)
        enableScroll()
    }

    const close = () => {
        if (popup) {
            setPopup(false)
            enableScroll()
        }
    }

    const closeSearch = () => {
        setSearch(!search)
    }
    
    const activeSearch = () => {
        setSearch(!search)
    }

    document.onkeydown = function (evt) {
        evt = evt || window.event;
        if (evt.keyCode === 27 && popup) {
            setPopup(false)
            enableScroll()
        }
    };

    const outside = useClickOutside(() => {
        setPopup(false)
        enableScroll()
    })


    const handleRadioChange = async (e) => {
        const target = e.target.value
        const found = currency_options.find(el => el.value === target)
        
        handleChangeCurency(found)
        setRadioValue(target)  
    }

    const customStyles = {
        menu: (provided, state) => ({
            ...provided,
            width: state.selectProps.width,
            color: '#F6F4EA',
            padding: '0 0 0 0',
            left: '-8px',
            top: '20px',
            boxShadow: 'none',
            background: '#fff',
            border: '0',
        })
    }

    return (
        <>
            <div className={`overlay ${popup ? "active" : ""}`} >
                {popup === 'logout' &&
                    <div className="problem__popup" ref={outside}>
                        <div className="product__popup-close" onClick={close}>
                            <svg className="icon close-product">
                                <use href={sprite + '#close-product-popup'}></use>
                            </svg>
                        </div>
                        <div className="problem__popup-title">{fetch?.text?.register?.logout?.title}</div>
                        <div className="logout__btns">
                        
                        <div className="logout__btn-cancel" onClick={close}>{fetch?.text?.register?.logout?.['btn-cancel']}</div>
                        <div className="logout__btn-logout" onClick={logOut}>{fetch?.text?.register?.logout?.['btn-logout']}</div>
                        </div>
                    </div>
                }

                {/* Popup menu show on burger btn click*/}
                {/* {popup === 'menu' && */}
                    <div className={`mobile__menu hiddenscroll ${isChildrenShow ? 'lg': ''} ${popup === 'menu' ? 'active' : ''}`} ref={outside}>
                        <div className="container">
                            <div className="mobile__content">
                                <div className="mobile__header modal">

                                    {/* Hide on lg - menu__mobile size header*/}
                                    <div className="mobile__header-content hide">
                                        <div className="mobile__menu-arrow" onClick={close}>
                                            < MobileMenuArrow className="icon mobile-menu arrow"/>
                                        </div>

                                        <span className='menu-span_mobile'>toate categoriile</span>
                                        
                                        <div className="mobile__header-right small">
                                            <div className="header__language-items">
                                                <Select
                                                    defaultValue={selectedOption}
                                                    onChange={setSelectedOption}
                                                    options={options}
                                                    placeholder={selectedOption}
                                                    isSearchable={false}
                                                    styles={customStyles}
                                                    id={0}
                                                    components={{
                                                        IndicatorSeparator:() => null,
                                                        DropdownIndicator:() => null
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    {/* Hide on sm - menu__desktop size header */}
                                    <div className="desktop__header-content">
                                        <span className='menu-span'>toate categoriile</span>
                                        <div className="mobile__menu-close" onClick={close}>
                                            <CloseMenuIcon />
                                        </div>
                                    </div>
                                </div>

                                <HeaderSearch sprite={sprite} setQuery={setQuery} query={query} handleSearch={handleSearch} />
                                
                                {/* NEW / SALES btns */}
                                <div className="mobile__menu-btns">
                                    {/* {fetch?.header?.links?.new?.url &&
                                        <Link className="mobile__menu-new" to={fetch?.header?.links?.new?.url} onClick={close} >{fetch?.header?.links?.new?.title}</Link>
                                    } */}
                                    {fetch?.header?.links?.sales?.url &&
                                        <Link className="mobile__menu-new" to={fetch?.header?.links?.sales?.url} onClick={close}>{fetch?.header?.links?.sales?.title}</Link>
                                    }
                                </div>

                                    <ul className="mobile__menu-items">
                                        {fetch?.categories?.map(item => <HeaderNavigation
                                            item={item}
                                            key={item?.id}
                                            close={close}
                                            selectedCategory={selectedCategory}
                                            setSelectedCategory={setSelectedCategory} 
                                            isChildrenShow={isChildrenShow}
                                            setIsChildrenShow={setIsChildrenShow}
                                        />)}
                                    </ul>
                                
                                  <div className='mobile__menu-footer'>
                                    {/* <div className="mobile__menu-social">
                                        <ul className="mobile__items">
                                            
                                            {fetch?.header?.links?.facebook?.url &&
                                            <li className="mobile__header-item">
                                                <a
                                                    className="mobile__header-link facebook-mobile"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    href={fetch?.header?.links?.facebook?.url}
                                                    onClick={close}
                                                >
                                                    <FbIcon />
                                                </a>
                                            </li>
                                            }
                                            
                                            {fetch?.header?.links?.instagram?.url &&
                                            <li className="mobile__header-item">
                                                <a
                                                    className="mobile__header-link instagram-mobile"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    href={fetch?.header?.links?.instagram?.url}
                                                    onClick={close}
                                                >
                                                    <InstagramIcon />
                                                </a>
                                            </li>
                                            }
                                            {fetch?.header?.links?.telegram?.url &&
                                            <li className="mobile__header-item">
                                                <a 
                                                    className="mobile__header-link telegram-mobile" 
                                                    target="_blank" 
                                                    rel="noreferrer"
                                                    href={fetch?.header?.links?.telegram?.url}
                                                    onClick={close}
                                                >
                                                    <TeleframIcon />

                                                </a>
                                            </li>
                                            }
                                        </ul>
                                    </div> */}
                                    <div className="mobile__menu-currency">
                                        {currency_options && currency_options.map((item) => (
                                            <label key={item.value} className="radio__container">{item.value}
                                                <input
                                                    type="radio"
                                                    
                                                    name="radio"
                                                    value={item.value}
                                                    onChange={(e) => handleRadioChange(e)}
                                                    checked={item.value === radioValue}
                                                />
                                                <span className="checkmark"></span>
                                            </label>
                                        ))

                                        }
                                                                               
                                    </div>
                                    {/* {user ? 
                                        <div className="mobile__auth" onClick={() => setPopup('logout')}>
                                            Log out
                                        </div> 
                                        : <Link className="mobile__auth" onClick={close} to="/registration">
                                            Sign in  
                                        </Link>
                                    } */}
                                </div>

                            </div>
                            
                        </div>
                    </div>
                {/* } */}
            </div>

            {/* Navigation Bar */}
            <div className="mobile__header">
                <div className="container">
                    <div className="mobile__header-content">

                        {/* Header burger btn */}
                        <div className="mobile__header-menu" onClick={() => setPopup('menu')}>
                            <svg className="icon mobile-menu">
                                <use href={sprite + '#mobile-menu-redisigned'}></use>
                            </svg>
                            <span className='menu-title'>Categorii</span>
                        </div>

                        {/* Header Logo */}
                        <Link className="mobile__header-logo" to="/" >
                            <img className="mobile__logo-img" src={fetch?.header?.logo} alt="mobileLogo" />
                        </Link>

                        {/* Header icons */}
                        <div className="mobile__header-right">
                        
                            {/* Header Desktop NavBar Search */}
                            <HeaderSearchDesktop
                                sprite={sprite}
                                search={search}
                                query={query}
                                setQuery={setQuery}
                                placeholder={fetch?.text?.search?.['page-title']}
                                activeSearch={activeSearch}
                                closeSearch={closeSearch}
                            />

                            {/* Favorites */}
                            <Link className="mobile__right-favorie" to="Wish">
                                {counter?.favorite && <div className={`cart__favorite ${counter?.favorite > 99 ? 'full' : ''}`}>{counter?.favorite}</div>}
                                <svg className="icon favorite-mobile">
                                    <use href={sprite + '#favorite'}></use>
                                </svg>
                            </Link>

                            {/* Cart */}
                            <Link className="header__right-card" to="cart">
                                {counter?.cart > 0 && <div className="cart__cantity">{counter.cart}</div>}
                                <svg className="icon bag-mobile">
                                    <use href={sprite + '#bag'}></use>
                                </svg>
                            </Link>

                            {/* User */}
                            <Link
                                className="mobile__right-user"
                                to="profile"
                                onClick={statusProfile}
                            >
                                <svg className="icon user-mobile">
                                    <use href={sprite + '#user'}></use>
                                </svg>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default HeaderMobile