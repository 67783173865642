import React from 'react'
import { useSelector } from 'react-redux';
const BlogTags = ( {tags} ) => {
  const trans     = useSelector(state => state?.fetch?.data?.text);
  return (
    <div className="blog__tags">
    <h3 className="blog__info-title">{trans?.['blog-page']?.title}</h3>
    <div className="blog__items-tags">

            {
                tags && tags.map((item, index) => 
                <div key={index} className="blog__item-tag">
                    <div className="blog__tag-link" >{item}</div>
                </div>
                )
            
            }
        </div>
    </div>
  )
}

export default BlogTags