import React from 'react'
import { useSelector } from 'react-redux'
const SearchBanner = ({ items }) => {
    const trans = useSelector(state => state?.fetch?.data?.text);
    return (
        <div className="baner__container">
            <div className="category-baner">
                <div className="container">
                    <div className="category-baner__title">{trans?.search?.['page-title']}</div>
                    <div className="category-baner__subtitle">
                        {items > 0 ? <span className="items__number">{items} {trans?.search?.items ?? 'items in search'}</span> : null}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SearchBanner