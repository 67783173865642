import axios from 'axios'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import {REACT_APP_SERVER_API} from "../../../constants";

const ColorProduct = ({ color }) => {
    const navigate = useNavigate()
    const aboutClick = async () => {
        const result = await axios.get(`${REACT_APP_SERVER_API}/products/${color?.id}`)
        navigate(`/product/${result?.data?.data?.url_key}`)
    }
    return (
        <li className="category__color-item" key={color?.id}
            style={{ background: color?.color?.swatch_value }} onClick={aboutClick}></li>
    )
}
export default ColorProduct