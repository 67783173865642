import React from 'react'
import sprite from "../../../assets/images/icons/sprite.svg"
import {useSelector} from "react-redux";

const ReportPopup = ({ problem, outside, close, handleSubmit, onSubmit, errors, register }) => {

    const user = useSelector(user => user?.user?.currentUser)
    const trans = useSelector(state => state?.fetch?.data?.text)

    return (
        <div className={`overlay ${problem ? "active" : ""}`}>
            <div className="problem__popup" ref={outside}>

                <div className="product__popup-close" onClick={close}>
                    <svg className="icon close-product">
                        <use href={sprite + '#close-product-popup'}></use>
                    </svg>
                </div>

                <div className="problem__popup-title">{trans?.['my-profile']?.['my-orders']?.['report-problem'].title}</div>

                <div className="problem__popup-text">{trans?.['my-profile']?.['my-orders']?.['report-problem'].subtitle}</div>

                <form className="contact__login" onSubmit={handleSubmit(onSubmit)}>
                    <div className="contact__login-inputs">

                        <div className="contact__login-name">{trans?.['my-profile']?.['my-orders']?.['report-problem']?.['input-name']}</div>

                        <input className="registration__name-input" defaultValue={user?.data?.first_name} {...register('name', {
                            required: { value: true, message: trans?.['my-profile']?.['my-orders']?.['report-problem']?.['input-name-error'] },
                            pattern: { value: /^[A-Za-z," "]+$/i, message: 'Name is wrong.' }
                        })} type="text" placeholder="Name" />

                        {errors.name && <p className='error-mesage'>{errors.name.message}</p>}

                        <div className="contact__login-mail">{trans?.['my-profile']?.['my-orders']?.['report-problem']?.['input-email']}</div>

                        <input className="registration__mailinput" defaultValue={user?.data?.email} {...register('email', {
                            required: { value: true, message: 'Email is required' },
                            pattern: { value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g, message: 'Email is wrong.' }
                        })} type="text" placeholder="Email" />

                        {errors.email && <p className='error-mesage'>{errors.email.message}</p>}

                        <div className="contact__login-message">{trans?.['my-profile']?.['my-orders']?.['report-problem']?.['input-message']}</div>

                        <textarea className="contact__message-input" placeholder="Your message here" {...register("message", { required: true })} />

                        {errors.message && <p className='error-mesage'>{trans?.['my-profile']?.['my-orders']?.['report-problem']?.['input-message-error']}</p>}

                        <button className="contact__send" type="submit">{trans?.['my-profile']?.['my-orders']?.['report-problem']?.['btn-send']}</button>

                    </div>
                </form>

            </div>
        </div>
    )
}

export default ReportPopup