import React, {useState} from 'react';
import styled from './Video.module.css';
import {ReactComponent as IconBtnPlay} from "../../../assets/images/icons/icon-btn-play.svg";
import Modal from "../Modal/Modal";
import ReactPlayer from 'react-player'


const Video = ({title, img, videoUrl}) => {
    const [modalVideo, setModalVideo] = useState(false);
    return (
        <>
            <div className={styled?.video} onClick={() => setModalVideo(true)}>
                <div className={styled?.hr_overflow}></div>
                <img src={img} alt={title}/>
                <div className={styled?.content}>
                    <h6>{title}</h6>
                    <div className={styled?.btn_blur}>
                        <div className={styled?.btn}>
                            <IconBtnPlay />
                        </div>
                    </div>
                </div>
            </div>
            {
                modalVideo ?
                <Modal status={true} btnClose={false} closeModal={setModalVideo}>
                    {title && <div className='react-player-title'>{title}</div>}
                    <ReactPlayer
                        className='react-player fixed-bottom'
                        url= {videoUrl}
                        width='100%'
                        controls={true}
                        playing={true}
                        muted={true}
                        />
                </Modal>
                : <></>
            }
        </>
    );
};

export default Video;