import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {updateCart} from "../../slices/cart";

const CartCounter = ({ product }) => {
    const [counter, setCounter] = useState(null)
    const dispatch = useDispatch()
    useEffect(() => {
        setCounter(Number(product?.quantity))
    }, [])

    const increase = () => {
        setCounter(prev => prev + 1)
    };
    useEffect(() => {
        if (counter) {
            if(Number(product.quantity) !== Number(counter)) {
                const update = {
                    product_id: product?.id,
                    count: counter
                }
                dispatch(updateCart(update))
            }
        }
    }, [counter])
    const decrease = () => {
        setCounter(count => count - 1);
        if (counter < 2) {
            setCounter(counter);
        }
    };

    return (
        <div className="bag-popup__input">
            {counter < 2
                ? <button className="minus-btn dis" type="button" onClick={decrease}>-</button>
                : <button className="minus-btn" type="button" onClick={decrease}>-</button>
            }
            <input className="bag-popup__counter" id="quantity" type="number" readOnly="readOnly" value={counter || ''} min="0" max="9999" />
            <button className="plus-btn" type="button" onClick={increase}>+</button>
        </div>
    )
}

export default CartCounter