import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
    name: 'user',
    initialState: {
        currentUser: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
    },

    reducers: {
        setCurrentUser: (state, action) => {
            state.currentUser = action.payload
            localStorage.setItem('user', JSON.stringify(state.currentUser))
        },
        deleteCurrentUser: (state, action) => {
            state.currentUser = null
            localStorage.setItem('user', null)
        },
    }
})

export const { setCurrentUser,deleteCurrentUser} = userSlice.actions
export default userSlice.reducer