import React from 'react'
import Slider from "react-slick";
import Fancybox from './Fancybox';


const Carousel = ({ images, image = null }) => {
    let settings = {
        dots: false,
        infinite: true,
        speed: 500,
        fade: true,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return (
        <>
            <Fancybox animated={true} autoFocus={true}>
                <Slider {...settings}>
                    {
                    images?.length && images?.map( (image, index) => 
                        <a key={index} className='carouse__item' data-fancybox="gallery" href={image?.product_image_url ?? image?.original_image_url}>
                            <img className='carousel__item-image' src={image?.product_image_url ?? image?.original_image_url} alt=""  />
                        </a>)
                    }

                    {
                        !images?.length && 
                        <a className='carouse__item' data-fancybox="gallery" href={image}>
                            <img className='carousel__item-image' src={image} alt=""  />
                        </a>
                    }

                </Slider>
            </Fancybox>
        </>
    )
}

export default Carousel