import React from 'react'
import {useSelector} from 'react-redux';
const HeaderSearch = ({ sprite, setQuery, query, handleSearch }) => {
    const trans = useSelector(state => state?.fetch?.data?.text)

    return (
        <div className="mobile__menu-search">
            <input className="mobile__search" type="text" placeholder={trans?.search?.['page-title']} value={query} onChange={e => setQuery(e.target.value)} />
            <div className="mobile__search-icon" onClick={handleSearch}>
                <svg className="icon search-mobile">
                    <use href={sprite + '#mobile-search'}></use>
                </svg>
            </div>
        </div>
    )
}

export default HeaderSearch