import React from 'react'
import {useSelector} from 'react-redux'
import Gallery from './Gallery'

const Galleries = ({color = "white"}) => {
    const fetch = useSelector(state => state?.fetch?.data?.header)
    return (
        <>
            {fetch?.sliders?.map((slide) => {

                if (slide?.products?.length === 0 || slide?.products === null) return null

                color = color === "white" ? "grey" : "white"

                return <Gallery
                    title={slide?.title}
                    caption={slide?.caption}
                    products={slide?.products}
                    color={color}
                    link={slide?.link}
                    key={slide?.id}
                />
            })}
        </>
    )
}

export default Galleries