import React, { useState, useEffect } from 'react'
import BreadCrumbs from "../../BreadCrumbs/BreadCrumbs";
import styled from './VideoPage.module.scss';
import Video from "../../UI/Video/Video";
import Pagination from "../../Pagination/Pagination";
import CategoryBanner from "../Category/CategoryBanner";
import { useDispatch, useSelector } from 'react-redux'
import { getVideos } from "../../../slices/videos";
import Loader from "../../Loader/Loader";

const VideoPage = () => {
    const trans = useSelector(state => state?.fetch?.data.text)
    const title = useSelector(state => state?.fetch?.data?.header?.videos?.title);  
    const caption = useSelector(state =>  state?.fetch?.data?.header?.videos?.caption);
    const banners = useSelector(state => state?.fetch?.data?.header?.bannersTop?.[0]?.image);
 
   
    const category = {
        name : title ?? trans?.videos,
        description: '',
        image_url: banners
    }
    const videos    = useSelector(state => state?.videos?.data)
    const loading   = useSelector(state => state?.videos?.loading)
    const locale    = useSelector(state => state?.language?.currentLanguage)
  
    const dispatch  = useDispatch()
    const [currentPage, setCurrentPage] = useState(1)
    const [pagination, setPagination]   = useState(undefined)

    useEffect(() => {
        dispatch(getVideos({ page: currentPage, locale })).unwrap().then(response => {
            setPagination(response?.videos?.meta)
        }).catch(e => {
            setPagination(undefined)
        })
    }, [currentPage, locale])

    const onPageChange = (pageNumber) => {
        setTimeout(() => {
            window.scrollTo({
                top: 200,
                behavior: "smooth",
            });
        }, 200);
        setCurrentPage(pageNumber);
    };

    return (
        <>
            {loading ?
                <div className="loader__container">
                    <Loader/>
                </div>
                : <>
                    <CategoryBanner category={category}/>
                    <BreadCrumbs title={title}/>
                    <div className="container">
                        <div className={styled.videoPage}>

                            {
                                videos?.map((video, index) =>
                                    <div key={index} className={styled.item}>
                                        <Video title={video?.title} img={video?.thumbnail}
                                               videoUrl={video?.path}/>
                                    </div>
                                )
                            }

                            <div className={styled.pagination}>
                                {pagination !== undefined && <Pagination
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    totalCount={pagination?.total}
                                    pageSize={pagination?.per_page}
                                    lastPage={pagination?.last_page}
                                    onPageChange={onPageChange}
                                />}
                            </div>

                        </div>

                    </div>
                </>
            }
        </>

    );
};

export default VideoPage;