import React from 'react'
import { useSelector } from 'react-redux';

const ContactLocation = ({sprite}) => {
    const label = useSelector(state => state?.contacts?.data?.text?.contacts?.['title-location']);
    const value = useSelector(state => state?.contacts?.data?.data?.location);
    
    return (
        <div className="contact__info-location">
            <div className="contact__location-icon"><svg className="icon contact__info">
                <use href={sprite + '#contact-location'}></use>
            </svg></div>
            <h3 className="contact__location-title">{label}</h3>
            <div className="contact__location-info">
                {value}
            </div>
        </div>
    )
}

export default ContactLocation