import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'


const Counter = ({ setSavedOption, savedOption, counter, setCounter }) => {

    useEffect(() => {
        setSavedOption({ ...savedOption, savedQuantity: counter })
    }, [counter])
    const increase = () => {
        setCounter(count => +count + 1);
    };
    const decrease = () => {
        setCounter(count => count - 1);
        if (counter < 2) {
            setCounter(counter);
        }
    };

    return (
        <div className="bag-popup__input">
            {counter < 2
                ? <button className="minus-btn dis" type="button" onClick={decrease}>-</button>
                : <button className="minus-btn" type="button" onClick={decrease}>-</button>
            }
            <input className="bag-popup__counter" id="quantity" type="number" readOnly="readOnly" value={counter} min="0" max="9999" />
            <button className="plus-btn" type="button" onClick={increase}>+</button>
        </div>
    )
}

export default Counter