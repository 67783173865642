import React, {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form';
import {useClickOutside} from '../../../../hooks/useClickOutside';
import sprite from "../../../../assets/images/icons/sprite.svg"
import {REACT_APP_SERVER_API} from "../../../../constants";
import Modal from "../../../UI/Modal/Modal";
import {enableScroll} from "../../../../helpers/documentElement";

const Reset = ({ forgot, setForgot, trans }) => {
    const [forgoutMessage, setForgoutMessage] = useState('');
    const [statusModalSuccess, setStatusModalSuccess] = useState({
        email: '',
        status: false
    });
    const {
        register,
        handleSubmit, reset,
        formState: { errors },
    } = useForm({
        mode: 'onChange'
    });
    document.onkeyup = function (evt) {
        evt = evt || window.event;
        if (evt.keyCode === 27 && forgot.status) {
            setForgot({
                ...forgot,
                status: false
            })
            enableScroll()
        }
    };
    const onSubmit = async (data) => {

        const result = await fetch(`${REACT_APP_SERVER_API}/customer/forgot-password?email=${data.email}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
        })
        const resultInJson = await result.json()

        if (resultInJson.error !== undefined) {
            setForgoutMessage(resultInJson.error)
        }
        if (resultInJson.status === 200) {
            setStatusModalSuccess({
                ...forgoutMessage,
                email: data.email,
                status: true
            });
            setForgot({
                ...forgot,
                status: !forgot.status,
                email: data.email
            })
        } else {
            setForgot({
                ...forgot,
                status: !forgot.status,
                email: data.email
            })
        }
        enableScroll()
        reset()
    }
    const close = () => {
        setForgot({
            ...forgot,
            status: false
        })
        enableScroll()
    }
    const outside = useClickOutside(() => {
        setForgot({
            ...forgot,
            status: false
        })
        enableScroll()
    })
    useEffect(() => {
        setForgot({
            ...forgot,
            status: false
        })
    }, [statusModalSuccess, setStatusModalSuccess])

    const closeModal = () => {
        setStatusModalSuccess({
            ...statusModalSuccess,
            status: false
        })
    }

    return (
        <>
            {
                statusModalSuccess.status
                    ? <Modal status={true} closeModal={closeModal} btnClose={true}>
                        <h4>{trans?.register?.['reset-password']?.['reset-title']}</h4>
                        <p style={{fontSize: '17px', fontWeight: 400, textTransform: 'none', marginTop: '20px'}}>
                            {`Pe email-ul ${statusModalSuccess.email} a fost trimis mesaj cu resetarea parolei`}
                        </p>
                    </Modal>
                    : <div className={`overlay ${forgot.status ? "active" : ""}`}>
                        <div className="reset" ref={outside}>
                            <div className="product__popup-close" onClick={close}>
                                <svg className="icon close-product">
                                    <use href={sprite + '#close-product-popup'}></use>
                                </svg>
                            </div>
                            <div className="reset__title">
                                {trans?.register?.['reset-password']?.['reset-title']}
                            </div>
                            <form className='reset__form' onSubmit={handleSubmit(onSubmit)}>
                                <div className="registration__sign-mail input-title">
                                    {
                                        forgoutMessage !== ''
                                            ? <div style={{color: 'var(--red)'}}>{forgoutMessage}</div>
                                            : trans?.register?.['reset-password']?.['input-email']
                                    }
                                </div>
                                <input className="registration__mailinput"  {...register('email', {
                                    required: {
                                        value: true,
                                        message: trans?.register?.['reset-password']?.['input-email-required'] ?? 'Email is required'
                                    },
                                    pattern: {
                                        value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                                        message: trans?.register?.['reset-password']?.['input-email-error'] ?? 'Enter a valid email'
                                    }
                                })} type="text" placeholder={trans?.register?.['reset-password']?.['input-email']}/>
                                {errors.email && <p className='error-mesage'>{errors.email.message}</p>}
                                <button className="contact__send"
                                        type="submit">{trans?.register?.['reset-password']?.['button-submit']}</button>
                            </form>
                        </div>
                    </div>
            }
        </>
    )
}

export default Reset