import React from 'react'
import sprite from "../../assets/images/icons/sprite.svg"
import { useSelector } from 'react-redux'

const SocialShare = () => {
  const trans = useSelector(state =>state?.fetch?.data?.text)
  const fetch = useSelector(state =>state?.fetch?.data)
  return (
    <div className="product__social">
      <div className="product__social-content">
        <div className="product__social-title">{trans?.socialshare} </div>
        
        {fetch?.header?.links?.instagram?.url &&
          <a className="product__social-link"  rel="noreferrer"  target="_blank" href={fetch?.header?.links?.facebook?.url}>
            <svg className="icon facebook-social">
              <use href={sprite + '#facebook-bl'}></use>
            </svg>
          </a>
        }
        {fetch?.header?.links?.instagram?.url &&
          <a className="product__social-link"  rel="noreferrer"  target="_blank" href={fetch?.header?.links?.instagram?.url}>
            <svg className="icon instagram-social">
              <use href={sprite + '#instagram-bl'}></use>
            </svg>
          </a>
        }
        {fetch?.header?.links?.telegram?.url &&
          <a className="product__social-link" rel="noreferrer" target="_blank" href={fetch?.header?.links?.telegram?.url}>
            <svg className="icon instagram-social">
              <use href={sprite + '#telegram'}></use>
            </svg>
          </a>
        }
      </div>
    </div>
  )
}

export default SocialShare