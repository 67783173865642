import { createSlice } from "@reduxjs/toolkit";

const languageSlice = createSlice({
    name: 'language',
    initialState: {
        currentLanguage: localStorage.getItem('language') ? JSON.parse(localStorage.getItem('language')) : 'ro'
    },

    reducers: {
        setCurrentLanguage: (state, action) => {
            state.currentLanguage = action.payload
            localStorage.setItem('language', JSON.stringify(state.currentLanguage))
        },
    }
})

export const { setCurrentLanguage } = languageSlice.actions
export default languageSlice.reducer