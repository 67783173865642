import request from "./utils/request";

const get = (address_id = null) => {
    return request().get(`/addresses${address_id ? `/${address_id}` : ''}`).then(response => response.data);
};

const add = (address1, city, country, country_name, phone, postcode, state, company_name, first_name, last_name, is_default) => {
    return request().post(`/addresses/create`, { address1, city, country, country_name, phone, postcode, state, company_name, first_name, last_name, is_default }).then(response => response.data);
};

const update = (address_id, address1, city, country, country_name, phone, postcode, state, company_name, first_name, last_name, is_default) => {
    return request().put(`/addresses/${address_id}`, { address1, city, country, country_name, phone, postcode, state, company_name, first_name, last_name, is_default, default_address: is_default ? true : undefined }).then(response => response.data);
};

const remove = (address_id) => {
    return request().delete(`/addresses/${address_id}`).then(response => response.data);
};

const regions = (code) => {
    return request().get(`/regions/${code}`).then(response => response.data);
};

const addressesService = { get, add, update, remove, regions };

export default addressesService;