import {createAsyncThunk} from "@reduxjs/toolkit";
import getErrorMessage from "../services/utils/error-message";
import FetchService from "../services/fetch.service";
import {setFetch} from "../components/Redux/FetchReducer";

export const getFetch = createAsyncThunk(
    "fetch/get",
    async ({ locale }, thunk) => {
        try {
            const response = await FetchService.get(locale);
            thunk.dispatch(setFetch(response));
            return response;
        } catch (error) {
            setTimeout(() => {
                thunk.dispatch(getFetch({locale}))
            }, 5000)
            return thunk.rejectWithValue(getErrorMessage(error));
        }
    }
);
