import React, {useEffect, useState} from 'react'
import WishEmpty from './WishEmpty';
import WishBanner from './WishBanner';
import Meta from '../../../meta/Meta';
import Goods from "../../Goods/Goods";
import Loader from '../../Loader/Loader';
import { useDispatch, useSelector} from 'react-redux';
import BreadCrumbs from '../../BreadCrumbs/BreadCrumbs';
import Pagination from "../../Pagination/Pagination";
import { getWishlist } from "../../../slices/wishlist";
import {disableScroll, enableScroll} from "../../../helpers/documentElement";

const Wish = () => {

    const trans = useSelector(state => state?.fetch?.data?.text)
    const fetch = useSelector(state => state?.fetch?.data)
    const favorites = useSelector(state => state?.favorite?.favoriteInCart)
    const loading   = useSelector(state => state?.favorite?.loading)
    const language  = useSelector(language => language?.language?.currentLanguage)
    const dispatch  = useDispatch()
    const [currentPage, setCurrentPage] = useState(favorites?.meta?.current_page ?? 1);

    useEffect(() => {
        if(loading) {
            disableScroll()
        } else {
            enableScroll()
        }
    }, [loading])

    useEffect(() => {
        dispatch(getWishlist(
            {
                locale: language,
                page: currentPage
            })
        );
    }, [language, currentPage])

    const paginate = (pageNumber) => {
        setTimeout(() => {
            window.scrollTo({
                top: 200,
                behavior: "smooth",
            });
        }, 10);
        setCurrentPage(pageNumber);
    };

    return (
        <>
            <Meta 
                title={trans?.wishlist?.title} 
                description={fetch?.header?.seo?.['meta_description']} 
                keywords={fetch?.header?.seo?.['meta_keywords']}
            />
            {loading &&
                <div className="loader__container">
                    <Loader/>
                </div>
            }
            <WishBanner favorites={favorites} trans={trans}/>
            <section className="category wish">
                <BreadCrumbs title={trans?.wishlist?.title}/>
                <div className="container">
                    {favorites?.data?.length ?
                        <div className="category__content wish-content">
                            <div className="goods">
                                <div className="gallery__slider">
                                    {
                                        favorites?.data?.length && favorites?.data?.map((product, index) => {

                                            return (
                                                <Goods
                                                    product={product?.product}
                                                    key={product?.product?.id}
                                                />
                                            )
                                        })
                                    }
                                </div>
                                {
                                    <Pagination
                                        totalCount={favorites?.meta?.total}
                                        onPageChange={paginate}
                                        currentPage={currentPage}
                                        pageSize={favorites?.meta?.per_page}
                                    />
                                }
                            </div>

                        </div>
                        :
                        <WishEmpty/>
                    }
                </div>
            </section>
        </>

    )
}

export default Wish;