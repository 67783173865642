import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AddToWish from './AddToWish'
import parse from 'html-react-parser'
import Meta from '../../../meta/Meta'
import { toast } from 'react-toastify'
import AddToCart from './AddToCart.jsx'
import SizeProduct from './SizeProduct'
import ProductInfo from './ProductInfo'
import { useForm } from 'react-hook-form'
import ColorProduct from './ColorProduct'
import Counter from '../../Counter/Counter'
import Gallery from '../../Gallery/Gallery'
import Galleries from '../../Gallery/Galleries'
import ProductOverlay from './ProductOverlay'
import Carousel from '../../Carousel/Carousel'
import SocialShare from '../../SocialShare/SocialShare'
import BreadCrumbs from '../../BreadCrumbs/BreadCrumbs'
import { useClickOutside } from '../../../hooks/useClickOutside'
import 'react-toastify/dist/ReactToastify.css'
import {disableScroll, enableScroll} from "../../../helpers/documentElement";
import {useParams} from "react-router-dom";
import {getProductBySlug} from "../../../slices/products";
import Error from "../404/404";
import Loader from "../../Loader/Loader";
import {buyNowOrder} from "../../../slices/orders";

const Product = () => {
  const trans     = useSelector(state => state?.fetch?.data?.text)
  const orders    = useSelector(state => state?.orders?.data)
  const language  = useSelector(state => state?.language?.currentLanguage)
  const product   = useSelector(state => state?.product?.currentProduct)
  const loading   = useSelector(state => state?.product?.loading)
  const params    = useParams();
  const [delivery, setDelivery] = useState("delivery")
  const [overlay, setOverlay]   = useState(false)
  const [sizeActive, setSizeActive] = useState(false)
  const [popup, setPopup]       = useState("")
  const [counter, setCounter]   = useState(1);
  const [selectedProductSize, setSelectedProductSize] = useState(false)
  const [sizeError, setSizeError] = useState(false)
  const dispatch = useDispatch()
  const [submitOption, setSubmitOption] = useState({ option: '' })
  const [notFound, setNotFound]   = useState(false)
  const [buyNowMessage, setBuyNowMessage] = useState("")
  const currency = useSelector(state => state?.currency?.currentValueCurrency)


  useEffect(() => {
    const request = {
      slug: params.slug,
      locale: language,
      currency: currency
    }
    dispatch(getProductBySlug(request)).unwrap()
        .then(response => {
          if(response === undefined) setNotFound(true)
        })
        .catch(e => {
          setNotFound(true)
        })
  }, [language, params.slug, currency])


  const [savedOption, setSavedOption] = useState(
      {
        savedSize: product?.attributes?.find(attribute => attribute?.code === 'marimea')?.value?.[0], 
        savedColor: '',
        savedQuantity: 1, 
        buyNow: submitOption, 
        savedTime: '', 
        savedId: ''
      }
  )

  useEffect(() => {
     //Set default option for size
    if (product) {
      setSizeActive(0)
      setSelectedProductSize(true)
      setSavedOption({ ...savedOption, savedSize: product?.attributes?.find(attribute => attribute?.code === 'marimea')?.value?.[0] })
    }
  }, [product])


  const { register, handleSubmit, reset, formState: { errors } } = useForm({ mode: 'onChange' });

  const outside = useClickOutside(() => {
    setOverlay(false)
    setPopup(false)
    enableScroll()
    reset()
  })

  const onSubmit = (data) => {
    setBuyNowMessage("")
    const body = {
      ...data,
      locale: language,
      product_id: product?.id,
      size: savedOption?.savedSize,
      quantity: savedOption?.savedQuantity ?? 1,
      savedOption
    }
    dispatch(buyNowOrder(body)).unwrap().then(response => {
      setPopup(false)
      setOverlay(!overlay)
      enableScroll()
      reset()
      if(response?.message) {
        toast(
            <div className="succes__popup">
              <div className="succes__popup-title">{response?.message}</div>
            </div>
        )
      }
    }).catch(message => {
      setBuyNowMessage(message)
    })

  }

  const showError = () => { setSizeError(true) }

  document.onkeydown = function (evt) {
    evt = evt || window.event;
    if (evt.keyCode === 27 && popup) {
      setPopup(false)
      setOverlay(!overlay)
      enableScroll()
    }
  };
  const activePopup = (popup) => {
    setPopup(popup)
    setOverlay(!overlay)
    disableScroll()
  }

  const close = () => {
    setPopup(false)
    setOverlay(!overlay)
    enableScroll()
    reset()
  }

  const setData = () => {
    // setSubmitOption({ ...submitOption }, submitOption.option = data)
  }

  return (
      <>
        {notFound === true ?
            <Error/>
            :
            <>
              {loading || product === undefined ?
                  <div className="loader__container">
                    <Loader />
                  </div>
                  :
                  <>
                    <Meta 
                      title={product?.name} 
                      description={product?.short_description} 
                      keywords={''}
                      image={product?.base_image?.original_image_url}
                    />
                    <ProductOverlay overlay={overlay} popup={popup} product={product}
                                    outside={outside} close={close}
                                    counter={counter} savedOption={savedOption}
                                    handleSubmit={handleSubmit} onSubmit={onSubmit}
                                    errors={errors} register={register} setData={setData}
                                    buyNowMessage={buyNowMessage}
                    />
                    <section className="product">
                      <BreadCrumbs title={product?.name ?? product?.id}/>
                      <div className="container">
                        <div className="product__content">
                          <div className="product__slider">

                            {/* Discout badge */}
                            {/* <div className="gallery__images-top">
                              {(product?.price) > (product?.special_price) 
                                ? <div className="gallery__discount">-{Math.round(100 - product?.special_price / (product?.price / 100))}%</div> 
                                : ''
                              }
                              {product?.new ? <div className="gallery__discount">{trans?.category?.new}</div> : ""}
                            </div> */}
                            {/* Discout badge  END */}

                            <Carousel images={product?.images} image={product?.base_image?.product_image_url}/>
                          </div>
                          <div className="product__info">
                            <div className="product__article">{trans?.products?.['product-code']}<span
                                className="product__number">{product?.id}</span></div>
                            <h2 className="product__title">{product?.name}</h2>
                            {product?.description  && <div className="product__description">{parse(String(product?.description ?? ''))}</div>}
                            
                            {product?.attributes && product?.attributes.map( (attribute, attr_index) => 
                              <div key={attr_index}>
                                {typeof(attribute?.value) == 'object' && attribute?.value && 
                                <div key={attr_index} className="product__size category__size">
                                  <div className="product__container" >
                                    <div className="product__size-title category__size-title">{attribute?.label}</div>
                                    <div className="category__size-boxes">
                                      <ul className="category__size-items">
                                        {
                                          attribute?.value && attribute?.value?.map((item, index) => 
                                            <SizeProduct 
                                              setSavedOption={setSavedOption}
                                              savedOption={savedOption} 
                                              key={index}
                                              setSelectedProductSize={setSelectedProductSize}
                                              sizeActive={sizeActive}
                                              setSizeActive={setSizeActive} index={index}
                                              item={item} selected={item?.selected}
                                              sizeItem={item?.item}
                                              active={item?.active}
                                          
                                          />)
                                        }
                                      </ul>
                                      {sizeError ? <>{selectedProductSize && sizeError ? null :
                                          <p className='product__error'>{trans?.products?.['size-error']}</p>} </> : null}
                                    </div>
                                  </div>
                                </div>}
                              </div>
                            )}

                            <div className="gallery__size-chart" onClick={() => activePopup("size")}>
                              {trans?.products?.['size-chart']}
                            </div>
                            {product?.siblings?.length > 0 && <div className="product__color category__color">
                              <div className="product__container">
                                <div
                                    className="product__color-title category__color-title">{trans?.products?.colors}</div>
                                <ul className="product__color-items category__color-items">
                                  {product?.siblings?.map((color) => <ColorProduct key={color?.id} color={color}/>)}
                                </ul>
                              </div>
                            </div>}

                            {
                              product?.is_sales 
                              ?
                              <div className={`product__price sale`} >
                                {product?.formated_special} 
                                <div className={`gallery__image-price sale`}>{product?.formated_price}</div>
                                <span className="product__stock">{trans?.products?.['in-stock']}</span>
                              </div>
                            : 
                            <div className={`product__price`} >
                              {product?.formated_price}
                              <span className="product__stock">{trans?.products?.['in-stock']}</span>
                            </div>

                            }

                           
                            <div className="product__buy">
                              <div className="product__buy-content">
                                <Counter setSavedOption={setSavedOption} savedOption={savedOption} counter={counter} setCounter={setCounter}/>
                                {/* {selectedProductSize ?
                                  <div className="product__price-buy" onClick={() => activePopup("buy")}>
                                    <div
                                        className="product__buy-text"> {trans?.products?.['popup-buy-now']?.title}</div>
                                  </div>
                                  : <div className="product__price-buy" onClick={showError}>
                                    <div className="product__buy-text">{trans?.products?.['popup-buy-now']?.title}</div>
                                  </div>
                              } */}
                                {selectedProductSize ?
                                  <AddToCart savedOption={savedOption} product={product}/> :
                                  <div 
                                      className="product__price-add"
                                      onClick={showError}>
                                    <div className="product__add-text">{trans?.products?.['add-to-cart'] ?? 'Add to cart'} </div>
                                  </div>
                                }
                                <AddToWish product={product}/>
                              </div>
                            </div>
                            
                            <ProductInfo 
                              trans={trans} 
                              delivery={delivery} 
                              setDelivery={setDelivery}
                              activePopup={activePopup} 
                              product={product}
                            />
                            <SocialShare trans={trans}/>
                          </div>
                        </div>
                      </div>

                      <Gallery 
                        title={trans?.sliders?.related?.title ?? 'Related products'} 
                        caption={trans?.sliders?.related?.caption ?? 'See related products'} 
                        products={product?.related_products}
                        color='grey'
                      />

                      {!product?.related_products && <Galleries/>}

                    </section>
                  </>
              }
            </>
        }
      </>
  )
}

export default Product