

const Overlay = (props, status) => {


    if (status) {
        document.body.style.overflow = "hidden"
    }

    return (
        <div className={`${status ? 'active' : ''} overlay`}>
            <div className="overlay__popup">
                {props?.children}
            </div>
        </div>
    )
}

export default Overlay