import React from 'react'
import {useSelector} from 'react-redux';

const ContactForm = ({handleSubmit, errors, onSubmit, register}) => {

    const trans = useSelector(state => state?.contacts?.data?.text);
 

    return (
        <form className="contact__login" onSubmit={handleSubmit(onSubmit)}>
            {/* <h2 className="contact__login-title">{trans?.contacts?.['title-contact-form']}</h2>
            <div className="contact__login-text">{trans?.contacts?.['subtitle-get-in-touch-form']}</div> */}
            <div className="contact__login-inputs">
                <div className='form-group'>
                    <div className="contact__login-name">{trans?.contacts?.['input-name']}</div>
                    <input className="registration__name-input" {...register('name', { required: true, pattern: /^[A-Za-z," "]+$/i })} type="text" placeholder={trans?.contacts?.['input-name']} />
                    {errors.name && <p className='error-mesage'>{trans?.contacts?.['input-name-error']}</p>}
                </div>
                <div className='form-group'>
                    <div className="contact__login-mail">{trans?.contacts?.['input-email']}</div>
                    <input className="registration__mailinput" {...register('email', { required: true, pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g })} type="text" placeholder={trans?.contacts?.['input-email']} />
                    {errors.email && <p className='error-mesage'>{trans?.contacts?.['input-email-error']}</p>}
                </div>
                <div className='form-group'>
                    <div className="contact__login-message">{trans?.contacts?.['input-message']}</div>
                    <textarea className="contact__message-input" type="text" placeholder={trans?.contacts?.['input-message']} {...register("message", { required: true })} />
                    {errors.message && <p className='error-mesage'>{trans?.contacts?.['input-message-error']}</p>}
                </div>
                <button className="contact__send" type="submit">{trans?.contacts?.['btn-send']}</button>
            </div>
        </form>
    )

}

export default ContactForm