import React from 'react'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import sprite from '../../assets/images/icons/sprite.svg'
import FooterNavigation from './FooterNavigation'
import FooterPay from './FooterPay'
import FooterSocial from './FooterSocial'
import Phones from './Phones'

const Footer = ({ fetch }) => {
    const user = useSelector(user => user.user.currentUser)
    const trans = useSelector(state =>state?.fetch?.data?.text)
    const navigate = useNavigate()

    return (
        <footer className="footer">
            <div className="container">
                <div className="footer__content">
                    <div className="footer__info">
                        <Link className="footer__logo-link" to="/">
                            <img className="footer__logo-img" src={fetch?.footer?.logo}
                            alt="" />
                        </Link>
                        <p className="footer__text">{fetch?.footer?.description}</p>
                        <FooterPay sprite={sprite} />
                    </div>
                    <div className="footer__category">
                        <div className="footer__category-title">{trans?.categories}</div>
                        <div className="footer__category-content">
                            <ul className="footer__items-left" >
                                {fetch?.categories && fetch?.categories?.map((item, index) => <FooterNavigation key={item.id} navigate={navigate} index={index} item={item} />)}
                            </ul>
                        </div>
                    </div>
                    <div className="footer__about">
                        {fetch?.footer?.columns &&
                            fetch?.footer?.columns?.map( (column,index) => <div key={index} className="footer__services">
                                <div className="footer__services-title">{column?.header}</div>
                                <ul className="footer__services-items">
                                    {column?.pages?.map(item => <li key={item?.id} className="footer__services-item">
                                        <Link to={`/page/${item?.link}`} className="footer__services-link">{item?.name}</Link></li>)}
                                </ul>
                        </div>)}
                        <Phones/>
                    </div>
                    <div className="footer__mobile_links">
                        {fetch?.footer?.columns &&
                           fetch?.footer?.columns?.map( (column,index) => { return column?.pages?.map(item =>
                           {return item?.name &&
                           <li key={item?.id} className="footer__mobile_item">
                                <Link to={`/page/${item?.link}`} className="footer__mobile_link">{item?.name}</Link>
                           </li>
                           }
                        )
                        }
                        )}
                    </div>
                    <div className="footer__phones_mobile">
                        <Phones/>
                    </div>
                    <FooterSocial sprite={sprite} data={fetch?.footer} />
                </div>
            </div>
        </footer>

    )
}

export default Footer