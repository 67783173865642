import {createAsyncThunk} from "@reduxjs/toolkit";
import getErrorMessage from "../services/utils/error-message";
import ProductsService from "../services/products.service";
import {setCurrentProduct, setProductLoading} from "../components/Redux/ProductReducer";
import {setCategoryLoading, setCurrentCategory} from "../components/Redux/CategoryReducer";
import {setCurrentSearch} from "../components/Redux/SearchReducer";

export const getProductsByCategory = createAsyncThunk(
    "products/get_by_category",
    async ({ slug, params }, thunk) => {
        try {
            thunk.dispatch(setCategoryLoading(true))
            const response = await ProductsService.getByCategory(slug, params);
            const category = response?.data
            thunk.dispatch(setCurrentCategory(category))
            thunk.dispatch(setCategoryLoading(false))
            return category
        } catch (error) {
            thunk.dispatch(setCurrentCategory(undefined))
            thunk.dispatch(setCategoryLoading(false))
            return thunk.rejectWithValue(getErrorMessage(error));
        }
    }
);

export const getProductBySlug = createAsyncThunk(
    "products/get_by_slug",
    async ({ slug, locale, currency }, thunk) => {
        try {
            thunk.dispatch(setProductLoading(true))
            const response = await ProductsService.getProduct(slug, locale, currency);
            const product = response?.data
            thunk.dispatch(setCurrentProduct(product))
            thunk.dispatch(setProductLoading(false))
            return product
        } catch (error) {
            thunk.dispatch(setCurrentProduct(undefined))
            thunk.dispatch(setProductLoading(false))
            return thunk.rejectWithValue(getErrorMessage(error));
        }
    }
);

export const searchProducts = createAsyncThunk(
    "products/search",
    async ({ query, locale, page }, thunk) => {
        try {
            const response = await ProductsService.searchProducts(query, locale, page);
            thunk.dispatch(setCurrentSearch(response?.data))
            return response
        } catch (error) {
            thunk.dispatch(setCurrentSearch(undefined))
            return thunk.rejectWithValue(getErrorMessage(error));
        }
    }
);

export const buyNow = createAsyncThunk(
    "products/buyNow",
    async ({ locale, product_id, quantity, size, name, email, phone }, thunk) => {
        try {
            const response = await ProductsService.buyNow(locale, product_id, quantity, size, name, email, phone );
            return response
        } catch (error) {
            return thunk.rejectWithValue(getErrorMessage(error));
        }
    }
);