import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

const PlacingEmpty = () => {
  const trans = useSelector(state => state?.fetch?.data?.text);
  
  return (
    <div className='empty__cart'>
    <div className="empty__cart-title">{trans?.checkout?.invoice?.no_invoice ?? 'Invoice not found'}</div>
    <div className="empty__cart-text">{trans?.checkout?.invoice?.no_invoice_description ?? 'No invoice found. Check the link or contact us if you think there is a mistake'}</div>
    <Link className="empty__cart-btn" to='/'>
        <div className="category__clear-text" >{trans?.checkout?.['complete-order']?.['button-go-to-home-page']} </div>
    </Link>
</div>
  )
}

export default PlacingEmpty