export const disableScroll = () => {
    if (document.documentElement.scrollHeight > window.innerHeight) {
        let scrollTop = document.documentElement.scrollTop ? document.documentElement.scrollTop : document.body.scrollTop;
        document.documentElement.classList.add('no-document-scroll')
        document.documentElement.style.top = `${-scrollTop}px`
    }
}

export const enableScroll = () => {
    document.body.style.overflow = "auto"
    document.documentElement.classList.remove('no-document-scroll')
    const documentTop = document.documentElement.style.top
    if(documentTop.length > 0) {
        document.documentElement.style.top = null;
        document.documentElement.scrollTop = -parseInt(documentTop)
    }
}