import React, {useEffect} from 'react'
import { Link, Navigate } from 'react-router-dom'
import sprite from "../../../assets/images/icons/sprite.svg"
import { useSelector, useDispatch } from 'react-redux';
import { success } from "../../../slices/cart";
import {useParams} from "react-router-dom";
import {COURIER, TAKE} from "./Cart/Cart";

const CartSuccess = ({outside, close, delivery}) => {
    const trans         = useSelector(state => state?.fetch?.data?.text)
    const order         = useSelector(state => state?.cart?.success_order?.order);
    // const status        = useSelector(state => state?.cart?.success_order?.success);
    const params        = useParams();
    const dispatch      = useDispatch();



    useEffect(() => {
        dispatch(success(params?.id))
    }, [params?.id])


    return (
        order ? 
        <div className={`overlay ${order ? "active" : ""}`} >
            <div className="order" ref={outside}>
                <div className="product__popup-close" onClick={close}>
                    <svg className="icon close-product">
                        <use href={sprite + '#close-product-popup'}></use>
                    </svg>
                </div>
                <div className="order__title">{trans?.checkout?.['complete-order']?.title}</div>
                <div className="order__id">{trans?.checkout?.['complete-order']?.subtitle} #{order?.id}</div>
                <div className="order__info">
                    <div className="order__date">{order?.orderDate}</div>
                    <div className="order__payment">{trans?.checkout?.['complete-order']?.['payment-type']}: {order?.payment_title}</div>
                    {order?.shipping_method === COURIER ? <div className="order__delivery">{trans?.checkout?.['complete-order']?.['delivery-address']}: {order?.shipping_address?.country}, {order?.shipping_address?.city}, {order?.shipping_address?.address1}, {order?.shipping_address?.postcode}</div>
                        : order?.shipping_method === TAKE ? <div className="order__delivery">{trans?.checkout?.['shipping-form']?.['take-off']} : {order?.shipping_title}</div>
                            : ''}

                    <div className="order__items">
                        <span>{order?.total_item_count} </span>{trans?.checkout?.['complete-order']?.['number-items-in-order']}
                    </div>
                    { order?.discount_percent > 0 && 
                    
                    <div className="order__discount">{trans?.checkout?.['complete-order']?.['discount-card']}: -{order?.discount_percent}%</div>}

                    <div className="cart__total">
                        <ul className="cart__total-list">
                            <li className="cart__total-info">{trans?.checkout?.['complete-order']?.['subtotal']}</li>
                            <li className="cart__total-price">{parseFloat(order?.sub_total).toFixed(2) } {trans?.products?.currency}</li>
                        </ul>
                        {order?.discount_invoiced > 0 && <ul className="cart__total-list">
                            <li className="cart__total-info">{trans?.checkout?.['complete-order']?.['discount']}</li>
                            <li className="cart__total-price">{parseFloat(order?.discount_invoiced).toFixed(2) } {trans?.products?.currency}</li>
                        </ul>}
                        {order?.shipping_amount ? <ul className="cart__total-list">
                            <li className="cart__total-info">{trans?.checkout?.delivery}:</li>
                            <li className="cart__total-price">{parseFloat(order?.shipping_amount).toFixed(2)} {trans?.products?.currency}</li>
                        </ul> : null}
                        <ul className="cart__total-list all-price">
                            <li className="cart__total-info">{trans?.checkout?.['complete-order']?.['total']}</li>
                            <li className="cart__total-price">{parseFloat(order?.grand_total).toFixed(2)} {trans?.products?.currency}</li>
                        </ul>
                    </div>
                </div>
                <Link className="order__home" to="/" onClick={close}>{trans?.checkout?.['complete-order']?.['button-go-to-home-page']}</Link>
            </div>
        </div>
        :
        ''
    )
}

export default CartSuccess