import { createSlice } from "@reduxjs/toolkit";

const profileSlice = createSlice({
    name: 'profile',
    initialState: {
        section: localStorage.getItem('profile') ? localStorage.getItem('profile') : "data"
    },

    reducers: {
        setProfileSection: (state, action) => {
            state.section = action.payload
            localStorage.setItem('profile', state.section)
        }
    }
})

export const { setProfileSection } = profileSlice.actions
export default profileSlice.reducer