import React, {useState, useCallback} from 'react'
import { Link, useNavigate } from 'react-router-dom'
// Icons
import { ReactComponent as MobileMenuArrow } from '../../assets/images/icons/mobile-menu-arrow.svg';
import { ReactComponent as Arrow } from '../../assets/images/icons/menu-arrow.svg'

const HeaderNavigation = ({ item, close, selectedCategory, setSelectedCategory, isChildrenShow, setIsChildrenShow }) => {
    const navigate = useNavigate()
    const handleClick = () => {
        navigate(`/category/${item?.slug}`)
        close()
    }
    const [show, setShow] = useState(false);

    const toggleChildren = () => {
        if(item?.name === selectedCategory && show ) {
            setShow(false)
            setIsChildrenShow(false)
            setSelectedCategory('')
        } else {
            setShow(true)
            setIsChildrenShow(true)
            setSelectedCategory(item?.name)
        }
    }
    
    const toggleChildrenMouseOver = () => {
        setShow(true)
        setSelectedCategory(item?.name)

        if(item.categories.length > 0 ) {
            setIsChildrenShow(true)
        } else {
            setIsChildrenShow(false)
        }
    }
    const toggleChildrenMouseLeave = () => {
        if( item?.name !== selectedCategory) {
            setShow(false)
            setSelectedCategory('')
        }
       
    }

    return (
        <>
            <li className="mobile__menu-item " >
                <div
                    className={`menu__item-link ${(selectedCategory === item?.name && show) ? "active" : ""}`}
                    onMouseOver={toggleChildrenMouseOver}
                    onMouseOut={toggleChildrenMouseLeave}
                >
                    <span onClick={handleClick}>{item?.name}</span>
                    {/* Arrow display check */}
                    { item?.categories?.length > 0 && <div className="icon-container" onClick={toggleChildren}><Arrow /></div> }
                </div>
            
                {/* Display children categoris */}
                {item?.categories?.length > 0 && 
                <div className={`menu__item-children-wrapper ${(selectedCategory === item?.name && show) ? "active" : ""}`}>
                    <div className='menu__item-children-header'>
                        <div className="mobile__menu-arrow" onClick={toggleChildren}>
                            <MobileMenuArrow className="icon mobile-menu arrow"/>
                        </div>
                        <span className='mobile__header-title'>{selectedCategory}</span>
                    </div>
                    <ul className={ `menu__item-children ${(selectedCategory === item?.name && show) ? "active" : ""}`}>
                        {item?.categories.map( (child, child_key) =>
                            child?.slug && 
                            <li className="menu__item-child" key={child_key}>
                                <Link
                                    onClick={close}
                                    className="menu__item-childlink"
                                    to={`/category/${child?.slug}`}
                                >
                                    {child?.name}
                                </Link>
                            </li>
                        )}
                        </ul>
                </div>
                    
                }
            </li>
        </>
    )
}

export default HeaderNavigation

