import React from 'react'
import { useSelector } from 'react-redux'
import Video from "../UI/Video/Video";
import {Link} from "react-router-dom";

const Videos = ({styled}) => {
    const fetch = useSelector(state => state?.fetch?.data?.header)
    const trans = useSelector(state => state?.fetch.data?.text)

    return (
        <>
            {   fetch?.videos?.items?.data && 
                <div className={styled.winter_collection}>
                    <div className='container'>
                        <h3>{fetch?.videos?.title ?? trans?.videos}</h3>
                        {/* <h3>{trans?.videos ?? "Videos"}</h3> */}
                        <p>
                            {/* {fetch?.videos?.caption} */}
                        </p>
                        <div className={styled.row_flex}>
                            {
                                fetch?.videos?.items?.data.map((video, index) => 
                                    <div key={index} className={styled.item}>
                                        <Video title={video?.title} img={video?.thumbnail} videoUrl={video?.path}/>
                                    </div>
                                )
                            }
                        </div>
                        <Link to='/videos' className={styled.btn}>{trans?.sliders['btn-view']}</Link>
                    </div>
                </div>
            }
        </>
    )
}

export default Videos