import React from 'react'
import {useSelector} from 'react-redux'

const Phones = () => {
    const phones = useSelector(state => state?.fetch?.data?.footer?.phone)
    const trans = useSelector(state => state?.fetch?.data?.text)

    return (
        <div className="footer__phones">
            <div className="footer__phone-text">{trans?.page?.contacts?.['title-phones'] ?? 'Phones'}</div>
            <div className="footer__phone-list">
                {phones && phones?.map((phone, index) =>
                    <a key={index} className="footer__phone" href={`tel:${phone}`}>
                        <div className="footer__phone-number">
                            <span>{phone}</span>
                        </div>
                    </a>
                )}
            </div>
        </div>
    )
}

export default Phones