import {createAsyncThunk} from "@reduxjs/toolkit";
import getErrorMessage from "../services/utils/error-message";
import ContactsService from "../services/contacts.service";
import { setContacts } from "../components/Redux/ContactsReducer";


export const getContacts = createAsyncThunk(
    "contacts/get",
    async (params, thunk) => {
        try {
            const response = await ContactsService.get(params);
            thunk.dispatch(setContacts(response));
            return response;
        } catch (error) {
            return thunk.rejectWithValue(getErrorMessage(error));
        }
    }
);

export const sendContact = createAsyncThunk(
    "contacts/send",
    async ({name, email, message, slug}, thunk) => {
        try {
            const response = await ContactsService.send(name, email, message, slug);
            return response;
        } catch (error) {
            return thunk.rejectWithValue(getErrorMessage(error));
        }
    }
);
