import React, {useEffect, useState} from 'react'
import sprite from "../../../assets/images/icons/sprite.svg"
import { useForm } from 'react-hook-form'
import Product from './Product'
import { useClickOutside } from '../../../hooks/useClickOutside'
import ReportPopup from './ReportPopup'
import { useDispatch,useSelector} from "react-redux";
import {disableScroll, enableScroll} from "../../../helpers/documentElement";
import { report } from "../../../slices/orders";
import {TAKE} from "../Cart/Cart/Cart";
import ReportOverlay from "./ReportOverlay";

const OrderCard = ({ order }) => {
  const dispatch = useDispatch();
  const language = useSelector(state => state?.language?.currentLanguage)
  const trans = useSelector(state => state?.fetch?.data?.text)
  const [problem, setProblem] = useState(false)
  const [productVisible, setProductVisible] = useState(false)
  const [closeReportModal, setCloseReportModal] = useState({status: true})
  const [address, setAddress] = useState([]);
  const [createdDate, setCreatedDate] = useState("")
  const [reportModal, setReportModal] = useState({
    message: '',
    status: false
  });

  useEffect(() => {
    const data = []

    if(order?.shipping_address?.country_name?.length > 0) {
      data.push(order?.shipping_address?.country_name)
    } else {
      data.push("Rep. of Moldova")
    }

    if(order?.shipping_address?.city?.length > 0) {
      data.push(order?.shipping_address?.city)
    } else {
      data.push("Chisinau")
    }

    if(order?.shipping_address?.address1?.[0]?.length > 0) {
      data.push(order?.shipping_address?.address1?.[0])
    }

    setAddress(data)

    let created_at = new Date(order?.created_at)
    setCreatedDate(created_at.toISOString().split('T')[0])


  }, [order])

  const handleProduct = () => {
    setProductVisible(!productVisible)
  }
  const handleProblem = () => {
    setReportModal({
      ...reportModal,
      status: false
    })
    setProblem(!problem)
    disableScroll()

  }
  const close = () => {
    if (problem) {
      setProblem(!problem)
      enableScroll()
    }
  }
  const outside = useClickOutside(() => {
    setProblem(false)
    enableScroll()
  })
  document.onkeyup = function (evt) {
    evt = evt || window.event;
    if (evt.keyCode === 27 && problem) {
      setProblem(false)
      enableScroll()
    }
  };
  const {register, handleSubmit, reset, formState: { errors },} = useForm({mode: 'onChange'});
  const onSubmit = async (data) => {
    setProblem(!problem)
    dispatch(report(
        {
          locale: language,
          name: data?.name,
          email: data?.email,
          message: data?.message,
          order: order?.id
        })
    ).unwrap().then((result) => {
        reset()
        setReportModal({
          status: result.status,
          message: result.message
        })
    }).catch((message) => {
      setReportModal({
        status: false,
        message: message
      })
    })
  }

  return (

      <>
        {!reportModal?.status && <ReportPopup problem={problem} outside={outside} close={close} handleSubmit={handleSubmit} onSubmit={onSubmit} errors={errors} register={register} />}
        <div className="profile__order-content">
          <div className="profile__order-main">
            <div className="profile__order-top">
              <div className="profile__order-number">{trans?.['my-profile']?.['my-orders']?.order ?? 'Order ID: '} 
                <span>{("0000000" + order?.id).slice(-7)}</span>
                { order?.is_buy_now && <span class="profile__order-oneclick">({trans?.['my-profile']?.['my-orders']?.oneclick ?? 'One click'})</span>}
              </div>
              <div className="profile__order-control">
                <div className="profile__control-completed">{order?.status_label}</div>
                <div className="profile__control-report" onClick={handleProblem}>{trans?.['my-profile']?.['my-orders']?.['report-problem']?.title}</div>
                <div className="profile__control-arrow" onClick={handleProduct}><svg className={`icon control-arrow ${productVisible ? "active-arrow" : ""} `}>
                  <use href={sprite + '#profile-arrow'}></use>
                </svg></div>
              </div>
            </div>
            <div className="profile__order-data">
              <div className="profile__order-data">{createdDate}</div>
              <div className="profile__order-data"><b>{trans?.checkout?.['complete-order']?.['payment-type']}:</b> {order?.payment_title}</div>
              {order?.shipping_method !== TAKE && <div className="profile__order-data"><b>{trans?.checkout?.['complete-order']?.['delivery-address']}:</b> {address.join(", ")}</div>}
            </div>
            <div className={`profile__order-cantity ${productVisible ? "active" : ""}`}> <span className="profile__cantity">{order?.total_item_count ? order?.total_item_count : 1}</span> {trans?.checkout?.['complete-order']?.['number-items-in-order']}</div>
          </div>
          <div className={`profile__order-products ${productVisible ? "active" : ""} `}>
            {order?.items?.map(order_product => <Product key={order_product?.id} product={order_product}/>)}
          </div>
          <div className="profile__order-bottom">
            <div className="profile__order-bg">
            {order?.discount_percent > 0 && <div className="profile__order-discount">{trans?.checkout?.['complete-order']?.['discount-card']}: -{parseFloat(order?.discount_percent).toFixed(2)}%</div> } 
            </div>
            <div className="profile__total-bottom">

              {parseFloat(order?.discount_amount) > 0 || order?.shipping_method !== TAKE &&
                  <ul className="profile__order-total">
                    <li className="profile__total-text">{trans?.checkout?.['complete-order']?.subtotal}</li>
                    <li className="profile__total-money">{order?.formated_sub_total}</li>
                  </ul>
              }

              {parseFloat(order?.discount_amount) > 0 &&
                  <ul className="profile__order-total">
                    <li className="profile__total-text">{trans?.checkout?.['complete-order']?.discount}</li>
                    <li className="profile__total-money">{order?.formated_discount_amount}</li>
                  </ul>
              }

              {order?.shipping_method !== TAKE &&
                  <ul className="profile__order-total">
                    <li className="profile__total-text delivery-prof">{trans?.checkout?.delivery}</li>
                    <li className="profile__total-money delivery-prof">{order?.formated_shipping_amount}</li>
                  </ul>
              }

              <ul className="profile__order-total profile__order-sum">
                <li className="profile__total-text final-price">{trans?.checkout?.['complete-order']?.total}</li>
                <li className="profile__total-money">{order?.formated_grand_total}</li>
              </ul>
            </div>
            <div className="profile__report-mobile" onClick={handleProblem}>
              {trans?.['my-profile']?.['my-orders']?.['report-problem']?.title}
            </div>
          </div>
        </div>
        {reportModal.status && closeReportModal.status && <ReportOverlay message={reportModal.message} close={setCloseReportModal}/>}
      </>
  )
}

export default OrderCard