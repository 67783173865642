import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import styled from './Home.module.scss';
import useWindowDimensions from '../../../hooks/useWindowDimensions';

const BannerTop = () => {
    const trans             = useSelector(state => state?.fetch?.data?.text)
    const banners           = useSelector(state => state?.fetch?.data?.header?.bannersTop)
    const windowDimensions  = useWindowDimensions();
    
    const getImage  = (banner) =>  {
        return windowDimensions?.width >= 1024 ? banner?.image : banner?.['image-mobile'];
    }

return (

    <>
        { banners && banners?.map((banner, index) => {
            
            return <section key={index} className={`banner_top ${styled.main}`}>
                <img src={getImage(banner)} alt={banner?.title}/>
                <div className="container">
                    <div className={`text_container ${styled.content}`}>
                        <h2>{banner?.title}</h2>
                        <p>{banner?.caption}</p>
                        <Link to={banner?.link}>{trans?.banners['btn-view']}</Link>
                    </div>
                </div>
            </section>
            
            })
        }

    </>
    )
}

export default BannerTop