import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import {setFavoriteInCart} from "../../Redux/FavoriteReducer";
import {useDispatch} from "react-redux";
const SignIn = ({ errorSignIn, handleForgot, setLoading, logInInput, logIn, setLoginInput, trans }) => {
    const dispatch = useDispatch()
    const [remember, setAddRemember] = useState(false)
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        mode: 'onChange'
    });
    const onSubmit = (data) => {
        setLoginInput(data)
        dispatch(setFavoriteInCart([]))
    }
    useEffect(() => {
        if (logInInput != null) {
            // reset()
            logIn().then()
        }
    }, [logInInput])
    const handleRemember = () => {
        setAddRemember(!remember)
    }
    return (
        <>

            <form className="registration__sign-in" onSubmit={handleSubmit(onSubmit)}>
                <div className="registration__sign-title">
                {trans?.register?.['sign-in-text']?.title}
                </div>
                <div className="registration__sign-text">
                    {trans?.register?.['sign-in-text']?.subtitle}
                </div>
                <div className="registration__sign-inputs">
                    <div className="registration__sign-mail input-title"> {trans?.register?.['sign-in-text']?.['input-email']} </div>
                    <input className="registration__mailinput" {...register('email', { required: true, pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g })} type="text" placeholder="Email" />
                    {errors.email && <p className='error-mesage'>{trans?.register?.['sign-in-text']?.['input-email-error']}</p>}
                    <div className="registration__sign-pass input-title" >{trans?.register?.['sign-in-text']?.['input-password']}</div>
                    <div>
                        <input className="registration__pass-input" type="password" placeholder="**********" {...register("password", { required: true })} style={{marginBottom: '0'}}/>
                        <div className='registration__error' style={{marginBottom: '25px'}}>
                            {
                                errorSignIn.status
                                    ? trans?.register?.['sign-in-text']?.['input-auth-error'] ?? 'Wrong credentials'
                                    : <></>
                            }
                        </div>
                    </div>
                    {errors.password && <p className='error-mesage'>{trans?.register?.['sign-in-text']?.['input-password-error']}</p>}
                    <div className={`registration__remember ${remember ? "active" : ""}`}  onClick={handleRemember}>
                        <input className="registration__checkbox" type="checkbox"  id="check" />
                        <label className="registration__checkbox-text">{trans?.register?.['sign-in-text']?.['checkbox']} </label>
                    </div>
                </div>
                <div className="registration__sign-bottom" >
                    <button className="registration__sign-btn" type='submit' >{trans?.register?.['sign-in-text']?.['title']} </button>
                    <div
                        className="registration__sign-forgot"
                        onClick={handleForgot}
                    >
                      {trans?.['register']?.['sign-in-text']?.['forgot-your-password']}
                    </div>
                </div>
            </form>
        </>
    )
}

export default SignIn