import React from 'react'
import Meta from '../../../meta/Meta'
import {useSelector} from "react-redux";
import parse from 'html-react-parser'


const BlogPost = () => {
    const trans = useSelector(state => state?.fetch?.data?.text);
    const article = useSelector(state => state?.articles?.article?.data);

    if (article) {
        const containerStyle = {
            backgroundImage:
                `url('${article?.image}')`,
        };

        <Meta
            title={article?.name}
            description={article?.meta_description}
            keywords={article?.meta_keywords}
            image={article?.image}
        />

        return (
            <div className="blog__posts-content">
                <div className="blog__description-container">
                    <img className='blog__post-image fullwidth' src={article?.image} alt={article?.i_title}/>
                    <div className="blog__top-description">
                        <div className="blog__posts-pretitle">{article?.additional}</div>
                        <h1 className="blog__posts-title">{article?.i_title}</h1>
                        <div className="blog__posts-date">{article?.i_caption}</div>
                        {/* <Link className="main__btn" to='/category'>
                            <div className="main__btn-text">{trans?.banners?.['btn-view']}</div>
                        </Link> */}
                    </div>
                </div>
                <div className="blog__description-content">
                    <div className="blog__posts-description">
                        <div className="blog__description-top">
                            <h2 className="blog__description-title">{article?.name}</h2>
                            <div className="product__social blog__social">
                            </div>
                        </div>
                        <div className="blog__description-subtitle">
                            <div className="blog__description-by">{trans?.['blog-page']?.author}<span
                                className="blog__description-author">{article?.author}</span></div>
                            <div className="blog__description-month">{article?.created_at}</div>
                        </div>
                        <div className="blog__description-text">
                            {parse(String(article?.content))}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default BlogPost