import React, { useEffect } from 'react'
import BreadCrumbs from '../../BreadCrumbs/BreadCrumbs'
import SocialShare from '../../SocialShare/SocialShare'
import BlogAboutInfo from './BlogPost'
import background from "../../../assets/images/pictures/blog-info.jpg"
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Loader/Loader";
import {getArticle} from "../../../slices/articles";

const BlogAbout = () => {

  const trans     = useSelector(state => state?.fetch?.data?.text);
  const language  = useSelector(state => state?.language?.currentLanguage);
  const article   = useSelector(state => state?.articles?.article?.data);
  const { slug }  = useParams();
  const dispatch  = useDispatch();

  useEffect(() => {
      dispatch(getArticle(slug, {
          locale: language
      }))
  }, [language])


  if (!article) {
    return <Loader/>
  }

  return (
    <div className='about'>
      
      <BreadCrumbs title={article?.name} home={trans?.page?.blog?.title} homeUrl={'/page/blog'}/>
      <div className="main" style={{ background: { background } }}>
        
      </div>
      <section className="blog about">
        <div className="container">
          <div className="blog__content">
            <div className="blog__posts">
              <div className="blog__posts-main">
                <BlogAboutInfo />
                <div className="blog__bottom">
                  <SocialShare />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default BlogAbout