import React, { useState, useEffect } from 'react'
import BlogTags from './BlogTags'
import BlogInfo from './BlogInfo'
import BlogRecent from './BlogRecent'
import BlogDescription from './BlogDescription'
import Meta from '../../../meta/Meta'
import Pagination from '../../Pagination/Pagination'
import BreadCrumbs from '../../BreadCrumbs/BreadCrumbs'
import { useDispatch, useSelector } from 'react-redux';
import {getArticles} from "../../../slices/articles";
import Loader from "../../Loader/Loader";
import useWindowDimensions from '../../../hooks/useWindowDimensions';

const Blog = () => {

    const trans     = useSelector(state => state?.fetch.data?.text);
    const language  = useSelector(state => state?.language?.currentLanguage);
    const articles  = useSelector(state => state?.articles?.data);
    const loading   = useSelector(state => state?.articles?.loading)
    const dispatch  = useDispatch();
    const [pagination, setPagination]   = useState(undefined);
    const [currentPage, setCurrentPage] = useState(1)
    const selectedArticles              = articles?.data?.articles?.data ? Object.values(articles?.data?.articles?.data) : [];
    const windowDimensions              = useWindowDimensions();
    const background_url                = windowDimensions?.width >= 1024 ? articles?.data?.image : articles?.data?.image_mobile;
    const styles                        = { backgroundImage: `url("${background_url}")`};

    useEffect(() => {
        dispatch(getArticles({ locale: language, page: currentPage })).unwrap().then(response => {
            setPagination(response?.data?.articles)
        }).catch(() => {
            setPagination(undefined)
        })
    }, [language, currentPage])

    const onPageChange = pageNumber => {
        setCurrentPage(pageNumber)
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    }

    return (
        <div className='blog-item'>
            <Meta 
                title={articles?.data?.name ?? articles?.data?.meta_title} 
                description={articles?.data?.meta_description} 
                keywords={articles?.data?.meta_keywords} 
                image={articles?.data?.image}
            />
            
            {loading ?
                <div className="loader__container">
                    <Loader/>
                </div>
                :
                <>
                    <div className="baner__container">
                        <div className="baner__container">
                            <div className="category-baner" style={styles}>
                                <div className="container">
                                    <div className="category-baner__title">{articles?.data?.name}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <BreadCrumbs title={articles?.data?.name}/>
                    <section className="blog">
                        <div className="container">
                            <div className="blog__content">
                                <div className="blog__info">
                                    <div className="blog__info-about">
                                        <h3 className="blog__info-title">{trans?.about?.['title']}</h3>
                                        <p className="blog__info-text">{trans?.about?.['description']}</p>
                                    </div>
                                    <BlogDescription />
                                    <BlogRecent />
                                    <BlogTags tags={articles?.data?.tags}/>
                                    
                                </div>
                                <div className="blog__posts">
                                    <div className="blog__posts-main">
                                        {selectedArticles.map(post => 
                                        <BlogInfo trans={articles?.text?.blog} post={post} key={post.id}/>)}
                                        {pagination !== undefined && <Pagination
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            totalCount={pagination?.total}
                                            pageSize={pagination?.per_page}
                                            lastPage={pagination?.last_page}
                                            onPageChange={onPageChange}
                                        />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </>
            }
        </div>
    )
}

export default Blog