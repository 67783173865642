import React from 'react'
import {ReactComponent as IconClose} from "../../../assets/images/icons/icon-close.svg";
import styled from './HeaderPopup.module.css';
import {useSelector} from "react-redux";

const HeaderPopup = ({ product, savedOption }) => {

  const trans = useSelector(state => state?.fetch?.data?.text)

  return (
      <>
        <div className="bag-popup__content">
          <div className="bag-popup__image" >
            <img className="bag-popup__img" src={product?.base_image?.['product-order']} alt="popup-image" />
          </div>
          <div className="bag-popup__info" style={{position: 'relative'}}>
            <div className="bag-popup__title">{product.name}</div>
            <div className="bag-popup__price">{savedOption?.savedQuantity} x {product?.is_sales ? product?.formated_special : product?.formated_price}</div>
            <div className="bag-popup__details">
              <div className="bag-popup__size">{trans?.products?.['popup-buy-now']?.size} {savedOption?.savedSize}</div>
              {savedOption?.savedColor && <div className="bag-popup__color" style={{ background: savedOption?.savedColor }}/>}
            </div>
          </div>
          <IconClose className={`${styled.close}`}/>
        </div>

      </>
  )
}

export default HeaderPopup