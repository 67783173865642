import { createSlice } from "@reduxjs/toolkit";

const productsSlice = createSlice({
    name: 'products',
    initialState: {
        currentProduct: undefined,
        loading: false
    },

    reducers: {
        setCurrentProduct: (state, action) => {
            state.currentProduct = action.payload
        },
        setProductLoading: (state, action) => {
            state.loading = action.payload
        }
    }
})

export const { setCurrentProduct, setProductLoading } = productsSlice.actions
export default productsSlice.reducer