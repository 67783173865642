import React, {useState} from 'react'
import Meta from '../../../meta/Meta'
import Galleries from '../../Gallery/Galleries'
import BannerTop from './BannerTop'
import HomeBanner from './HomeBanner'
import styled from './Home.module.scss';
import { useSelector } from 'react-redux'
import Videos from '../../Videos/Videos'
import CartSuccess from '../Cart/CartSuccess'
import {useNavigate} from "react-router-dom";
import {disableScroll, enableScroll} from "../../../helpers/documentElement";


const Home = ({success = false}) => {

    const fetch = useSelector(state => state?.fetch?.data.header)
    const trans = useSelector(state => state?.fetch?.data.text)
    const image = `url(${fetch?.logo})`;
    const [cart_success,setCartSuccess] = useState(success);
    const navigate  = useNavigate()

    const closeSuccess = () => {
        navigate('/');
        setCartSuccess(false);
        enableScroll();
    }
    return (
        <>
        <Meta 
            title={fetch?.seo?.meta_title} 
            description={fetch?.seo?.meta_description} 
            keywords={fetch?.seo?.meta_keywords} 
            image={fetch?.logo}
        />
        {cart_success && <CartSuccess close={closeSuccess} />}
        <div className="main" style={{ backgroundImage: image }}>
            <BannerTop/>
            {/* <div className="container">
                 <div className="main__content">
                   <div className="main__pretitle">Limited edition</div>
                   <h1 className="main__title">{fetch?.bannersBottom[0]?.title}</h1>
                   <div className="main__subtitle">{fetch?.bannersBottom[0]?.caption}</div>
                    <Link className="main__btn" to='category'>
                        <div className="main__btn-text">View Collection</div>
                    </Link>
                 </div>
           </div> */}
        </div>
        <section className="gallery__bottom">
            <div className="container">
                <div className="gallery__banner">
                    <HomeBanner/>
                </div>
            </div>
        </section>

            <Videos styled={styled}/>
            <Galleries color="grey"/>

        {/* <Instagram /> */}

        </>
    )
}

export default Home