import React, { useEffect } from 'react'
import { useSelector} from 'react-redux';

const Product = ({ product }) => {
    const trans = useSelector(state => state?.fetch?.data?.text)

    return (
        <div className="profile__order-product">
            <img className="profile__product-img" src={product?.base_image?.product_add_cart_image_url} alt="" />
            <div className="profile__product-name">
                <div className="profile__name-title">{product?.product?.name ?? product?.name}</div>
                <div className="profile__name-code">{product?.product?.short_description}{product?.sku ? " | " + product?.sku : ""}</div>
                <div className="bag-popup__details">
                    {product?.additional?.savedOption?.savedSize?.length > 0 &&
                        <div className="profile__product-size">{trans?.checkout?.size}:<span>{product?.additional?.savedOption?.savedSize}</span></div>
                    }
                    {product?.additional?.savedOption?.savedColor?.length > 0 &&
                        <div className="bag-popup__color" style={{background: product?.savedColor}}/>
                    }
                </div>
                <div className="profile__product-cantity-mobile">
                    <span className="profile__product-number">{product?.qty_ordered ?? 1} </span> x {product?.formated_price}
                </div>
            </div>
            <div className="profile__product-right">
                <div className="profile__product-cantity">
                    <span className="profile__product-number">{product?.qty_ordered ?? 1}</span> x  {product?.formated_price}
                </div>
            </div>
        </div>
    )
}

export default Product