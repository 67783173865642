import React, { useEffect } from 'react'
import Meta from '../../../meta/Meta'
import { Link } from 'react-router-dom'
import error from "../../../assets/images/pictures/404.jpg"
import { useDispatch, useSelector } from 'react-redux'
import { getInfoPage } from "../../../slices/infopage"
import parse from "html-react-parser"

const Error = () => {
    const trans     = useSelector(state => state?.fetch?.data?.text);
    const fetch     = useSelector(state => state?.fetch?.data?.header)
    const infopage  = useSelector(state => state?.infoPage?.data)

    const dispatch  = useDispatch();
    const locale    = useSelector(state => state?.language?.currentLanguage)
    const url       = 'not-found';

    useEffect(() => {
        dispatch(getInfoPage({url,locale}))
      }, [url, locale, dispatch])

    return (
        <>
            <Meta 
                title={infopage?.data?.name ?? infopage?.data?.meta_title ?? `${fetch?.seo?.meta_title} | 404`} 
                description={infopage?.data?.meta_description ?? infopage?.data?.description ?? fetch?.seo?.meta_description} 
                keywords={infopage?.data?.meta_keywords ?? fetch?.seo?.meta_keywords} 
                image={infopage?.data?.image ?? fetch?.logo}
            />
            <div className='container error'>
                <img src={infopage?.data?.image ?? error} alt="" />
                <div className="container">
                    <h1 className="error__title">
                        { parse(infopage?.data?.content ?? '') ?? trans?.error?.['page-not-found'] ?? "Page not found"}
                    </h1>
                    <Link className="error__back" to="/"> {trans?.checkout?.['complete-order']?.['button-go-to-home-page']}</Link>
                </div>
            </div>
        </>
    )
}

export default Error