import React from 'react'
import minicode from '../../assets/images/pictures/minicode.png'

const Copyright = () => {
    return (
        <section className="copyright">
            <div className="container">
                <div className="copyright__content">
                    <div className="copyright__minicode">
                        <div className="copyright__minicode-text">Made by</div>
                        <a className="copyright__minicode-link" target="_blank" href="https://minicode.md/"><img src={minicode} alt="minicode" /></a>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Copyright