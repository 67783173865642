import React from 'react'
import {Helmet} from "react-helmet"
import {useSelector} from 'react-redux'

const Meta = ({title, description, keywords, image = null}) => {

    const fetch = useSelector(state => state?.fetch?.data.header)

    return (
        <div className="application">
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{title ?? fetch?.seo?.meta_title}</title>
                <meta name="description" content={description ?? fetch?.seo?.meta_description}/>
                <meta name="keywords" content={keywords ?? fetch?.seo?.meta_keywords}/>
                <meta property="og:title" content={title ?? fetch?.seo?.meta_title}/>
                <meta property="og:description" content={description ?? fetch?.seo?.meta_description}/>
                <meta property="og:image" content={image ?? fetch?.logo}/>
            </Helmet>
        </div>
    )
}

export default Meta