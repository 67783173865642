import React from 'react'
import {useDispatch, useSelector} from "react-redux";
import {setProfileSection} from "../../Redux/ProfileReducer";

const ProfileButtons = ({ section }) => {

    const dispatch = useDispatch();
    const trans = useSelector(state => state.fetch.data.text)

    return (
        <div className="profile__top-btns ">
            <div className={`profile__top-btn ${section === "data" && "profile__active-btn"}`} onClick={() => dispatch(setProfileSection("data"))} >
                <div className="profile__personaldata-text">{trans?.['my-profile']?.['personal-data']?.['page-title']}</div>
            </div>
            <div className={`profile__top-btn ${section === "address" && "profile__active-btn"}`} onClick={() => dispatch(setProfileSection("address"))} >
                <div className="profile__personaldata-text">{trans?.['my-profile']?.['my-addresses']?.['title-my-addresses']}</div>
            </div>
            <div className={`profile__top-btn ${(section === "order") && "profile__active-btn"}`} onClick={() => dispatch(setProfileSection("order"))} >
                <div className="profile__personaldata-text">{trans?.['my-profile']?.['btn-my-orders']}</div>
            </div>
        </div>
    )
}

export default ProfileButtons