import React from 'react'
import { useSelector } from 'react-redux';

const ContactHours = ({ sprite }) => {
    const label = useSelector(state => state?.contacts?.data?.text?.contacts?.['title-hours']);
    const hours = useSelector(state => state?.contacts?.data?.data?.hours);

    return (
        <div className="contact__info-hours">
            <div className="contact__hours-icon">
                <svg className="icon contact__hours">
                    <use href={sprite + '#contact-time'}></use>
                </svg>
            </div>
            <h3 className="contact__hours-title">{label}</h3>
            <div className="contact__hours-work">
                {
                   hours && hours?.map( (hour,index) => 
                    <ul key={index} className="contact__hours-items">
                        <li className="contact__hours-time">{hour}</li>
                    </ul>)
                }
            </div>
        </div>
    )
}

export default ContactHours