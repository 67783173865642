export default function authParams(params = {}) {
    const user = JSON.parse(localStorage.getItem("user"));
    const currency = JSON.parse(localStorage.getItem("currency"));
    if (user && user.token) {
        return { 
            ...params, 
            token: true, 
            //currency: currency 
        };
    } else {
        return {
            ...params, 
            //currency: currency
        };
    }
}