import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux';

const BreadCrumbs = ({title = '', home = null, homeUrl = '/'}) => {
    const defaulthome = useSelector(state => state?.fetch?.data?.text?.breadcrumbs);
    return (
        <div className="breadcrumbs">
            <div className="container">
                <div className="breadcrumbs__content">
                    <ul className="breadcrumbs__item">
                        <li className="breadcrumbs__items"><Link className="breadcrumbs__link" to={homeUrl}>{home ?? defaulthome}</Link></li>
                        <li className="breadcrumbs__items"><div className="breadcrumbs__link" >{title}</div></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default BreadCrumbs