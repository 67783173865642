import React from 'react'
import InputMask from "react-input-mask"
import popup1 from "../../../assets/images/pictures/product-popup-1.jpg"
import popup2 from "../../../assets/images/pictures/product-popup-2.jpg"
import popup3 from "../../../assets/images/pictures/product-popup-3.jpg"
import tabel from "../../../assets/images/pictures/tabel.png"
import ScrollContainer from 'react-indiana-drag-scroll'
import sprite from "../../../assets/images/icons/sprite.svg"
import {useSelector} from "react-redux";
import parse from "html-react-parser"

const ProductOverlay = ({ overlay, popup, product, outside, close, counter, savedOption, handleSubmit, onSubmit, errors, register, setData, buyNowMessage }) => {

    const trans     = useSelector(state => state?.fetch?.data?.text);
    const loading   = useSelector(state => state?.orders?.loading);
    const currency = useSelector(state => state?.currency?.currentDataCurrency)


    const formatChars = {
        '9': '[0-9]',
        '6': '[6-7]',
        '1': '[1-9]',
        'a': '[A-Za-z]',
        '*': '[A-Za-z0-9]'
      } 
    
    return (
        <div className={`overlay ${overlay ? "active" : ""}`} >
            {popup === "buy" &&
                <div className='phone__popup active' ref={outside}>
                    <div className="product__popup-close" onClick={close}><svg className="icon close-product">
                        <use href={sprite + '#close-product-popup'}></use>
                    </svg></div>
                    <div className="phone__popup-content">
                        <div className="phone__popup-info">
                            <div className="phone__popup-title">{trans?.products?.['popup-buy-now']?.title}</div>
                            <div className="phone__popup-product">
                                <div className="bag-popup__content">
                                    <div className="bag-popup__image" >
                                        <img className="bag-popup__img" src={product?.base_image?.['product-buy-now']} alt="popup-image" />
                                    </div>
                                    <div className="bag-popup__info">
                                        <div className="bag-popup__info-top">
                                            <div className="bag-popup__title">{product?.name}</div>
                                            <div className="bag-popup__price">{counter} x {product?.formated_price}</div></div>
                                        <div className="bag-popup__article">{product?.short_description} | {product?.sku}</div>
                                        <div className="bag-popup__details">
                                            <div className="bag-popup__size">{trans?.products?.size}: {savedOption?.savedSize}</div>
                                            {savedOption?.savedColor && <div className="bag-popup__color" style={{ background: savedOption?.savedColor }}></div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <form className="phone__popup-form buy-it-now" onSubmit={handleSubmit(onSubmit)}>
                                <div className="phone__popup-inputs">
                                    <div className="phone__popup-name">
                                        <div className="contact__login-name">{trans?.products?.['popup-buy-now']?.['input-firstname'] ?? "Firstname"}</div>
                                        <input className="registration__name-input" {...register('first_name', {
                                            required: { value: true, message: trans?.products?.['popup-buy-now']?.['input-firstname-error'] ?? "Complete your firstname" },
                                            pattern: { value: /^[A-Za-z]+$/i, message: 'Firstname is wrong.' }
                                        })} type="text" placeholder={trans?.products?.['popup-buy-now']?.['input-firstname']} />
                                        {errors.first_name && <p className='error-mesage'>{errors.first_name.message}</p>}
                                    </div>
                                    <div className="phone__popup-name">
                                        <div className="contact__login-name">{trans?.products?.['popup-buy-now']?.['input-lastname'] ?? "Lastname"}</div>
                                        <input className="registration__name-input" {...register('last_name', {
                                            required: { value: true, message: trans?.products?.['popup-buy-now']?.['input-lastname-error'] ?? "Complete your lastname" },
                                            pattern: { value: /^[A-Za-z]+$/i, message: 'Lastname is wrong.' }
                                        })} type="text" placeholder={trans?.products?.['popup-buy-now']?.['input-lastname']} />
                                        {errors.last_name && <p className='error-mesage'>{errors.last_name.message}</p>}
                                    </div>
                                    <div className="phone__login-name">
                                        <div className="contact__login-name">{trans?.products?.['popup-buy-now']?.['input-email']}</div>
                                        <input className="registration__name-input"  {...register('email', {
                                            required: { value: true, message: trans?.products?.['popup-buy-now']?.['input-email-error'] },
                                            pattern: { value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g, message: 'Email is wrong.' }
                                        })} type="text" placeholder={trans?.products?.['popup-buy-now']?.['input-email']} />
                                        {errors.email && <p className='error-mesage'>{errors.email.message}</p>}
                                    </div>

                                    <div className="phone__popup-phone">
                                        <div className="contact__login-mail">{trans?.products?.['popup-buy-now']?.['input-phone']}</div>
                                        <InputMask 
                                            className="registration__name-input popup__newadd-input"
                                            placeholder={trans?.products?.['popup-buy-now']?.['input-phone']}
                                            mask={currency?.country_code === 'RO' ? '+40 199 999 999' : "+373 69 999 999"}
                                            formatChars={formatChars} 
                                            {...register("phone", { 
                                                required: true, 
                                                minLength: 15, 
                                                pattern: currency?.country_code === 'RO' ? /^\+40 [1-9]\d{0,2} \d{3} \d{3}$/ : /^\+373 [1-9]\d{0,1} \d{3} \d{3}$/,
                                            })} 
                                        />

                                    </div>
                                    <input {...register('product_id')} type="hidden" defaultValue={product?.id} />
                                    <input {...register('savedOption')} type="hidden" defaultValue={savedOption} />
                                    <input {...register('quantity')} type="hidden" defaultValue={savedOption?.savedQuantity} />
                                    <input {...register('size')} type="hidden" defaultValue={savedOption?.savedSize} />
                                </div>
                                <button disabled={loading} className="contact__send" type="submit" onClick={setData}>{trans?.products?.['popup-buy-now']?.['btn-submit']}</button>
                                {buyNowMessage.length > 0 && <div><p className="error-mesage">{buyNowMessage}</p></div>}
                            </form>
                        </div>
                    </div>
                </div>
            }
            {popup === "size" &&
                <div className="size active" ref={outside}>
                    <div className="product__popup-close" onClick={close}><svg className="icon close-product">
                        <use href={sprite + '#close-product-popup'}></use>
                    </svg></div>
                    <ScrollContainer hideScrollbars={false} className="scroll-container">
                        <div className="size__tabel">
                            <img className='size__tabel-img' src={product?.information?.sizes?.img ?? tabel} alt="" />
                        </div>
                    </ScrollContainer>
                </div>
            }
            {popup === "payment" &&
                <div className="product__popup active__product-popup" ref={outside}>
                    <div className="product__popup-content">
                        <div className="product__popup-close" onClick={close}><svg className="icon close-product">
                            <use href={sprite + '#close-product-popup'}></use>
                        </svg></div>
                        <div className="product__popup-info">
                            <div className="product__popup-description">
                                <div className="product__popup-title">{product?.information?.payment?.title ?? trans?.products?.payment}</div>
                                <div className="product__popup-text">{parse(product?.information?.payment?.description ?? '')}</div>
                            </div>
                            <img src={product?.information?.payment?.img ?? popup1} alt="" />
                        </div>
                    </div>
                </div>}
            {popup === "delivery" &&
                <div className="product__popup active__product-popup" ref={outside}>
                    <div className="product__popup-content">
                        <div className="product__popup-close" onClick={close}><svg className="icon close-product">
                            <use href={sprite + '#close-product-popup'}></use>
                        </svg></div>
                        <div className="product__popup-info">
                            <div className="product__popup-description">
                                <div className="product__popup-title">{product?.information?.delivery?.title ?? trans?.page?.['delivery-page']?.title}</div>
                                <div className="product__popup-text">{parse(product?.information?.delivery?.description ?? '')}</div>
                            </div>
                            <img src={product?.information?.delivery?.img ?? popup3} alt="" />
                        </div>
                    </div>
                </div>
            }
            {popup === "return" &&
                <div className="product__popup active__product-popup" ref={outside}>
                    <div className="product__popup-content">
                        <div className="product__popup-close" onClick={close}><svg className="icon close-product">
                            <use href={sprite + '#close-product-popup'}></use>
                        </svg></div>
                        <div className="product__popup-info">
                            <div className="product__popup-description">
                                <div className="product__popup-title">{product?.information?.return?.title ?? trans?.products?.['return-policy']}</div>
                                <div className="product__popup-text">{parse(product?.information?.return?.description ?? '')}</div>
                            </div>
                            <img src={product?.information?.return?.img ?? popup2} alt="" />
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default ProductOverlay