import React from 'react';
import Overlay from "../../Overlay/Overlay";
import sprite from "../../../assets/images/icons/sprite.svg";
import styled from './Modal.module.css'

const Modal = (props) => {
    const fnCloseModal = () => {
        props?.closeModal(false);
        document.body.style.overflow = "auto"
    }

    return (
        <Overlay status={props?.status}>
            <div className={`reset ${styled?.modal}`}>
                <div className="product__popup-close" onClick={fnCloseModal}>
                    <svg className="icon close-product">
                        <use href={sprite + '#close-product-popup'}></use>
                    </svg>
                </div>
                <div className="reset__title" style={{marginBottom: 0}}>
                    {props?.children}
                </div>
                {
                    props?.btnClose
                    ? <button className={`contact__send ${styled?.closeBtn}`} onClick={fnCloseModal}>Close</button>
                    : <></>
                }
            </div >
        </Overlay>
    );
};

export default Modal;