import React, { useState, useEffect } from 'react'
import Info from './Info'
import Terms from './Terms'
import Policy from './Policy'
import Return from './Return'
import Meta from '../../../meta/Meta'
import PrivacyBtns from './PrivacyBtns'
import PrivacyBanner from './PrivacyBanner'
import BreadCrumbs from '../../BreadCrumbs/BreadCrumbs'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams, useParams } from 'react-router-dom'
import { getInfoPage } from "../../../slices/infopage";
import Loader from "../../Loader/Loader";

const Privacy = () => {
  const trans = useSelector(state => state?.fetch?.data?.text);
  const infopage = useSelector(state => state?.infoPage?.data)
  const loading = useSelector(state => state?.infoPage?.loading)

  const dispatch = useDispatch();

  const navigate = useNavigate()
 
  const locale = useSelector(state => state.language.currentLanguage)
  // const [searchParams] = useSearchParams()

  const params        = useParams();
  const url           = params?.slug;


  useEffect(() => {
    dispatch(getInfoPage({url,locale}))
  }, [url, locale])



    return (
      <>
      {loading ? 
        <div className="loader__container">
            <Loader/>
        </div>
      :
      <div className='privacy-page'>
        <Meta 
          title={infopage?.data?.name ?? infopage?.data?.meta_title} 
          description={infopage?.data?.meta_description ?? infopage?.data?.description} 
          keywords={infopage?.data?.meta_keywords} 
          image={infopage?.data?.image}
        />
        <PrivacyBanner 
          title={infopage?.data?.name} 
          subtitle={infopage?.data?.description}
          image={infopage?.data?.image}
          image_mobile={infopage?.data?.image_mobile}
        />
        <BreadCrumbs title={infopage?.data?.name} />
        <section className="profile">
          <div className="container">
            <div className="profile__content">
              {infopage?.text && <PrivacyBtns data={url} />}
              <Policy content={ infopage?.data }/>
            </div>
          </div>
        </section>
      </div>
      }
      </>
    )
  
}

export default Privacy