import React from 'react'
import { Link } from 'react-router-dom'
import empty from "../../../assets/images/pictures/empty-cart.png"
import { useSelector } from 'react-redux'
const WishEmpty = () => {
    const trans = useSelector(state => state?.fetch?.data?.text)
    return (
        <div className='empty__cart'>
            <img className="empty__cart-img" src={empty} alt="" />
            <div className="empty__cart-title">{trans?.wishlist?.empty ?? 'Your wishlist is empty'}</div>
            <div className="empty__cart-text">{trans?.wishlist?.explore ?? 'Explore the shop'}</div>
            <Link to="/" className="empty__cart-btn">{trans?.['my-profile']?.['my-orders']?.['btn-start-shopping'] ?? 'Start shopping'}</Link>
        </div>
    )
}

export default WishEmpty