import {createAsyncThunk} from "@reduxjs/toolkit";
import CartService from "../services/cart.service";
import {
    setCart,
    setCartLoading,
    setCartProgress,
    setCouponTotal,
    setDiscountTotal,
    setPayment,
    setPaymentLoading,
    setPaymentRedirect,
    setSuccess,
    setVerifyProgress,
    setOrderStatus
} from "../components/Redux/CartReducer";
import {setCurrentCartCounter} from "../components/Redux/UserCounterReducer";
import getErrorMessage from "../services/utils/error-message";

export const getCart = createAsyncThunk(
    "cart/get",
    async ({ currency }, thunk) => {
        try {
            thunk.dispatch(setCartProgress(true))
            thunk.dispatch(setCartLoading(true))
            const cart = await CartService.get(currency);
            replaceCart(thunk, cart?.data)
            thunk.dispatch(setCartProgress(false))
            return cart
        } catch (error) {
            setTimeout(() => {
                thunk.dispatch(getCart())
            }, 5000)
            return thunk.rejectWithValue(getErrorMessage(error));
        }
    }
);

export const addToCart = createAsyncThunk(
    "cart/add",
    async ({ product_id, quantity, size, savedOption }, thunk) => {
        try {
            thunk.dispatch(setCartProgress(true))
            const cart = await CartService.add(product_id, quantity, size, savedOption);
            replaceCart(thunk, cart?.data)
            thunk.dispatch(setCartProgress(false))
            return cart
        } catch (error) {
            thunk.dispatch(setCartProgress(false))
            return thunk.rejectWithValue(getErrorMessage(error));
        }
    }
);

export const updateCart = createAsyncThunk(
    "cart/update",
    async ({ product_id, count}, thunk) => {
        try {
            thunk.dispatch(setCartProgress(true))
            const cart = await CartService.update(product_id, count);
            replaceCart(thunk, cart?.data)
            thunk.dispatch(setCartProgress(false))
            return cart
        } catch (error) {
            thunk.dispatch(setCartProgress(false))
            return thunk.rejectWithValue(getErrorMessage(error));
        }
    }
);

export const cartAddress = createAsyncThunk(
    "cart/address",
    async ({ data }, thunk) => {
        try {
            thunk.dispatch(setCartProgress(true))
            const response = await CartService.address(data)
            thunk.dispatch(setCartProgress(false))
            return response
        } catch (error) {
            thunk.dispatch(setCartProgress(false))
            return thunk.rejectWithValue(getErrorMessage(error));
        }
    }
);

export const cartShipping = createAsyncThunk(
    "cart/shipping",
    async ({ shipping_method, shipping_zone, currency }, thunk) => {
        try {
            thunk.dispatch(setCartProgress(true))
            const response = await CartService.shipping(shipping_method, shipping_zone, currency);
            if(response?.data?.cart) {
                replaceCart(thunk, response?.data?.cart)
            }
            thunk.dispatch(setCartProgress(false))
            return response
        } catch (error) {
            thunk.dispatch(setCartProgress(false))
            return thunk.rejectWithValue(getErrorMessage(error));
        }
    }
);

export const cartPayment = createAsyncThunk(
    "cart/payment",
    async ({ method }, thunk) => {
        try {
            thunk.dispatch(setCartProgress(true))
            const response = await CartService.payment(method);
            if(response?.data?.cart) {
                replaceCart(thunk, response?.data?.cart)
            }
            thunk.dispatch(setCartProgress(false))
            return response
        } catch (error) {
            thunk.dispatch(setCartProgress(false))
            return thunk.rejectWithValue(getErrorMessage(error));
        }
    }
);

export const cartComment = createAsyncThunk(
    "cart/comment",
    async ({ comment }, thunk) => {
        try {
            thunk.dispatch(setCartProgress(true))
            const response = await CartService.comment(comment);
            if(response?.data?.cart) {
                replaceCart(thunk, response?.data?.cart)
            }
            thunk.dispatch(setCartProgress(false))
            return response
        } catch (error) {
            thunk.dispatch(setCartProgress(false))
            return thunk.rejectWithValue(getErrorMessage(error));
        }
    }
);


export const cartSave = createAsyncThunk(
    "cart/save",
    async (_, thunk) => {
        try {
            thunk.dispatch(setCartProgress(true))
            const response = await CartService.save();
            if(response?.data?.cart) {
                //replaceCart(thunk, response?.data?.cart)
            }
            thunk.dispatch(setCartProgress(false))
            return response
        } catch (error) {
            thunk.dispatch(setCartProgress(false))
            return thunk.rejectWithValue(getErrorMessage(error));
        }
    }
);

export const cartCoupon = createAsyncThunk(
    "cart/coupon",
    async ({ code }, thunk) => {
        try {
            thunk.dispatch(setCartProgress(true))
            const response = await CartService.coupon(code);
            if(response?.data?.cart) {
                replaceCart(thunk, response?.data?.cart)
            }
            if(response?.data?.coupon?.discount) {
                thunk.dispatch(setCouponTotal(response?.data?.coupon?.discount))
            }
            thunk.dispatch(setCartProgress(false))
            return response
        } catch (error) {
            thunk.dispatch(setCartProgress(false))
            return thunk.rejectWithValue(getErrorMessage(error));
        }
    }
);

export const cartDiscount = createAsyncThunk(
    "cart/discount",
    async ({ code }, thunk) => {
        try {
            thunk.dispatch(setCartProgress(true))
            const response = await CartService.discount(code);

            if(response?.data?.cart) {
                replaceCart(thunk, response?.data?.cart)
            }
            if(response?.data && response?.data["discount-card"]?.discount) {
                thunk.dispatch(setDiscountTotal(response?.data["discount-card"]?.discount))
            }
            thunk.dispatch(setCartProgress(false))
            return response
        } catch (error) {
            thunk.dispatch(setCartProgress(false))
            return thunk.rejectWithValue(getErrorMessage(error));
        }
    }
);

export const removeCartProduct = createAsyncThunk(
    "cart/remove_product",
    async ({ product_id }, thunk) => {
        try {
            thunk.dispatch(setCartProgress(true));
            const cart = await CartService.remove(product_id);
            replaceCart(thunk, cart?.data)
            thunk.dispatch(setCartProgress(false));
            return cart
        } catch (error) {
            thunk.dispatch(setCartProgress(false));
            return thunk.rejectWithValue(getErrorMessage(error));
        }
    }
    
);

export const invoice = createAsyncThunk(
    "cart/online_payment",
    async (id, thunk) => {
        try {
            thunk.dispatch(setPaymentLoading(true));
            const response = await CartService.invoice(id);
            thunk.dispatch(setPayment(response));

            return response
        } catch (error) {
            thunk.dispatch(setPaymentLoading(false));
            return thunk.rejectWithValue(getErrorMessage(error));
        }
    }
);

export const onlinePay = createAsyncThunk(
    "cart/online_payment",
    async (id, thunk) => {
        try {
            thunk.dispatch(setPaymentLoading(true));
            const response = await CartService.onlinePay(id);
            thunk.dispatch(setPaymentRedirect(response));

            return response
        } catch (error) {
            thunk.dispatch(setPaymentLoading(false));
            return thunk.rejectWithValue(getErrorMessage(error));
        }
    }
);

export const success = createAsyncThunk(
    "cart/success",
    async (id, thunk) => {
        try {
            const response = await CartService.success(id);
            thunk.dispatch(setSuccess(response));
            return response
        } catch (error) {
            return thunk.rejectWithValue(getErrorMessage(error));
        }
    }
);

export const verify_status = createAsyncThunk(
    "cart/verify_status",
    async (id, thunk) => {
        try {
            thunk.dispatch(setVerifyProgress(true));
            const response = await CartService.verify_status(id);
            thunk.dispatch(setOrderStatus(response));
            thunk.dispatch(setVerifyProgress(false));
            return response
        } catch (error) {
            thunk.dispatch(setVerifyProgress(false));
            return thunk.rejectWithValue(getErrorMessage(error));
        }
    }
);




const replaceCart = (thunk, cart) => {
    thunk.dispatch(setCart(cart))
    thunk.dispatch(setCurrentCartCounter(cart?.items_count ?? null))
}