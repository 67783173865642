import React from 'react'
import { useSelector } from 'react-redux';
 
const ContactMail = ({sprite}) => {
    const label     = useSelector(state => state?.contacts?.data?.text?.contacts?.['title-email']);
    const value     = useSelector(state => state?.contacts?.data?.data?.additional?.email);
      
    return (
        <div className="contact__info-mail">
            <div className="contact__mail-icon"><svg className="icon contact__mail">
                <use href={sprite + '#contact-mail'}></use>
            </svg></div>
            <h3 className="contact__mail-title">{label}</h3>
            <a className="contact__mail-text" target="_blank" href={`mailto:${value}`}>{value}</a>
        </div>
    )
}

export default ContactMail