import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import sprite from "../../assets/images/icons/sprite.svg"
import {addToWishlist, updateWishlist} from "../../slices/wishlist";

const Goods = ({product}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const trans = useSelector(state => state?.fetch?.data?.text)
    const favorites = useSelector(state => state?.favorite?.favoriteInCart)

    const aboutClick = () => {
        navigate(`/product/${product?.url_key}`)
    }

    const [userFavorite, setUserFavorite] = useState(product?.is_wishlisted)

    useEffect(() => {
        setUserFavorite(product?.is_wishlisted)
    }, [product]);

    const removeFavorite = (product_id = null) => {
        if (product_id) {
            const new_data = favorites?.data.filter(item => item?.product?.id !== product_id);
            const new_list = {
                data: new_data,
                links: favorites?.links,
                meta: favorites?.meta,
            }
            dispatch(updateWishlist(new_list));
        }
    }

    const favoriteClick = () => {
        dispatch(addToWishlist(product?.id)).unwrap().then(response => {
            setUserFavorite(false)
            if (response?.data) {
                setUserFavorite(true)
            } else {
                removeFavorite(product?.id)
            }
        }).catch();
    };

    return (
        <div className="gallery__images" id={product.id} key={product.id}>
            <div className="gallery__images-main">
                <div className="gallery__images-top">
                    {/* {product?.is_sales
                        ? <div className="gallery__discount">-{Math.round(100 - product?.special_price / (product?.price / 100))}%</div> 
                        : ''
                    }
                    {product?.new ? <div className="gallery__discount">{trans?.category?.new}</div> : ""} */}
                </div>
                <div className="gallery__link" onClick={aboutClick}>
                    <img className="gallery__image"
                         src={product?.base_image?.product_category_image_url ?? product?.base_image?.product_image_url}
                         alt={product?.name}/></div>
            </div>
            <div className="gallery__image-bottom">
                <div className="gallery__image-info">
                    <div className="gallery__image-title">{product?.name}</div>
                    <div className="gallery__image-container">
                        {/* If has discount */}
                        { product?.is_sales
                            ? 
                            <>
                                <div className={`gallery__image-price gallery__sale-price sale_special`} >
                                    { product?.formated_special}
                                </div> 
                                <div className={`gallery__image-price sale`}>{product?.formated_price}</div>
                            </>
                            : 
                            <div className={`gallery__image-price gallery__sale-price `} >
                                { product?.formated_price}
                            </div> 
                        }
                        
                    </div>
                </div>
                <button className="gallery__image-bag" onClick={favoriteClick}>
                    {userFavorite ? <svg className="icon gal-bag-active">
                            <use href={sprite + '#favorite'}></use>
                        </svg>
                        : <svg className="icon gal-bag">
                            <use href={sprite + '#favorite'}></use>
                        </svg>}
                </button>
            </div>
        </div>
    )
}

export default Goods