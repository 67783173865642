import { createSlice } from "@reduxjs/toolkit";

const searchSlice = createSlice({
    name: 'search',
    initialState: {
        currentSearch: undefined,
        loading: true
    },

    reducers: {
        setCurrentSearch: (state, action) => {
            state.currentSearch = action.payload
            state.loading = false
        },
        setLoadingSearch: (state, action) => {
            state.loading = action.payload
        }
    }
})

export const { setCurrentSearch, setLoadingSearch } = searchSlice.actions
export default searchSlice.reducer