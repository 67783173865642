import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
const PrivacyBtns = ({data }) => {
    const links = useSelector(state => state?.fetch?.data?.footer?.columns[0]?.pages)

    return (
        <div className="profile__top-btns ">
            {
                links && links?.map(item => 
                    <Link 
                        key={item?.id} 
                        to={`/page/${item?.link}`}
                        className={`profile__top-btn ${data == item?.link && "profile__active-btn"}`}
                    >
                        <div className="profile__personaldata-text">{item?.name}</div>
                    </Link>
                )
            }
        </div>
    )
}

export default PrivacyBtns