import { useRef } from 'react'
import { Link } from 'react-router-dom'

const HeaderSearchDesktop = ({ sprite, search, query, setQuery, placeholder, activeSearch, closeSearch }) => {
  const searchInput = useRef()

  const handleClick = () => {
    activeSearch()

    // Focus input element
    setTimeout(() => {
      searchInput.current.focus()
    }, 100);
  }

  return (
    <div className="mobile__search-icon">
                        
      <div className={`search__popup ${search ? "active" : ""}`}>
        <div className="container">
          <div className="search__popup-content">
            <input
              className="search__popup-input"
              type='text'
              placeholder={placeholder}
              value={query}
              onChange={e => setQuery(e.target.value)}
              ref={searchInput}
            />
              {/* <div className="search__popup-close" onClick={closeSearch}>
                  <svg className="icon close-search">
                      <use href={sprite + '#close-search-popup'}/>
                  </svg>
              </div> */}
          </div>
        </div>
      </div>

      <Link
        className="header__rightside-link"
        to="#" 
        onClick={handleClick}
      >
        <svg className="icon search">
          <use href={sprite + '#search'}></use>
        </svg>
      </Link>
    </div>
  )
}

export default HeaderSearchDesktop