import React from 'react'
import parse from 'html-react-parser';

const Policy = ({content}) => {
  if (content) {
    return (
      <div className={`profile__data active-profile`}>
        <h3 className="profile__data-title">{content?.name}</h3>
        <div className="privacy__info">
          <div className="privacy__article">
            {parse(String(content?.content ?? ''))}
          </div>
        </div>
      </div>
    )
  }
}

export default Policy