import React, {useMemo, useState} from "react";
import Coupon from "../Coupon";
import Select from "react-select";
import { useEffect } from "react";
import Discount from "../Discount";
import Product from "../CartProduct";
import { CartLog } from "../CartLog";
import CartTotal from "../CartTotal";
import CartEmpty from "../CartEmpty";
import CartBanner from "../CartBaner";
import Meta from "../../../../meta/Meta";
import CartPayment from "../CartPayment";
import {Link} from "react-router-dom";
import { useForm } from "react-hook-form";
import CartAddressInputs from "../CartAddressInputs";
import { useDispatch, useSelector } from "react-redux";
import BreadCrumbs from "../../../BreadCrumbs/BreadCrumbs";
import Loader from "../../../Loader/Loader";
import styled from "./Cart.module.css";
import SignUp from "../../Registration/SignUp";
import AddAddressPopup from "../../Profile/AddAddressPopup";
import {disableScroll, enableScroll} from "../../../../helpers/documentElement";
import {cartAddress, cartPayment, cartSave, cartShipping, getCart} from "../../../../slices/cart";
import {loginUser, registerUser} from "../../../../slices/auth";
import {getAddresses, getRegions} from "../../../../slices/addresses";
import ModalOverlay from "../../Profile/ModalOverlay";
import {addressToOption} from "../../../../helpers/transforms";
import CartSuccessModal from "../CartSuccessModal";
import {setLastOrder} from "../../../Redux/CartReducer"

export const COURIER = "flatrate_flatrate"
export const TAKE = "free_free"
export const PAYNET = "paynet"
export const CASH = "cashondelivery"

const Cart = () => {
  // Global State
  const trans = useSelector(state => state?.fetch?.data?.text)
  const user = useSelector(user => user?.user?.currentUser);
  const inProgress = useSelector(state => state?.cart?.in_progress)
  const addresses = useSelector(state => state?.addresses?.data);
  const addresessLoading = useSelector(state => state?.addresses?.loading)
  const localAddress = useSelector(state => state?.addresses?.local);
  const userCart = useSelector(state => state?.cart?.data);
  const regions = useSelector(state => state?.addresses?.regions)
  const language = useSelector(state => state?.language?.currentLanguage);
  const loading = useSelector(state => state?.cart?.loading)
  const currency = useSelector(state => state?.currency?.currentDataCurrency)
  const currentCurrency = useSelector(state => state?.currency?.currentValueCurrency)

  // Local State
  const [errorSignUp, setErrorSignUp] = useState(null);
  const [successModal, setSuccessModal] = useState(false);
  const [logInInput, setLoginInput] = useState(null);
  const [addressError, setAddressError] = useState(false);
  const [contact, setContact] = useState("registration");
  const [paymentError, setPaymentError] = useState(false);
  const [deliveryError, setDeliveryError] = useState(false);
  const [termsError, setTermsError] = useState(false);
  const [editableAddress, setEditableAddress] = useState({});
  const [registerInput, setRegisterInput] = useState(null);
  const [addressPopup, setAddressPopup] = useState(false);
  const [overlay, setOverlay] = useState(false)
  const [selectedOption, setSelectedOption] = useState(null);
  const [delivery, setDelivery] = useState(COURIER);
  const [name, setName] = useState(null);
  const [phone, setPhone] = useState(null);
  const [email, setEmail] = useState(null);
  const [payment, setPayment] = useState(userCart?.payment?.method);
  const [responseError, setResponseError] = useState("");
  const [ isLoading, setIsLoading] = useState(false)


  // Local consts

  const dispatch = useDispatch();
  const address_options = addresses?.data?.length ? addresses?.data?.filter(el => el.country === currency?.country_name ).map(addressToOption) : (localAddress ? [addressToOption(localAddress)] : []);

  // use Effect
  useEffect(() => {
    if(user) dispatch(getAddresses())
  }, []);

  // Memo
  useEffect(() => {
    setDelivery(userCart?.shipping_method)
  }, [userCart?.shipping_method])

  useEffect(() => {
    setPayment(userCart?.payment?.method)
  }, [userCart?.payment])



  useEffect(() => {
    if(selectedOption) {
      setSelectedOption(null)
    }
  }, [currency])


  useEffect(() => {
    if(localAddress) setEditableAddress(localAddress)
  }, [localAddress])


  const {setValue, register, handleSubmit, formState: { errors }} = useForm({ mode: "onChange" });

  useEffect(() => {
    const handleChangeCurrenct = async () => {
      setIsLoading(true)
      if(selectedOption) {
        setSelectedOption(null)
      }


      await dispatch(getCart({currency: currentCurrency}))
      if(userCart) {
        await dispatch(cartPayment({method: currency?.methods[0]}))
        await dispatch(cartShipping({shipping_method: TAKE, currency: currentCurrency}))
      }


     // handleDelivery(COURIER)
      setIsLoading(false)
    }
    handleChangeCurrenct()
  }, [currentCurrency])

  const shippingZone = async (value) => {
    let allRegions = regions

    let city;

    if( value ) {
      city = value
    } else {
      const address = user ? await addresses?.data?.find(it => it.id === selectedOption?.value) : localAddress
      city = await address?.city
    }


    if (regions.length === 0 ) await dispatch(getRegions({code: currency.country_code})).unwrap().then((data) => allRegions = data.regions)


    return Object.keys(allRegions)?.find(key => allRegions[key] === city)

  }


  const onSelectAddress = async () => {

    const address = user ? await addresses?.data?.find(it => it.id === selectedOption?.value) : localAddress

    const shipping_zone = await shippingZone()


    if (shipping_zone) {

      const order_address = email?.length ? email : user?.data?.email

      if (order_address) {

        const formatted_address = {
          ...address,
          email: order_address,
          phone: phone?.length ? phone : address?.phone,
          first_name: name?.length ? name : address?.first_name
        }

        const data = {
          billing: formatted_address,
          shipping: formatted_address
        }

        await dispatch(cartAddress({data})).unwrap().then(async () => {
          await dispatch(cartShipping({
            shipping_method: COURIER,
            shipping_zone,
            currency: currentCurrency
          }))
        })

      }
    }
  }

  useEffect(() => {
    if(selectedOption !== null && !addresessLoading) onSelectAddress().then()
  }, [selectedOption, addresessLoading]);

  // const outside = useClickOutside(() => {
  //   //enableScroll()
  // });

  const onSubmit = () => {
    setResponseError("")
    dispatch(cartSave()).unwrap().then((response) => {

      dispatch(setLastOrder(response?.order?.id))
      window.scrollTo({
        top: 0,
        left: 0
      });
      setSuccessModal(true)
    }).catch((message) => {
      setResponseError(message)
    })
  };

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      color: "#A9A9A9",
      padding: 0,
      background: "#FFFFFF",

    }),
    option: (provided) => ({
      ...provided,
      // textOverflow: 'ellipsis',
      // overflow: 'hidden',
      // whiteSpace: 'nowrap',

    })
  };

  const selectAddress = (address) => {
    const current = addressToOption(address)
    setSelectedOption(current)
  }

  const checkErrors = () => {
    //
  }

  const checkCondition = () => {
    handleSubmit(checkErrors)()
    setDeliveryError(true);
    setPaymentError(true);
    setAddressError(true);
  };

  const saveByType = (type = delivery) => {
    if(type === TAKE) {
      const order_address = email?.length ? email : user?.data?.email

      if (order_address) {
        const firstName = name?.length ? name.split(" ")[0] : user?.data?.first_name
        const lastName = name?.length && name.split(" ").length > 1 ? name.split(" ")?.[1] : user?.data?.last_name

        const formatted_address = {
          address1: ["Chisinau"],
          city: "Chisinau",
          email: order_address,
          phone: phone?.length ? phone : user?.data?.phone,
          first_name: firstName ?? "user",
          last_name: lastName ?? "user"
        }

        const data = {
          billing: formatted_address,
          shipping: formatted_address
        }
        dispatch(cartAddress({data})).unwrap().then(() => {
          dispatch(cartShipping({
            shipping_method: TAKE,
            shipping_zone: Object.keys(regions)[0],
            currency: currentCurrency
          }))
        })
      }
    } else if(type === COURIER) {
     onSelectAddress()
    }
  }

  const handleDelivery = (type) => {
    setDelivery(type);
    saveByType(type)
  };


  const logIn = async () => {

    const loginData = {
      email: logInInput?.email,
      password: logInInput?.password,
      locale: language
    };
    dispatch(loginUser(loginData)).unwrap().then(() => {
      window.location.reload();
    }).catch(() => {

      const temp_errors = {
        failed: true
      }

      setErrorSignUp(temp_errors);
    })
  };

  const registration = async () => {
    const registerData = {
      first_name: registerInput?.name,
      last_name: registerInput?.name,
      email: registerInput?.email,
      password: registerInput?.password,
      password_confirmation: registerInput?.confirm_password,
      locale: language
    };
    await dispatch(registerUser(registerData)).unwrap()

        .then((user) => {
          dispatch(loginUser(registerData)).unwrap().then(() => {
            window.location.reload()
          })
        }).catch((error) => {
          setErrorSignUp(error);
        });
  };

  addressPopup || successModal ? disableScroll() : enableScroll()

  const closeOverlay = () => {
    setAddressPopup(false)
    setOverlay(false)
    enableScroll()
  }

  const openAddressPopup = () => {
    setAddressPopup(true)
    setOverlay(true)
  }

  const uncompleted = () => {
    return termsError || errors.name || errors.number || errors.mail || !delivery || (delivery === COURIER && (!payment || !selectedOption?.value)) || (delivery === TAKE && !payment) || (delivery === TAKE )
  }

  return (
      <>
        <Meta
          title={trans?.checkout?.title}
        />
        {(loading || isLoading) &&
            <div className="loader__container">
              <Loader/>
            </div>
        }
        {/* {<CartSuccess userCart={userCart} order={order} outside={outside} close={closeSuccessModal} ord={ord} delivery={delivery}/>}*/}
        <CartBanner/>
        <section className="cart">
          {successModal && <CartSuccessModal callback={setSuccessModal}/>}
          <BreadCrumbs title={trans?.checkout?.title} />
          <div className="container">
            {loading ?
                <Loader />
                :
                <>
                  {userCart && userCart?.items?.length > 0 ?
                      <div className="cart__product-main">
                        <div className="cart__content">
                            <div className="cart__products">
                            {userCart?.items?.length > 0 && userCart?.items?.map((product) => (
                                <Product
                                    key={product?.id}
                                    product={product}
                                    selectedOption={selectedOption}
                                    shipping_method={COURIER}
                                    shippingZone={shippingZone}
                                />
                            ))}
                            </div>
                          <div className="cart__inputs">
                            {false && <Coupon/>}
                            {false && <Discount/>}
                          </div>
                          <CartTotal/>
                          <div className="cart__back">
                            <Link className="cart__back-link" to="/">
                              {trans?.checkout?.['continue-shopping']}
                            </Link>
                          </div>
                        </div>
                        <div className={contact === "contact" && !user ? styled.contact : ""}>
                          {!user && contact === "contact" ? (
                              <div className={styled.contact_form}>
                                <CartLog
                                    logIn={logIn}
                                    logInInput={logInInput}
                                    setLoginInput={setLoginInput}
                                    contact={contact}
                                    setContact={setContact}
                                />
                              </div>
                          ) : (
                              <></>
                          )}
                          {!user && contact === "signUp" ? (
                              <div className={`${styled.contact_form} ${styled.signUp}`}>
                                <div className={`cart__login-title cart-container ${styled.signUp_title}`}>
                                  {trans?.checkout?.['shipping-form']?.['btn-sign-up']}
                                </div>
                                <SignUp
                                    errorSignUp={errorSignUp}
                                    setErrorSignUp={setErrorSignUp}
                                    registerInput={registerInput}
                                    registration={registration}
                                    setRegisterInput={setRegisterInput}
                                    cart={false}
                                    setContact={setContact}
                                />
                              </div>
                          ) : (
                              <></>
                          )}
                          <form className={`cart__registration ${styled.cart__registration}`} onSubmit={handleSubmit(onSubmit)}>
                            <div className="cart__registration-log">
                              <div className={`cart__contacts ${contact === "registration" && "active-contact"}`}>
                                <div className="cart__contact-top cart-container">
                                  <div className="cart__contact-title">{trans?.checkout?.['shipping-form']?.['title-contacts']}</div>
                                  {!user && (
                                      <div style={{ display: "flex", alignItems: "center" }}>
                                        <div className="cart__contact-log" onClick={() => setContact("contact")}>
                                          {trans?.checkout?.['shipping-form']?.['btn-sign-in']}
                                        </div>
                                        /
                                        <div className="cart__contact-log signup" onClick={() => setContact("signUp")}>
                                          {trans?.checkout?.['shipping-form']?.['btn-sign-up']}
                                        </div>
                                      </div>
                                  )}
                                </div>
                                <CartAddressInputs
                                    name={name}
                                    email={email}
                                    phone={phone}
                                    setName={setName}
                                    setPhone={setPhone}
                                    setEmail={setEmail}
                                    addressError={addressError}
                                    saveAddress={saveByType}
                                    register={register}
                                    setValue={setValue}
                                    errors={errors}
                                />
                              </div>
                              <div className="cart__payment">
                                <div className="cart__payment-title cart-container">
                                  {trans?.checkout?.['shipping-form']?.['title-delivery']}
                                </div>
                                <div className="cart__payment-choose delivery-choose cart-container">
                                  <div className={`cart__delivery-item-info ${delivery === COURIER ? "active" : ""}`}>
                                    <div
                                      className={`cart__payment-cash ${delivery === COURIER ? "active" : ""}`}
                                      onClick={() => handleDelivery(COURIER)}
                                    >
                                      <input
                                          type="radio"
                                          className="cart__online-take"
                                      />
                                      {trans?.checkout?.['shipping-form']?.['courier-delivery']}
                                    </div>
                                    {(address_options.length > 0 || (!user && localAddress)) &&
                                        <Select
                                            className="address"
                                            value={selectedOption}
                                            onChange={setSelectedOption}
                                            options={address_options}
                                            placeholder={selectedOption?.value}
                                            isSearchable={false}
                                            styles={customStyles}
                                            components={{
                                              IndicatorSeparator:() => null,
                                              DropdownIndicator:() => null
                                            }}
                                        />
                                    }

                                    <div className="cart__add-address--text" onClick={openAddressPopup}>
                                      {!user && localAddress ? <> {trans?.['my-profile']?.['my-addresses']?.['sidebar-address']?.["btn-edit"]}</> : <>{trans?.checkout?.['shipping-form']?.['bnt-add-address']}</>}
                                    </div>

                                    {address_options?.map((a, index) => (
                                        <div key={index}>
                                          {a?.input} {a?.street}
                                        </div>
                                    ))}

                                  </div>
                                  {deliveryError && delivery === COURIER && !selectedOption?.value &&
                                      <p className="product__error">
                                        {trans?.checkout?.['shipping-form']?.['select']}
                                      </p>
                                  }
                                  {deliveryError && delivery === TAKE &&
                                      <p className="product__error">
                                        {trans?.checkout?.['shipping-form']?.['select']}
                                      </p>
                                  }
                                  {/* <div className={`cart__delivery-item-info ${delivery === TAKE ? "active" : ""}`}>
                                    <div
                                        className={`cart__payment-cash ${delivery === TAKE ? "active" : ""}`}
                                        onClick={() => handleDelivery(TAKE)}
                                    >
                                      <input
                                          type="radio"
                                          className="cart__online-take"
                                      />
                                      {trans?.checkout?.['shipping-form']?.['take-off']}
                                    </div>
                                  </div> */}
                                  {deliveryError && !delivery &&
                                      <p className="product__error">
                                        Select delivery type
                                      </p>
                                  }
                                </div>
                              </div>

                              <CartPayment
                                  payment={payment}
                                  setPayment={setPayment}
                                  paymentError={paymentError}
                                  setTermsError={setTermsError}
                                  termsError={termsError}
                              />


                              <div className="cart__checkout cart-container" onClick={uncompleted() ? checkCondition : undefined}>
                                {uncompleted() ?
                                    <div className={`cart__checkout-btn ${inProgress ? "disabled" : ""}`}>{trans?.checkout?.['shipping-form']?.checkout}</div>
                                    :
                                    <button className={`cart__checkout-btn ${inProgress ? "disabled" : ""}`} disabled={inProgress} type="submit">{trans?.checkout?.['shipping-form']?.checkout}</button>
                                }

                                {responseError.length > 0 &&
                                    <p className="product__error">
                                      {responseError}
                                    </p>
                                }

                              </div>

                            </div>
                          </form>
                        </div>
                      </div>
                      :
                      <CartEmpty />
                  }
                  <div className="cart_address">
                    {addressPopup && <ModalOverlay overlay={overlay} close={closeOverlay}/>}
                    {addressPopup &&
                      <AddAddressPopup
                        close={closeOverlay}
                        setAddressPopup={setAddressPopup}
                        setOverlay={setOverlay}
                        overlay={overlay}
                        editableAddress={editableAddress}
                        setEditableAddress={setEditableAddress}
                        callback={selectAddress}
                        shipping_method={COURIER}
                        shippingZone={shippingZone}
                      />
                    }
                  </div>
                </>
            }
          </div>
        </section>
      </>
  );
};

export default Cart;
