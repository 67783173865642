import React, {useEffect, useState} from "react";
import NewAddress from "./NewAddress";
import AddAddressPopup from "./AddAddressPopup";
import {useDispatch, useSelector} from "react-redux";
import Loader from "../../Loader/Loader";
import PersonalData from "./PersonalData/PersonalData";
import {disableScroll} from "../../../helpers/documentElement";
import { getAddresses, getRegions } from "../../../slices/addresses";

const Addresses = ({ data, close, addressPopup, setAddressPopup, setOverlay, overlay, editableAddress, setEditableAddress, trans }) => {
    const dispatch = useDispatch();
    const addresses = useSelector(state => state?.addresses?.data)
    const loading = useSelector(state => state?.addresses?.loading)
    const language = useSelector(state => state.language.currentLanguage)
    const regions = useSelector(state => state?.addresses?.regions)
    const currency = useSelector(state => state?.currency?.currentDataCurrency)


    useEffect(() => { 
       dispatch(getAddresses())
    }, [language]);

    const activePopup = () => {
        setAddressPopup(!addressPopup);
        setOverlay(!overlay);
        disableScroll()
    };

    const editPopup = (address) => {
        setEditableAddress(address);
        setAddressPopup(!addressPopup);
        setOverlay(!overlay);
        disableScroll()
    };


    const shippingZone = async (value) => {
        let allRegions = regions
        if (regions.length === 0 ) await dispatch(getRegions({code: currency.country_code})).unwrap().then((data) => allRegions = data.regions)
        return Object.keys(allRegions)?.find(key => allRegions[key] === value)
    
      }

    return (
        <div className={`profile__data ${data === "address" && "active-profile"}`}>
            {addressPopup &&
                <AddAddressPopup
                    close={close}
                    setAddressPopup={setAddressPopup}
                    setOverlay={setOverlay}
                    overlay={overlay}
                    editableAddress={editableAddress}
                    setEditableAddress={setEditableAddress}
                    shippingZone={shippingZone}
                />
            }
            <div className="profile__no-address">
                <h3 className="profile__address-title" id="privacy">
                    {trans?.['my-profile']?.['my-addresses']?.['title-my-addresses']}
                </h3>
                <div className="profile__delivery-text">{trans?.['my-profile']?.['my-addresses']?.['subtitle-my-addresses']}</div>
                {
                    !addresses?.data?.length && 
                    <div className="profile__add_address">
                        {trans?.['my-profile']?.['my-addresses']?.['empty-addresses'] ?? 'You need to add an address'} 
                    </div>
                }
                {loading ? (
                    <div className="loader__container">
                        <Loader />
                    </div>
                ) : (
                    <>
                        {addresses?.data ? (
                            <div className="profile__addresses">
                                <div className="profile__current-address"></div>
                                {addresses?.data?.map((product, index) => (
                                    <NewAddress
                                        key={index}
                                        id={product?.id}
                                        address={product}
                                        editPopup={editPopup}
                                    />
                                ))
                                }

                                {/*TODO PAGINATION*/}

                                <div className="profile__add-address" onClick={activePopup}>
                                    <div className="profile__add-text">{trans?.['my-profile']?.['my-addresses']?.['btn-add']}</div>
                                </div>
                            </div>
                        ) : (
                            <div>
                                <div className="profile__deliveryadd-input">
                                    {trans?.['my-profile']?.['my-addresses']?.['btn-add']}
                                </div>
                                <div className="profile__add-address" onClick={activePopup}>
                                    <div className="profile__add-text">{trans?.['my-profile']?.['my-addresses']?.['btn-add']}</div>
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default Addresses;
