import {createAsyncThunk} from "@reduxjs/toolkit";
import getErrorMessage from "../services/utils/error-message";
import VideosService from "../services/videos.service";
import { setVideos } from "../components/Redux/VideoReducer";

export const getVideos = createAsyncThunk(
    "videos/get",
    async ({ page, locale }, thunk) => {
        try { 
            const response = await VideosService.get(page, locale);
            thunk.dispatch(setVideos(response?.videos?.data ?? []));
            return response;
        } catch (error) {
            return thunk.rejectWithValue(getErrorMessage(error));
        }
    }
);
