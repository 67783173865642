import React from 'react'
import {useSelector} from 'react-redux'
import Goods from '../Goods/Goods'


const Galleries = ({title = '', caption = '', products = null, link = null, color = "white"}) => {

    const trans = useSelector(state => state?.fetch?.data?.text)

    return <>
        {products && <div className={`gallery__${color}`}>
            <div className="gallery__content">
                <div className="gallery__view-title">{title}</div>
                <div className="gallery__view-subtitle">{caption}</div>
                <div className="gallery">
                    <div className="container">
                        <div className="gallery__slider">
                            {products?.map(product => <Goods product={product} key={product.id}/>)}
                        </div>
                    </div>
                </div>

                {
                    link &&
                    <a className="gallery__btn" href={link}>
                        <div className="gallery__btn-text">
                            {trans?.sliders['btn-view']}
                        </div>
                    </a>
                }
            </div>
        </div>
        }
    </>
}

export default Galleries