import React from 'react'

const RegistrationBanner = ({trans}) => {
    return (
        <div className="baner__container">
            <div className="baner__container">
                <div className="category-baner">
                    <div className="container">
                        <div className="category-baner__title"> 
                        {trans?.['register']?.['signup-text']?.['title']}
                            <div className="category-baner__subtitle"> {trans?.['register']?.['signup-text']?.['subtitle']}</div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RegistrationBanner