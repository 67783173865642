import React from 'react'
import { useDispatch } from 'react-redux'

const FooterNavigation = ({ item, index, navigate}) => {
    const scroll = () => {
        navigate(`/category/${item?.slug}`)
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    }
  return (
    <li key={index} className="footer__item" ><div className="footer__link-left" onClick={scroll}>{item?.name}</div></li>
  )
}

export default FooterNavigation