import { createSlice } from "@reduxjs/toolkit";

const videosSlice = createSlice({
    name: 'videos',
    initialState: {
        data: [],
        loading: true
    },

    reducers: {
        setVideos: (state, action) => {
            state.data      = action.payload
            state.loading   = false
        },
    }
})

export const { setVideos } = videosSlice.actions
export default videosSlice.reducer