import React, {useEffect, useState} from 'react'
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux'

export const CartLog = ({ logIn,logInInput, setLoginInput,  contact, setContact }) => {
    const trans = useSelector(state => state?.fetch?.data?.text)
    const { register, handleSubmit, reset, formState: { errors } } = useForm({ mode: 'onChange' });
    const onSubmit = (data) => {
        setLoginInput(data)
    }
    useEffect(() => {
        if (logInInput != null) {
            reset()
            logIn()
        }
    }, [logInInput])

    return (
        <form className={`cart__login ${contact === "contact" && "active-contact"}`} onSubmit={handleSubmit(onSubmit)}>
            <div className="cart__login-title cart-container">{trans?.checkout?.['shipping-form']?.['title-contacts']}</div>
            <div className="cart__login-inputs cart-container">
                <input className="registration__mailinput" {...register('email', { required: true, pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g })} type="text" placeholder={trans?.register?.['signup-text']?.['input-email']} />
                {errors?.email && <p className='error-mesage'>{trans?.register?.['signup-text']?.['input-email-error'] ?? 'Enter a valid email'}</p>}
                <input className="registration__pass-input" type="password" placeholder={trans?.register?.['signup-text']?.['input-password']} {...register("password", { required: true })} />
                {errors?.password && <p className='error-mesage'>{trans?.['my-profile']?.['personal-data']?.['input-password-required'] ?? 'Password required'}</p>}
            </div>
            <div className="cart__login-btns cart-container">
                <div className="cart__login-back" onClick={() => setContact("registration")}>{trans?.checkout?.['shipping-form']?.['btn-back'] ?? 'Back'}</div>
                <button className="cart__login-log" type="submit">{trans?.checkout?.['shipping-form']?.['btn-sign-in'] ?? 'Sign in'}</button>
            </div>
        </form>
    )
}
