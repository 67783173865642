import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import empty from "../../../assets/images/pictures/empty-cart.png"
const CartEmpty = () => {
    const trans = useSelector(state => state?.fetch?.data?.text)
    return (
        <div className='empty__cart'>
            <img className="empty__cart-img" src={empty} alt=""/>
            <div className="empty__cart-title">{trans?.checkout?.['empty-cart'] ?? 'Your cart is currently empty'} </div>
            <div className="empty__cart-text">{trans?.checkout?.['empty-cart-desc'] ?? ' Looks like you haven’t made your choice yet...'}</div>
            <Link to="/" className="empty__cart-btn">{trans?.['my-profile']?.['my-orders']?.['btn-start-shopping']}</Link>
        </div>
    )
}

export default CartEmpty