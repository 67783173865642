import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'




const HomeBanner = () => {
    const trans = useSelector(state => state?.fetch?.data?.text)
    const banners = useSelector(state => state?.fetch?.data?.header?.bannersBottom)

    return (

        <section className="gallery__top">

            <div className="gallery__banner">
                { banners && banners?.map((banner, index) => {
                    const side = index % 2 == 0 ? "left" : "right"
                    return  <div key={index} className={`gallery__${side}`}>
                            <Link className={` gallery__${side}-link`} to={banner?.link} >
                            
                      
                            <img className={`gallery__${side}-img`} src={banner?.image} alt={banner?.title} />
                     
                                                        
                            <div className={`gallery_content gallery__${side}-content`}>
                                <h2 className={`gallery__${side}-title`}>{banner?.title}</h2>
                                <div className={`gallery__${side}-text`}>
                                    {banner?.caption}
                                </div>
                                <div className={`gallery__${side}-btn`}>
                                    <div className="gallery__btn-text">{trans?.sliders['btn-view'] ?? 'See more'}</div>
                                </div>
                            </div>
                            </Link>
                        </div>
                    })
                }
            </div>

        </section>
    )
}

export default HomeBanner