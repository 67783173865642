import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import HeaderPopup from '../../Header/HeaderPopup/HeaderPopup'
import { setItemInCart } from '../../Redux/CartReducer'
import { ToastContainer, toast, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {REACT_APP_SERVER_API} from "../../../constants";
import {addToCart} from "../../../slices/cart";

const AddToCart = ({ product, savedOption }) => {

    const dispatch = useDispatch();
    const trans = useSelector(state => state?.fetch?.data?.text)

    const postProduct = async () => {
        const productData = {
            product_id: product?.id,
            quantity: savedOption?.savedQuantity,
            size: savedOption?.savedSize,
            savedOption: savedOption,
        }
        await dispatch(addToCart(productData))
    }

    const handleClick = (e) => {
        postProduct().then(() => toast(
            <>
                <HeaderPopup savedOption={savedOption} product={product} />
            </>
        ))
    };

    return (
        <>
            <div>
                <ToastContainer
                    position="top-right"
                    autoClose={2000}
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnHover
                    transition={Slide}
                    limit={5} >
                </ToastContainer>
            </div>
            <div className="product__price-add" onClick={handleClick}>
                <div className="product__add-text" >{trans?.products?.['add-to-cart'] ?? 'Add to cart'}</ div>
            </div>
        </>
    )
}

export default AddToCart