import { createSlice } from "@reduxjs/toolkit";

const currencySlice = createSlice({
    name: 'currency',
    initialState: {
      loading: false,
      currentValueCurrency: localStorage.getItem('currency') ? JSON.parse(localStorage.getItem('currency')) : 'MDL',
      currentDataCurrency: null
    },

    reducers: {
        setCurrencyLoading: (state, action) => {
            state.loading = action.payload
        },
        setCurrentDataCurrency: (state, action) => {
            state.currentDataCurrency = action.payload
            state.loading = false
        },
        setCurrentValueCurrency: (state, action) => {
            state.currentValueCurrency = action.payload
            localStorage.setItem('currency', JSON.stringify(state.currentValueCurrency))
            state.loading = false
        },
    }
})

export const { setCurrentValueCurrency, setCurrentDataCurrency, setCurrencyLoading } = currencySlice.actions
export default currencySlice.reducer