import request from "./utils/request";

const defaultCurrency = JSON.parse(localStorage.getItem('currency'))
 
const get = (currency) => {
    return request().get( `/checkout/cart`, {params: {currency}}).then(response => response.data)
};

const add = (product_id, quantity, size, savedOption) => {
    return request().post(`/checkout/cart/add/${product_id}`, { product_id, quantity, size, savedOption }).then(response => response.data)
};

const update = (product_id, count ) => {
    return request().put(`/checkout/cart/update`, { qty: { [product_id]: count } }).then(response => response.data);
};

const address = (data) => {
    return request().post(`/checkout/save-address`, data).then(response => response.data);
};

const shipping = (shipping_method, shipping_zone, currency=defaultCurrency) => {
    return request().post(`/checkout/save-shipping?currency=${currency}`, {shipping_method, shipping_zone}).then(response => response.data);
};

const payment = (method) => {
    return request().post(`/checkout/save-payment`, { payment: { method } }).then(response => response.data);
};

const comment = (comment) => {
    return request().post(`/checkout/save-comment`, { comment }).then(response => response.data);
};

const save = () => {
    return request().post(`/checkout/save-order`).then(response => response.data);
};

const coupon = (code) => {
    return request().post('/checkout/cart/coupon', { code }).then(response => response.data)
}

const discount = (code) => {
    return request().post('/checkout/cart/discount-card', { code }).then(response => response.data)
}

const remove = (product_id, shipping_zone) => {
    return request().get(`/checkout/cart/remove-item/${product_id}`).then(response => response.data);
};

const invoice = (id) => {
    return request().get(`payment/order/${id}`).then(response => response.data);
};

const onlinePay = (id) => {
    return request().post(`payment/redirect/${id}`).then(response => response.data);
};

const success = (id) => {
    return request().get(`success/${id}`).then(response => response.data);
};

const verify_status = (id) => {
    return request().get(`order/verify_status/${id}`).then(response => response.data);
};


const cartService = { get, add, update, address, shipping, payment, comment, save, coupon, discount, remove, invoice, onlinePay, success, verify_status };

export default cartService;