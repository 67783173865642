import React from 'react'
import sprite from "../../../assets/images/icons/sprite.svg";
import { useSelector } from 'react-redux';

const ContactSocial = () => {
  const data = useSelector(state => state?.fetch?.data?.footer)


  return (
    <div className="contact_social">
      {data?.links?.facebook && <a className="footer__social-link" target="_blank" href={data?.links?.facebook?.url} title={data?.links?.facebook?.title}>
        <svg className="icon facebook-wh">
          <use href={sprite + '#facebook-wh'}></use>
        </svg>
      </a>}
      {data?.links?.twitter && <a className="footer__social-link" target="_blank" href={`//t.me/${data?.phone?.replace(/\s/g, '')}`} title={'Telegram'}> 
        <svg className="icon twitter-wh">
            <use href={sprite + '#telegram'}></use>
        </svg>
      </a>}
     { data?.links?.instagram && <a className="footer__social-link" target="_blank" href={data?.links?.instagram?.url} title={data?.links?.instagram?.title}>
        <svg className="icon instagram-wh">
          <use href={sprite + '#instagram-wh'}></use>
        </svg>
      </a>
      }
      
    </div>
  )
}

export default ContactSocial