import React from 'react'
import { Link } from 'react-router-dom'
import empty from "../../../assets/images/pictures/empty-cart.png"
const OrderEmpty = ({ data, trans }) => {
    return (
        <div className={`empty__cart order-empty ${data === "order" && "active-profile"}`}>
            <img className="empty__cart-img" src={empty} />
            <div className="empty__cart-title">{trans?.['my-profile']?.['my-orders']?.['empty-page-title']}</div>
            <div className="empty__cart-text">{trans?.['my-profile']?.['my-orders']?.['empty-page-subtitle']}</div>
            <Link to="/" className="empty__cart-btn">{trans?.['my-profile']?.['my-orders']?.['btn-start-shopping']}</Link>
        </div>
    )
}

export default OrderEmpty