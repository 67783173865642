import React, { useEffect, useState } from 'react';
import {Helmet} from "react-helmet";
import { Routes, Route, Navigate } from 'react-router-dom';
import useScrollToTop from './hooks/useScrollToTop';
import Error from './components/pages/404/404'
import Header from './components/Header/Header'
import Footer from './components/Footer/Footer'
import Home from './components/pages/Home/Home'
import Wish from './components/pages/Wish/Wish'
import Cart from './components/pages/Cart/Cart/Cart'

import Blog from './components/pages/Blog/Blog'
import Search from './components/pages/Search/Search'
import Contact from './components/pages/Contact/Contact'
import Profile from './components/pages/Profile/Profile'
import Product from './components/pages/Product/Product'
import Privacy from "./components/pages/Privacy/Privacy"
import Copyright from './components/Copyright/Copyright'
import Placing from './components/pages/Placing/Placing'
import BlogAbout from './components/pages/Blog/BlogAbout'
import Category from './components/pages/Category/Category'
import Navigation from './components/Navigation/Navigation'
import Registration from './components/pages/Registration/Registration'
import { useDispatch, useSelector } from 'react-redux'
import "./components/styles/Style.scss"
import VideoPage from "./components/pages/Video/VideoPage";


import { getFetch } from "./slices/fetch";
import Loader from "./components/Loader/Loader";

function App() {

    const locale    = useSelector(language => language?.language?.currentLanguage)
    const user      = useSelector(user => user?.user?.currentUser)
    const loading   = useSelector(state => state?.fetch?.loading)
    const fetch     = useSelector(state => state?.fetch?.data)
    const dispatch  = useDispatch();

    useEffect(() => {
        dispatch(getFetch({ locale }))
    }, [locale])

    useScrollToTop()
    return (
        <div className="App">

            {loading ?
                <div className="loader__container">
                    <Loader/>
                </div>
                :
                <>

                    <Header fetch={fetch}/>
                    {/*<Navigation fetch={fetch?.categories}/>*/}
                    <main>
                        <Routes>
                            <Route path="/" element={<Home/>}/>
                            <Route path="success/:id" element={<Home success={true}/>}/>
                            <Route path="category/:slug" element={<Category/>}/>
                            <Route path="product/:slug" element={<Product/>}/>
                            <Route path="wish" element={<Wish/>}/>
                            <Route path="cart" element={<Cart />}/>
                            <Route path="payment/:id" element={<Placing />}/>
                            <Route path="registration" element={<Registration/>}/>
                            <Route path="page/blog/:slug" element={<BlogAbout/>}/>
                            <Route path="profile" element={<Profile/>}/>
                            <Route path="page/blog" element={<Blog/>}/>
                            <Route path="page/contact-us" element={<Contact/>}/>
                            <Route path="page/:slug" element={<Privacy/>}/>
                            <Route path="search/:query" element={<Search/>}/>
                            <Route path="videos" element={<VideoPage/>}/>
                            <Route path="404" element={<Error/>}/>
                            <Route path='*' element={<Navigate to='/404' replace/>}/>
                        </Routes>
                    </main>
                    <Footer fetch={fetch}/>
                    <Copyright/>
                    <Helmet>
                        <script src="//code.jivo.ru/widget/ZpnvYvGxbQ" type="text/javascript" async />
                    </Helmet>
                </>
            }
        </div>
    );
}

export default App;
