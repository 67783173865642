import React from 'react'
import { useSelector } from 'react-redux';
 

const ContactNumber = ({ sprite }) => {
    const label     = useSelector(state => state?.contacts?.data?.text?.contacts?.['title-phone']);
    const value     = useSelector(state => state?.contacts?.data?.data?.additional?.phone);
    const contact   = useSelector(state => state?.fetch?.data?.footer?.phone);
        
    return (
        <div className="contact__info-number">
            <div className="contact__number-icon"><svg className="icon contact__number">
                <use href={sprite + '#contact-phone'}></use>
            </svg></div>
            <h3 className="contact__number-title">{label}</h3>
            {value && <div className="contact__number-phones">
                <a className="contact__number-phone" href={`tel:${value}`}>{value}</a>
            </div>}
        </div>
    )
}

export default ContactNumber