import React from 'react'

const CategoryEmpty = ({ empty, resetAll, text = {emptyTitle: '', emptyDescription: '', emptyBtn: 'btn-see-all'}}) => {
    return (
        <div className='empty__cart'>
            <img className="empty__cart-img" src={empty} />
            <div className="empty__cart-title">{text.emptyTitle}</div>
            <div className="empty__cart-text">{text.emptyDescription}</div>
        </div>
    )
}

export default CategoryEmpty