import request from "./utils/request";

const getAll = (params) => {
    return request().get( `/page/blog`, { params: params} ).then(response => response.data)
};

const get = (slug,params) => {
    return request().get( `/article/${slug}`, { params: params} ).then(response => response.data)
};


const articlesService = { getAll, get };

export default articlesService;