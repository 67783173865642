import { createSlice } from "@reduxjs/toolkit";

const contactsSlice = createSlice({
    name: 'contacts',
    initialState: {
        data: [],
        loading: false
    },

    reducers: {
        setContacts: (state, action) => {
            state.data = action.payload
        },
    }
})

export const { setContacts } = contactsSlice.actions
export default contactsSlice.reducer