import React from 'react'
import { useSelector } from "react-redux";

const OrderItems = ({items}) => {
    const trans = useSelector(state => state?.fetch?.data?.text)
    return (
        <>
        {items && items.map( item => 
            <div className="placing__order-item" key={item?.id}>
              
                <img className="placing__order-image" src={item?.base_image?.['product-order'] ?? item?.base_image?.product_image_url} alt=''/>
                <div className="placing__order-content">
                    <div className="placing__order-content--title">{item?.name}</div>
                    <div className="placing__order-content--article">{item?.additional?.savedOption?.savedColor ? `${item?.additional?.savedOption?.savedColor} |` : ''} {item?.id}</div>
                    <div className="placing__order-content--size">{trans?.products?.['popup-buy-now']?.size} <span>{item?.additional?.size}</span></div>
                    <div className="placing__mobile-price">{item?.qty_ordered} x {item?.formated_price}</div>
                </div>
                <div className="placing__order-price">{item?.qty_ordered} x {item?.formated_price}</div>
            </div>
        )}
        </>
    )
}
export default OrderItems